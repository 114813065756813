const kapitel85 = [
    {
        "searchTerm": "erreichen",
        "answer": "uppnå",
        "key": "1"
    },
    {
        "searchTerm": "der Unfall",
        "answer": "olyckan",
        "key": "2"
    },
    {
        "searchTerm": "seitdem",
        "answer": "sedan dess",
        "key": "3"
    },
    {
        "searchTerm": "die Leidenschaft",
        "answer": "lidelsen",
        "key": "4"
    },
    {
        "searchTerm": "der Sportverein",
        "answer": "idrottsföreningen",
        "key": "5"
    },
    {
        "searchTerm": "der Schulwettkampf",
        "answer": "skoltävlingen",
        "key": "6"
    },
    {
        "searchTerm": "geholt",
        "answer": "hämtat",
        "key": "7"
    },
    {
        "searchTerm": "das Interview",
        "answer": "intervjun",
        "key": "8"
    },
    {
        "searchTerm": "der Glückwunsch",
        "answer": "lyckönskningen",
        "key": "9"
    },
    {
        "searchTerm": "der Erfolg",
        "answer": "framgången",
        "key": "10"
    },
    {
        "searchTerm": "die Mannschaft",
        "answer": "laget",
        "key": "11"
    },
    {
        "searchTerm": "das Treffen",
        "answer": "mötet",
        "key": "12"
    },
    {
        "searchTerm": "es hat geklappt",
        "answer": "det har fungerat",
        "key": "13"
    },
    {
        "searchTerm": "lassen",
        "answer": "låta",
        "key": "14"
    },
    {
        "searchTerm": "die Bedienung",
        "answer": "betjäningen",
        "key": "15"
    },
    {
        "searchTerm": "das Lieblingsgericht",
        "answer": "älsklingsrätten",
        "key": "16"
    },
    {
        "searchTerm": "du nimmst",
        "answer": "du tar",
        "key": "17"
    },
    {
        "searchTerm": "entschieden",
        "answer": "bestämt",
        "key": "18"
    },
    {
        "searchTerm": "die Pizza",
        "answer": "pizzan",
        "key": "19"
    },
    {
        "searchTerm": "das Schnitzel",
        "answer": "schnitzeln",
        "key": "20"
    },
    {
        "searchTerm": "das Essen",
        "answer": "maten",
        "key": "21"
    },
    {
        "searchTerm": "erfahren",
        "answer": "få veta",
        "key": "22"
    },
    {
        "searchTerm": "angefangen",
        "answer": "börja",
        "key": "23"
    },
    {
        "searchTerm": "nun",
        "answer": "nu",
        "key": "24"
    },
    {
        "searchTerm": "viermal die Woche",
        "answer": "fyra gånger i veckan",
        "key": "25"
    },
    {
        "searchTerm": "der Leser",
        "answer": "läsaren",
        "key": "26"
    },
    {
        "searchTerm": "erklären",
        "answer": "förklara",
        "key": "27"
    },
    {
        "searchTerm": "jeder",
        "answer": "varje",
        "key": "28"
    },
    {
        "searchTerm": "genau",
        "answer": "precis",
        "key": "29"
    },
    {
        "searchTerm": "regulär",
        "answer": "reguljär",
        "key": "30"
    },
    {
        "searchTerm": "der Unterschied",
        "answer": "skillnaden",
        "key": "31"
    },
    {
        "searchTerm": "verteidigen",
        "answer": "försvara",
        "key": "32"
    },
    {
        "searchTerm": "der Korb",
        "answer": "korgen",
        "key": "33"
    },
    {
        "searchTerm": "gar nicht",
        "answer": "inte alls",
        "key": "34"
    },
    {
        "searchTerm": "die Geschwindigkeit",
        "answer": "hastigheten",
        "key": "35"
    },
    {
        "searchTerm": "wendig",
        "answer": "smidig",
        "key": "36"
    },
    {
        "searchTerm": "der Kraft",
        "answer": "kraften",
        "key": "37"
    },
    {
        "searchTerm": "die Gemeinschaft",
        "answer": "gemenskapen",
        "key": "38"
    },
    {
        "searchTerm": "gefordert",
        "answer": "utmanad",
        "key": "39"
    },
    {
        "searchTerm": "seitdem",
        "answer": "allt sedan",
        "key": "40"
    },
    {
        "searchTerm": "die Behinderung",
        "answer": "handikappet",
        "key": "41"
    },
    {
        "searchTerm": "das Hindernis",
        "answer": "hindret",
        "key": "42"
    },
    {
        "searchTerm": "der Sieg",
        "answer": "segern",
        "key": "43"
    },
    {
        "searchTerm": "der Traum",
        "answer": "drömmen",
        "key": "44"
    },
    {
        "searchTerm": "die Freiheit",
        "answer": "friheten",
        "key": "45"
    },
    {
        "searchTerm": "die Unendlichkeit",
        "answer": "oändligheten",
        "key": "46"
    },
    {
        "searchTerm": "der Blogeintrag",
        "answer": "blogginlägget",
        "key": "47"
    },
    {
        "searchTerm": "begann",
        "answer": "började",
        "key": "48"
    },
    {
        "searchTerm": "der Fallschirmsprung",
        "answer": "fallskärmshoppet",
        "key": "49"
    },
    {
        "searchTerm": "der Fall",
        "answer": "fallet",
        "key": "50"
    },
    {
        "searchTerm": "geschenkt",
        "answer": "gett",
        "key": "51"
    },
    {
        "searchTerm": "zum ersten Mal",
        "answer": "för första gången",
        "key": "52"
    },
    {
        "searchTerm": "die Leere",
        "answer": "tomheten",
        "key": "53"
    },
    {
        "searchTerm": "wagen",
        "answer": "våga",
        "key": "54"
    },
    {
        "searchTerm": "aufgeregt",
        "answer": "upprörd",
        "key": "55"
    },
    {
        "searchTerm": "der Absprung",
        "answer": "uthoppet",
        "key": "56"
    },
    {
        "searchTerm": "bereit",
        "answer": "redo",
        "key": "57"
    },
    {
        "searchTerm": "unglaublich",
        "answer": "otrolig",
        "key": "58"
    },
    {
        "searchTerm": "die Angst",
        "answer": "rädslan",
        "key": "59"
    },
    {
        "searchTerm": "das Herz",
        "answer": "hjärtat",
        "key": "60"
    },
    {
        "searchTerm": "pochen",
        "answer": "slå",
        "key": "61"
    },
    {
        "searchTerm": "vor Aufregung",
        "answer": "av upphetsning",
        "key": "62"
    },
    {
        "searchTerm": "schwitzen",
        "answer": "svettas",
        "key": "63"
    },
    {
        "searchTerm": "zittern",
        "answer": "darra",
        "key": "64"
    },
    {
        "searchTerm": "springen",
        "answer": "hoppa",
        "key": "65"
    },
    {
        "searchTerm": "schließlich",
        "answer": "till slut",
        "key": "66"
    },
    {
        "searchTerm": "schubsen",
        "answer": "knuffa",
        "key": "67"
    },
    {
        "searchTerm": "unbeschreiblich",
        "answer": "obeskrivlig",
        "key": "68"
    },
    {
        "searchTerm": "noch nie",
        "answer": "aldrig någonsin",
        "key": "69"
    },
    {
        "searchTerm": "zuvor",
        "answer": "tidigare",
        "key": "70"
    },
    {
        "searchTerm": "gefühlt",
        "answer": "känt",
        "key": "71"
    },
    {
        "searchTerm": "zurasen",
        "answer": "störta till",
        "key": "72"
    },
    {
        "searchTerm": "sanft",
        "answer": "mjukt",
        "key": "73"
    },
    {
        "searchTerm": "die Leidenschaft",
        "answer": "lidelsen",
        "key": "74"
    },
    {
        "searchTerm": "das Slacklining",
        "answer": "balansgång på lina",
        "key": "75"
    },
    {
        "searchTerm": "antreiben",
        "answer": "driva på",
        "key": "76"
    },
    {
        "searchTerm": "die Herausforderung",
        "answer": "utmaningen",
        "key": "77"
    },
    {
        "searchTerm": "das Leben",
        "answer": "livet",
        "key": "78"
    },
    {
        "searchTerm": "berichten",
        "answer": "berätta",
        "key": "79"
    },
    {
        "searchTerm": "die Halle",
        "answer": "hallen",
        "key": "80"
    },
    {
        "searchTerm": "dort",
        "answer": "där",
        "key": "81"
    },
    {
        "searchTerm": "das Gebirge",
        "answer": "berget",
        "key": "82"
    },
    {
        "searchTerm": "zwar",
        "answer": "visserligen",
        "key": "83"
    },
    {
        "searchTerm": "gesichert",
        "answer": "säkrad",
        "key": "84"
    },
    {
        "searchTerm": "die Route",
        "answer": "rutten",
        "key": "85"
    },
    {
        "searchTerm": "die Kraft",
        "answer": "kraften",
        "key": "86"
    },
    {
        "searchTerm": "bewältigen",
        "answer": "bemästra",
        "key": "87"
    },
    {
        "searchTerm": "das Selbstvertrauen",
        "answer": "självförtroendet",
        "key": "88"
    },
    {
        "searchTerm": "die Slackline",
        "answer": "lina av nylon eller polyester",
        "key": "89"
    },
    {
        "searchTerm": "dünn",
        "answer": "tunn",
        "key": "90"
    },
    {
        "searchTerm": "das Band",
        "answer": "bandet",
        "key": "91"
    },
    {
        "searchTerm": "spannen",
        "answer": "spänna",
        "key": "92"
    },
    {
        "searchTerm": "die Schlucht",
        "answer": "klyftan",
        "key": "93"
    },
    {
        "searchTerm": "überwinden",
        "answer": "övervinna",
        "key": "94"
    },
    {
        "searchTerm": "schaffen",
        "answer": "klara",
        "key": "95"
    },
    {
        "searchTerm": "das Ziel",
        "answer": "målet",
        "key": "96"
    },
    {
        "searchTerm": "einfach",
        "answer": "helt enkelt",
        "key": "97"
    },
    {
        "searchTerm": "ein Risiko eingehen",
        "answer": "ta en risk",
        "key": "98"
    },
    {
        "searchTerm": "unnötig",
        "answer": "onödig",
        "key": "99"
    },
    {
        "searchTerm": "der Abgrund",
        "answer": "avgrunden",
        "key": "100"
    },
    {
        "searchTerm": "ziehen",
        "answer": "dra",
        "key": "101"
    },
    {
        "searchTerm": "der Schritt",
        "answer": "steget",
        "key": "102"
    },
    {
        "searchTerm": "überwinden",
        "answer": "övervinna",
        "key": "103"
    },
    {
        "searchTerm": "die Wüstenwanderung",
        "answer": "ökenvandringen",
        "key": "104"
    },
    {
        "searchTerm": "zurückgekehrt",
        "answer": "återvänt",
        "key": "105"
    },
    {
        "searchTerm": "teilen",
        "answer": "dela",
        "key": "106"
    },
    {
        "searchTerm": "das Erlebnis",
        "answer": "upplevelsen",
        "key": "107"
    },
    {
        "searchTerm": "die Blase",
        "answer": "blåsan",
        "key": "108"
    },
    {
        "searchTerm": "der Sonnenbrand",
        "answer": "solsvedan",
        "key": "109"
    },
    {
        "searchTerm": "geschafft",
        "answer": "klarat av",
        "key": "110"
    },
    {
        "searchTerm": "gnadenlos",
        "answer": "obarmhärtig",
        "key": "111"
    },
    {
        "searchTerm": "die Hitze",
        "answer": "hettan",
        "key": "112"
    },
    {
        "searchTerm": "tagsüber",
        "answer": "på dagen",
        "key": "113"
    },
    {
        "searchTerm": "die Angst",
        "answer": "rädslan",
        "key": "114"
    },
    {
        "searchTerm": "die Richtung",
        "answer": "riktningen",
        "key": "115"
    },
    {
        "searchTerm": "der Kompass",
        "answer": "kompassen",
        "key": "116"
    },
    {
        "searchTerm": "der Alltag",
        "answer": "vardagen",
        "key": "117"
    },
    {
        "searchTerm": "zugleich",
        "answer": "samtidigt",
        "key": "118"
    },
    {
        "searchTerm": "kaum",
        "answer": "knappast",
        "key": "119"
    },
    {
        "searchTerm": "sich trauen",
        "answer": "våga",
        "key": "120"
    },
    {
        "searchTerm": "vorallem",
        "answer": "framförallt",
        "key": "121"
    },
    {
        "searchTerm": "erleben",
        "answer": "uppleva",
        "key": "122"
    },
    {
        "searchTerm": "die Erde",
        "answer": "jorden",
        "key": "123"
    },
    {
        "searchTerm": "entdecken",
        "answer": "upptäcka",
        "key": "124"
    },
    {
        "searchTerm": "gefroren",
        "answer": "frusen",
        "key": "125"
    },
    {
        "searchTerm": "turnen",
        "answer": "gymnastisera",
        "key": "126"
    },
    {
        "searchTerm": "in einer Wok-Pfanne rodeln",
        "answer": "åka i en wokpanna",
        "key": "127"
    },
    {
        "searchTerm": "ungewöhnlich",
        "answer": "ovanlig",
        "key": "128"
    },
    {
        "searchTerm": "Rhönradturnen",
        "answer": "gymnastisera i ett bandhjul",
        "key": "129"
    },
    {
        "searchTerm": "beliebt",
        "answer": "omtyckt",
        "key": "130"
    },
    {
        "searchTerm": "die Brücke",
        "answer": "bryggan",
        "key": "131"
    },
    {
        "searchTerm": "die Liegestütze",
        "answer": "armböjningarna",
        "key": "132"
    },
    {
        "searchTerm": "der Freiflug",
        "answer": "ett moment där man släpper händerna",
        "key": "133"
    },
    {
        "searchTerm": "drehen",
        "answer": "rotera",
        "key": "134"
    },
    {
        "searchTerm": "der Turner",
        "answer": "den manlige gymnasten",
        "key": "135"
    },
    {
        "searchTerm": "die Turnerin",
        "answer": "den kvinnliga gymnasten",
        "key": "136"
    },
    {
        "searchTerm": "steuern",
        "answer": "styra",
        "key": "137"
    },
    {
        "searchTerm": "das Gleichgewicht",
        "answer": "jämvikten",
        "key": "138"
    },
    {
        "searchTerm": "die Sportart",
        "answer": "idrottsgrenen",
        "key": "139"
    },
    {
        "searchTerm": "die Ausdauer",
        "answer": "uthålligheten",
        "key": "140"
    },
    {
        "searchTerm": "werfen",
        "answer": "kasta",
        "key": "141"
    },
    {
        "searchTerm": "der Becher",
        "answer": "bägaren",
        "key": "142"
    },
    {
        "searchTerm": "hin und her",
        "answer": "fram och tillbaka",
        "key": "143"
    },
    {
        "searchTerm": "die Reaktionsfähigkeit",
        "answer": "reaktionsförmågan",
        "key": "144"
    },
    {
        "searchTerm": "das Geschick",
        "answer": "skickligheten",
        "key": "145"
    },
    {
        "searchTerm": "das Netz",
        "answer": "nätet",
        "key": "146"
    },
    {
        "searchTerm": "erreichen",
        "answer": "uppnå",
        "key": "147"
    },
    {
        "searchTerm": "die Geschwindigkeit",
        "answer": "hastigheten",
        "key": "148"
    },
    {
        "searchTerm": "der Nischensport",
        "answer": "specialidrotten",
        "key": "149"
    },
    {
        "searchTerm": "der Zuschauer",
        "answer": "åskådaren",
        "key": "150"
    },
    {
        "searchTerm": "die Wasseroberfläche",
        "answer": "vattenytan",
        "key": "151"
    },
    {
        "searchTerm": "die Luft anhalten",
        "answer": "hålla andan",
        "key": "152"
    },
    {
        "searchTerm": "der Auswechselspieler",
        "answer": "avbytaren",
        "key": "153"
    },
    {
        "searchTerm": "die Wendigkeit",
        "answer": "smidigheten",
        "key": "154"
    },
    {
        "searchTerm": "gegeneinander",
        "answer": "mot varandra",
        "key": "155"
    },
    {
        "searchTerm": "sogenannt",
        "answer": "så kallad",
        "key": "156"
    },
    {
        "searchTerm": "der Frame",
        "answer": "ramen",
        "key": "157"
    },
    {
        "searchTerm": "fair",
        "answer": "ärlig",
        "key": "158"
    },
    {
        "searchTerm": "der Gegner",
        "answer": "motståndaren",
        "key": "159"
    },
    {
        "searchTerm": "der Rasen",
        "answer": "gräsmattan",
        "key": "160"
    },
    {
        "searchTerm": "erfunden",
        "answer": "uppfunnit",
        "key": "161"
    },
    {
        "searchTerm": "verbreitet",
        "answer": "utbredd",
        "key": "162"
    },
    {
        "searchTerm": "übrigens",
        "answer": "för övrigt",
        "key": "163"
    },
    {
        "searchTerm": "das Geräusch",
        "answer": "ljudet",
        "key": "164"
    },
    {
        "searchTerm": "uppnå",
        "answer": "erreichen",
        "key": "165"
    },
    {
        "searchTerm": "olyckan",
        "answer": "der Unfall",
        "key": "166"
    },
    {
        "searchTerm": "sedan dess",
        "answer": "seitdem",
        "key": "167"
    },
    {
        "searchTerm": "lidelsen",
        "answer": "die Leidenschaft",
        "key": "168"
    },
    {
        "searchTerm": "idrottsföreningen",
        "answer": "der Sportverein",
        "key": "169"
    },
    {
        "searchTerm": "skoltävlingen",
        "answer": "der Schulwettkampf",
        "key": "170"
    },
    {
        "searchTerm": "hämtat",
        "answer": "geholt",
        "key": "171"
    },
    {
        "searchTerm": "intervjun",
        "answer": "das Interview",
        "key": "172"
    },
    {
        "searchTerm": "lyckönskningen",
        "answer": "der Glückwunsch",
        "key": "173"
    },
    {
        "searchTerm": "framgången",
        "answer": "der Erfolg",
        "key": "174"
    },
    {
        "searchTerm": "laget",
        "answer": "die Mannschaft",
        "key": "175"
    },
    {
        "searchTerm": "mötet",
        "answer": "das Treffen",
        "key": "176"
    },
    {
        "searchTerm": "det har fungerat",
        "answer": "es hat geklappt",
        "key": "177"
    },
    {
        "searchTerm": "låta",
        "answer": "lassen",
        "key": "178"
    },
    {
        "searchTerm": "betjäningen",
        "answer": "die Bedienung",
        "key": "179"
    },
    {
        "searchTerm": "älsklingsrätten",
        "answer": "das Lieblingsgericht",
        "key": "180"
    },
    {
        "searchTerm": "du tar",
        "answer": "du nimmst",
        "key": "181"
    },
    {
        "searchTerm": "bestämt",
        "answer": "entschieden",
        "key": "182"
    },
    {
        "searchTerm": "pizzan",
        "answer": "die Pizza",
        "key": "183"
    },
    {
        "searchTerm": "schnitzeln",
        "answer": "das Schnitzel",
        "key": "184"
    },
    {
        "searchTerm": "maten",
        "answer": "das Essen",
        "key": "185"
    },
    {
        "searchTerm": "få veta",
        "answer": "erfahren",
        "key": "186"
    },
    {
        "searchTerm": "börja",
        "answer": "angefangen",
        "key": "187"
    },
    {
        "searchTerm": "nu",
        "answer": "nun",
        "key": "188"
    },
    {
        "searchTerm": "fyra gånger i veckan",
        "answer": "viermal die Woche",
        "key": "189"
    },
    {
        "searchTerm": "läsaren",
        "answer": "der Leser",
        "key": "190"
    },
    {
        "searchTerm": "förklara",
        "answer": "erklären",
        "key": "191"
    },
    {
        "searchTerm": "varje",
        "answer": "jeder",
        "key": "192"
    },
    {
        "searchTerm": "precis",
        "answer": "genau",
        "key": "193"
    },
    {
        "searchTerm": "reguljär",
        "answer": "regulär",
        "key": "194"
    },
    {
        "searchTerm": "skillnaden",
        "answer": "der Unterschied",
        "key": "195"
    },
    {
        "searchTerm": "försvara",
        "answer": "verteidigen",
        "key": "196"
    },
    {
        "searchTerm": "korgen; målet",
        "answer": "der Korb",
        "key": "197"
    },
    {
        "searchTerm": "inte alls",
        "answer": "gar nicht",
        "key": "198"
    },
    {
        "searchTerm": "hastigheten",
        "answer": "die Geschwindigkeit",
        "key": "199"
    },
    {
        "searchTerm": "smidig",
        "answer": "wendig",
        "key": "200"
    },
    {
        "searchTerm": "kraften",
        "answer": "der Kraft",
        "key": "201"
    },
    {
        "searchTerm": "gemenskapen",
        "answer": "die Gemeinschaft",
        "key": "202"
    },
    {
        "searchTerm": "utmanad",
        "answer": "gefordert",
        "key": "203"
    },
    {
        "searchTerm": "allt sedan",
        "answer": "seitdem",
        "key": "204"
    },
    {
        "searchTerm": "handikappet",
        "answer": "die Behinderung",
        "key": "205"
    },
    {
        "searchTerm": "hindret",
        "answer": "das Hindernis",
        "key": "206"
    },
    {
        "searchTerm": "segern",
        "answer": "der Sieg",
        "key": "207"
    },
    {
        "searchTerm": "drömmen",
        "answer": "der Traum",
        "key": "208"
    },
    {
        "searchTerm": "friheten",
        "answer": "die Freiheit",
        "key": "209"
    },
    {
        "searchTerm": "oändligheten",
        "answer": "die Unendlichkeit",
        "key": "210"
    },
    {
        "searchTerm": "blogginlägget",
        "answer": "der Blogeintrag",
        "key": "211"
    },
    {
        "searchTerm": "började",
        "answer": "begann",
        "key": "212"
    },
    {
        "searchTerm": "fallskärmshoppet",
        "answer": "der Fallschirmsprung",
        "key": "213"
    },
    {
        "searchTerm": "fallet",
        "answer": "der Fall",
        "key": "214"
    },
    {
        "searchTerm": "gett",
        "answer": "geschenkt",
        "key": "215"
    },
    {
        "searchTerm": "för första gången",
        "answer": "zum ersten Mal",
        "key": "216"
    },
    {
        "searchTerm": "tomheten",
        "answer": "die Leere",
        "key": "217"
    },
    {
        "searchTerm": "våga",
        "answer": "wagen",
        "key": "218"
    },
    {
        "searchTerm": "upprörd",
        "answer": "aufgeregt",
        "key": "219"
    },
    {
        "searchTerm": "uthoppet",
        "answer": "der Absprung",
        "key": "220"
    },
    {
        "searchTerm": "redo",
        "answer": "bereit",
        "key": "221"
    },
    {
        "searchTerm": "otrolig",
        "answer": "unglaublich",
        "key": "222"
    },
    {
        "searchTerm": "rädslan",
        "answer": "die Angst",
        "key": "223"
    },
    {
        "searchTerm": "hjärtat",
        "answer": "das Herz",
        "key": "224"
    },
    {
        "searchTerm": "slå",
        "answer": "pochen",
        "key": "225"
    },
    {
        "searchTerm": "av upphetsning",
        "answer": "vor Aufregung",
        "key": "226"
    },
    {
        "searchTerm": "svettas",
        "answer": "schwitzen",
        "key": "227"
    },
    {
        "searchTerm": "darra",
        "answer": "zittern",
        "key": "228"
    },
    {
        "searchTerm": "hoppa",
        "answer": "springen",
        "key": "229"
    },
    {
        "searchTerm": "till slut",
        "answer": "schließlich",
        "key": "230"
    },
    {
        "searchTerm": "knuffa",
        "answer": "schubsen",
        "key": "231"
    },
    {
        "searchTerm": "obeskrivlig",
        "answer": "unbeschreiblich",
        "key": "232"
    },
    {
        "searchTerm": "aldrig någonsin",
        "answer": "noch nie",
        "key": "233"
    },
    {
        "searchTerm": "tidigare",
        "answer": "zuvor",
        "key": "234"
    },
    {
        "searchTerm": "känt",
        "answer": "gefühlt",
        "key": "235"
    },
    {
        "searchTerm": "störta till",
        "answer": "zurasen",
        "key": "236"
    },
    {
        "searchTerm": "mjukt",
        "answer": "sanft",
        "key": "237"
    },
    {
        "searchTerm": "lidelsen",
        "answer": "die Leidenschaft",
        "key": "238"
    },
    {
        "searchTerm": "balansgång på lina",
        "answer": "das Slacklining",
        "key": "239"
    },
    {
        "searchTerm": "driva på",
        "answer": "antreiben",
        "key": "240"
    },
    {
        "searchTerm": "utmaningen",
        "answer": "die Herausforderung",
        "key": "241"
    },
    {
        "searchTerm": "livet",
        "answer": "das Leben",
        "key": "242"
    },
    {
        "searchTerm": "berätta",
        "answer": "berichten",
        "key": "243"
    },
    {
        "searchTerm": "hallen",
        "answer": "die Halle",
        "key": "244"
    },
    {
        "searchTerm": "där",
        "answer": "dort",
        "key": "245"
    },
    {
        "searchTerm": "berget",
        "answer": "das Gebirge",
        "key": "246"
    },
    {
        "searchTerm": "visserligen",
        "answer": "zwar",
        "key": "247"
    },
    {
        "searchTerm": "säkrad",
        "answer": "gesichert",
        "key": "248"
    },
    {
        "searchTerm": "rutten",
        "answer": "die Route",
        "key": "249"
    },
    {
        "searchTerm": "kraften",
        "answer": "die Kraft",
        "key": "250"
    },
    {
        "searchTerm": "bemästra",
        "answer": "bewältigen",
        "key": "251"
    },
    {
        "searchTerm": "självförtroendet",
        "answer": "das Selbstvertrauen",
        "key": "252"
    },
    {
        "searchTerm": "lina av nylon eller polyester",
        "answer": "die Slackline",
        "key": "253"
    },
    {
        "searchTerm": "tunn",
        "answer": "dünn",
        "key": "254"
    },
    {
        "searchTerm": "bandet",
        "answer": "das Band",
        "key": "255"
    },
    {
        "searchTerm": "spänna",
        "answer": "spannen",
        "key": "256"
    },
    {
        "searchTerm": "klyftan",
        "answer": "die Schlucht",
        "key": "257"
    },
    {
        "searchTerm": "övervinna",
        "answer": "überwinden",
        "key": "258"
    },
    {
        "searchTerm": "klara",
        "answer": "schaffen",
        "key": "259"
    },
    {
        "searchTerm": "målet",
        "answer": "das Ziel",
        "key": "260"
    },
    {
        "searchTerm": "helt enkelt",
        "answer": "einfach",
        "key": "261"
    },
    {
        "searchTerm": "ta en risk",
        "answer": "ein Risiko eingehen",
        "key": "262"
    },
    {
        "searchTerm": "onödig",
        "answer": "unnötig",
        "key": "263"
    },
    {
        "searchTerm": "avgrunden",
        "answer": "der Abgrund",
        "key": "264"
    },
    {
        "searchTerm": "dra",
        "answer": "ziehen",
        "key": "265"
    },
    {
        "searchTerm": "steget",
        "answer": "der Schritt",
        "key": "266"
    },
    {
        "searchTerm": "övervinna",
        "answer": "überwinden",
        "key": "267"
    },
    {
        "searchTerm": "ökenvandringen",
        "answer": "die Wüstenwanderung",
        "key": "268"
    },
    {
        "searchTerm": "återvänt",
        "answer": "zurückgekehrt",
        "key": "269"
    },
    {
        "searchTerm": "dela",
        "answer": "teilen",
        "key": "270"
    },
    {
        "searchTerm": "upplevelsen",
        "answer": "das Erlebnis",
        "key": "271"
    },
    {
        "searchTerm": "blåsan",
        "answer": "die Blase",
        "key": "272"
    },
    {
        "searchTerm": "solsvedan",
        "answer": "der Sonnenbrand",
        "key": "273"
    },
    {
        "searchTerm": "klarat av",
        "answer": "geschafft",
        "key": "274"
    },
    {
        "searchTerm": "obarmhärtig",
        "answer": "gnadenlos",
        "key": "275"
    },
    {
        "searchTerm": "hettan",
        "answer": "die Hitze",
        "key": "276"
    },
    {
        "searchTerm": "på dagen",
        "answer": "tagsüber",
        "key": "277"
    },
    {
        "searchTerm": "rädslan",
        "answer": "die Angst",
        "key": "278"
    },
    {
        "searchTerm": "riktningen",
        "answer": "die Richtung",
        "key": "279"
    },
    {
        "searchTerm": "kompassen",
        "answer": "der Kompass",
        "key": "280"
    },
    {
        "searchTerm": "vardagen",
        "answer": "der Alltag",
        "key": "281"
    },
    {
        "searchTerm": "samtidigt",
        "answer": "zugleich",
        "key": "282"
    },
    {
        "searchTerm": "knappast",
        "answer": "kaum",
        "key": "283"
    },
    {
        "searchTerm": "våga",
        "answer": "sich trauen",
        "key": "284"
    },
    {
        "searchTerm": "framförallt",
        "answer": "vorallem",
        "key": "285"
    },
    {
        "searchTerm": "uppleva",
        "answer": "erleben",
        "key": "286"
    },
    {
        "searchTerm": "jorden",
        "answer": "die Erde",
        "key": "287"
    },
    {
        "searchTerm": "upptäcka",
        "answer": "entdecken",
        "key": "288"
    },
    {
        "searchTerm": "frusen",
        "answer": "gefroren",
        "key": "289"
    },
    {
        "searchTerm": "gymnastisera",
        "answer": "turnen",
        "key": "290"
    },
    {
        "searchTerm": "åka i en wokpanna",
        "answer": "in einer Wok-Pfanne rodeln",
        "key": "291"
    },
    {
        "searchTerm": "ovanlig",
        "answer": "ungewöhnlich",
        "key": "292"
    },
    {
        "searchTerm": "gymnastisera i ett bandhjul",
        "answer": "Rhönradturnen",
        "key": "293"
    },
    {
        "searchTerm": "omtyckt",
        "answer": "beliebt",
        "key": "294"
    },
    {
        "searchTerm": "bryggan",
        "answer": "die Brücke",
        "key": "295"
    },
    {
        "searchTerm": "armböjningarna",
        "answer": "die Liegestütze",
        "key": "296"
    },
    {
        "searchTerm": "ett moment där man släpper händerna",
        "answer": "der Freiflug",
        "key": "297"
    },
    {
        "searchTerm": "rotera",
        "answer": "drehen",
        "key": "298"
    },
    {
        "searchTerm": "den manlige gymnasten",
        "answer": "der Turner",
        "key": "299"
    },
    {
        "searchTerm": "den kvinnliga gymnasten",
        "answer": "die Turnerin",
        "key": "300"
    },
    {
        "searchTerm": "styra",
        "answer": "steuern",
        "key": "301"
    },
    {
        "searchTerm": "jämvikten",
        "answer": "das Gleichgewicht",
        "key": "302"
    },
    {
        "searchTerm": "idrottsgrenen",
        "answer": "die Sportart",
        "key": "303"
    },
    {
        "searchTerm": "uthålligheten",
        "answer": "die Ausdauer",
        "key": "304"
    },
    {
        "searchTerm": "kasta",
        "answer": "werfen",
        "key": "305"
    },
    {
        "searchTerm": "bägaren",
        "answer": "der Becher",
        "key": "306"
    },
    {
        "searchTerm": "fram och tillbaka",
        "answer": "hin und her",
        "key": "307"
    },
    {
        "searchTerm": "reaktionsförmågan",
        "answer": "die Reaktionsfähigkeit",
        "key": "308"
    },
    {
        "searchTerm": "skickligheten",
        "answer": "das Geschick",
        "key": "309"
    },
    {
        "searchTerm": "nätet",
        "answer": "das Netz",
        "key": "310"
    },
    {
        "searchTerm": "uppnå",
        "answer": "erreichen",
        "key": "311"
    },
    {
        "searchTerm": "hastigheten",
        "answer": "die Geschwindigkeit",
        "key": "312"
    },
    {
        "searchTerm": "specialidrotten",
        "answer": "der Nischensport",
        "key": "313"
    },
    {
        "searchTerm": "åskådaren",
        "answer": "der Zuschauer",
        "key": "314"
    },
    {
        "searchTerm": "vattenytan",
        "answer": "die Wasseroberfläche",
        "key": "315"
    },
    {
        "searchTerm": "hålla andan",
        "answer": "die Luft anhalten",
        "key": "316"
    },
    {
        "searchTerm": "avbytaren",
        "answer": "der Auswechselspieler",
        "key": "317"
    },
    {
        "searchTerm": "smidigheten",
        "answer": "die Wendigkeit",
        "key": "318"
    },
    {
        "searchTerm": "mot varandra",
        "answer": "gegeneinander",
        "key": "319"
    },
    {
        "searchTerm": "så kallad",
        "answer": "sogenannt",
        "key": "320"
    },
    {
        "searchTerm": "ramen",
        "answer": "der Frame",
        "key": "321"
    },
    {
        "searchTerm": "ärlig",
        "answer": "fair",
        "key": "322"
    },
    {
        "searchTerm": "motståndaren",
        "answer": "der Gegner",
        "key": "323"
    },
    {
        "searchTerm": "gräsmattan",
        "answer": "der Rasen",
        "key": "324"
    },
    {
        "searchTerm": "uppfunnit",
        "answer": "erfunden",
        "key": "325"
    },
    {
        "searchTerm": "utbredd",
        "answer": "verbreitet",
        "key": "326"
    },
    {
        "searchTerm": "för övrigt",
        "answer": "übrigens",
        "key": "327"
    },
    {
        "searchTerm": "ljudet",
        "answer": "das Geräusch",
        "key": "328"
    }
]

export default kapitel85;