const kapitel82 = [
    {
        "searchTerm": "umsteigen",
        "answer": "byta",
        "key": "1"
    },
    {
        "searchTerm": "nachschauen",
        "answer": "se efter",
        "key": "2"
    },
    {
        "searchTerm": "der Speisewagen",
        "answer": "restaurangvagnen",
        "key": "3"
    },
    {
        "searchTerm": "das Getränk",
        "answer": "drycken",
        "key": "4"
    },
    {
        "searchTerm": "der Fahrkartenschalter",
        "answer": "biljettluckan",
        "key": "5"
    },
    {
        "searchTerm": "einfach",
        "answer": "enkel",
        "key": "6"
    },
    {
        "searchTerm": "die Rückfahrt",
        "answer": "återresan",
        "key": "7"
    },
    {
        "searchTerm": "die Rückfahrkarte",
        "answer": "tur- och returbiljetten",
        "key": "8"
    },
    {
        "searchTerm": "die Studentenermäßigung",
        "answer": "studentrabatten",
        "key": "9"
    },
    {
        "searchTerm": "hin und zurück",
        "answer": "tur och retur",
        "key": "10"
    },
    {
        "searchTerm": "der Studentenausweis",
        "answer": "studentlegitimationen",
        "key": "11"
    },
    {
        "searchTerm": "die Abfahrt",
        "answer": "avfärden",
        "key": "12"
    },
    {
        "searchTerm": "die Ankunft",
        "answer": "ankomsten",
        "key": "13"
    },
    {
        "searchTerm": "abfahren",
        "answer": "avgå",
        "key": "14"
    },
    {
        "searchTerm": "der Bahnsteig",
        "answer": "plattformen",
        "key": "15"
    },
    {
        "searchTerm": "der Erwachsene",
        "answer": "den vuxne",
        "key": "16"
    },
    {
        "searchTerm": "falls",
        "answer": "om",
        "key": "17"
    },
    {
        "searchTerm": "einchecken",
        "answer": "checka in",
        "key": "18"
    },
    {
        "searchTerm": "ankommen",
        "answer": "anlända",
        "key": "19"
    },
    {
        "searchTerm": "die Bettwäsche",
        "answer": "sänglinnet",
        "key": "20"
    },
    {
        "searchTerm": "mitbringen",
        "answer": "ta med",
        "key": "21"
    },
    {
        "searchTerm": "mieten",
        "answer": "hyra",
        "key": "22"
    },
    {
        "searchTerm": "das Stockbett",
        "answer": "våningssängen",
        "key": "23"
    },
    {
        "searchTerm": "das Gepäck",
        "answer": "bagaget",
        "key": "24"
    },
    {
        "searchTerm": "abstellen",
        "answer": "ställa ifrån sig",
        "key": "25"
    },
    {
        "searchTerm": "das Handtuch",
        "answer": "handduken",
        "key": "26"
    },
    {
        "searchTerm": "empfehlen",
        "answer": "rekommendera",
        "key": "27"
    },
    {
        "searchTerm": "die Haltestelle",
        "answer": "busshållplatsen",
        "key": "28"
    },
    {
        "searchTerm": "der Schlüssel",
        "answer": "nyckeln",
        "key": "29"
    },
    {
        "searchTerm": "das Reiseziel",
        "answer": "resmålet",
        "key": "30"
    },
    {
        "searchTerm": "unbedingt",
        "answer": "tvunget",
        "key": "31"
    },
    {
        "searchTerm": "entschieden",
        "answer": "bestämt",
        "key": "32"
    },
    {
        "searchTerm": "nicht nur … sondern auch",
        "answer": "inte bara … utan också",
        "key": "33"
    },
    {
        "searchTerm": "der Dom",
        "answer": "domkyrkan",
        "key": "34"
    },
    {
        "searchTerm": "entdecken",
        "answer": "upptäcka",
        "key": "35"
    },
    {
        "searchTerm": "die Bestätigung",
        "answer": "bekräftelsen",
        "key": "36"
    },
    {
        "searchTerm": "gemacht",
        "answer": "gjort",
        "key": "37"
    },
    {
        "searchTerm": "nie",
        "answer": "aldrig",
        "key": "38"
    },
    {
        "searchTerm": "zuvor",
        "answer": "förr",
        "key": "39"
    },
    {
        "searchTerm": "getroffen",
        "answer": "träffat",
        "key": "40"
    },
    {
        "searchTerm": "das Erlebnis",
        "answer": "upplevelsen",
        "key": "41"
    },
    {
        "searchTerm": "Das war einfach der Hammer!",
        "answer": "Det var helt häftigt!",
        "key": "42"
    },
    {
        "searchTerm": "byta",
        "answer": "umsteigen",
        "key": "43"
    },
    {
        "searchTerm": "se efter",
        "answer": "nachschauen",
        "key": "44"
    },
    {
        "searchTerm": "restaurangvagnen",
        "answer": "der Speisewagen",
        "key": "45"
    },
    {
        "searchTerm": "drycken",
        "answer": "das Getränk",
        "key": "46"
    },
    {
        "searchTerm": "biljettluckan",
        "answer": "der Fahrkartenschalter",
        "key": "47"
    },
    {
        "searchTerm": "enkel",
        "answer": "einfach",
        "key": "48"
    },
    {
        "searchTerm": "återresan",
        "answer": "die Rückfahrt",
        "key": "49"
    },
    {
        "searchTerm": "tur- och returbiljetten",
        "answer": "die Rückfahrkarte",
        "key": "50"
    },
    {
        "searchTerm": "studentrabatten",
        "answer": "die Studentenermäßigung",
        "key": "51"
    },
    {
        "searchTerm": "tur och retur",
        "answer": "hin und zurück",
        "key": "52"
    },
    {
        "searchTerm": "studentlegitimationen",
        "answer": "der Studentenausweis",
        "key": "53"
    },
    {
        "searchTerm": "avfärden",
        "answer": "die Abfahrt",
        "key": "54"
    },
    {
        "searchTerm": "ankomsten",
        "answer": "die Ankunft",
        "key": "55"
    },
    {
        "searchTerm": "avgå",
        "answer": "abfahren",
        "key": "56"
    },
    {
        "searchTerm": "plattformen",
        "answer": "der Bahnsteig",
        "key": "57"
    },
    {
        "searchTerm": "den vuxne",
        "answer": "der Erwachsene",
        "key": "58"
    },
    {
        "searchTerm": "om",
        "answer": "falls",
        "key": "59"
    },
    {
        "searchTerm": "checka in",
        "answer": "einchecken",
        "key": "60"
    },
    {
        "searchTerm": "anlända",
        "answer": "ankommen",
        "key": "61"
    },
    {
        "searchTerm": "sänglinnet",
        "answer": "die Bettwäsche",
        "key": "62"
    },
    {
        "searchTerm": "ta med",
        "answer": "mitbringen",
        "key": "63"
    },
    {
        "searchTerm": "hyra",
        "answer": "mieten",
        "key": "64"
    },
    {
        "searchTerm": "våningssängen",
        "answer": "das Stockbett",
        "key": "65"
    },
    {
        "searchTerm": "bagaget",
        "answer": "das Gepäck",
        "key": "66"
    },
    {
        "searchTerm": "ställa ifrån sig",
        "answer": "abstellen",
        "key": "67"
    },
    {
        "searchTerm": "handduken",
        "answer": "das Handtuch",
        "key": "68"
    },
    {
        "searchTerm": "rekommendera",
        "answer": "empfehlen",
        "key": "69"
    },
    {
        "searchTerm": "busshållplatsen",
        "answer": "die Haltestelle",
        "key": "70"
    },
    {
        "searchTerm": "nyckeln",
        "answer": "der Schlüssel",
        "key": "71"
    },
    {
        "searchTerm": "resmålet",
        "answer": "das Reiseziel",
        "key": "72"
    },
    {
        "searchTerm": "tvunget",
        "answer": "unbedingt",
        "key": "73"
    },
    {
        "searchTerm": "bestämt",
        "answer": "entschieden",
        "key": "74"
    },
    {
        "searchTerm": "inte bara … utan också",
        "answer": "nicht nur … sondern auch",
        "key": "75"
    },
    {
        "searchTerm": "domkyrkan",
        "answer": "der Dom",
        "key": "76"
    },
    {
        "searchTerm": "upptäcka",
        "answer": "entdecken",
        "key": "77"
    },
    {
        "searchTerm": "bekräftelsen",
        "answer": "die Bestätigung",
        "key": "78"
    },
    {
        "searchTerm": "gjort",
        "answer": "gemacht",
        "key": "79"
    },
    {
        "searchTerm": "aldrig",
        "answer": "nie",
        "key": "80"
    },
    {
        "searchTerm": "förr",
        "answer": "zuvor",
        "key": "81"
    },
    {
        "searchTerm": "träffat",
        "answer": "getroffen",
        "key": "82"
    },
    {
        "searchTerm": "upplevelsen",
        "answer": "das Erlebnis",
        "key": "83"
    },
    {
        "searchTerm": "Det var helt häftigt!",
        "answer": "Das war einfach der Hammer!",
        "key": "84"
    }
]

export default kapitel82;