const kapitel95 = [
    {
        "searchTerm": "die Geschichtsstunde",
        "answer": "historietimmen",
        "key": "1"
    },
    {
        "searchTerm": "reden",
        "answer": "prata",
        "key": "2"
    },
    {
        "searchTerm": "die Macht",
        "answer": "makten",
        "key": "3"
    },
    {
        "searchTerm": "die Machtergreifung",
        "answer": "maktövertagandet",
        "key": "4"
    },
    {
        "searchTerm": "wahrscheinlich",
        "answer": "förmodligen",
        "key": "5"
    },
    {
        "searchTerm": "eingeführt",
        "answer": "infört",
        "key": "6"
    },
    {
        "searchTerm": "das Gesetz",
        "answer": "lagen",
        "key": "7"
    },
    {
        "searchTerm": "gegen",
        "answer": "mot",
        "key": "8"
    },
    {
        "searchTerm": "heiraten",
        "answer": "gifta sig",
        "key": "9"
    },
    {
        "searchTerm": "der Beamte",
        "answer": "tjänstemannen",
        "key": "10"
    },
    {
        "searchTerm": "erreichte",
        "answer": "nådde",
        "key": "11"
    },
    {
        "searchTerm": "der Nazi",
        "answer": "nazisten",
        "key": "12"
    },
    {
        "searchTerm": "angezündet",
        "answer": "tänt på",
        "key": "13"
    },
    {
        "searchTerm": "zerstören",
        "answer": "förstöra",
        "key": "14"
    },
    {
        "searchTerm": "veranstalten",
        "answer": "anordna",
        "key": "15"
    },
    {
        "searchTerm": "die Verfolgung",
        "answer": "förföljelsen",
        "key": "16"
    },
    {
        "searchTerm": "außerdem",
        "answer": "dessutom",
        "key": "17"
    },
    {
        "searchTerm": "der Stern",
        "answer": "stjärnan",
        "key": "18"
    },
    {
        "searchTerm": "schließlich",
        "answer": "slutligen",
        "key": "19"
    },
    {
        "searchTerm": "seit",
        "answer": "sedan",
        "key": "20"
    },
    {
        "searchTerm": "eingesperrt",
        "answer": "inspärrat",
        "key": "21"
    },
    {
        "searchTerm": "das Vernichtungslager",
        "answer": "förintelselägret",
        "key": "22"
    },
    {
        "searchTerm": "gebaut",
        "answer": "byggt",
        "key": "23"
    },
    {
        "searchTerm": "der Zweck",
        "answer": "ändamålet",
        "key": "24"
    },
    {
        "searchTerm": "töten",
        "answer": "döda",
        "key": "25"
    },
    {
        "searchTerm": "ziemlich",
        "answer": "ganska",
        "key": "26"
    },
    {
        "searchTerm": "angegriffen",
        "answer": "angripit",
        "key": "27"
    },
    {
        "searchTerm": "nach und nach",
        "answer": "efterhand",
        "key": "28"
    },
    {
        "searchTerm": "besetzen",
        "answer": "ockupera",
        "key": "29"
    },
    {
        "searchTerm": "die Wende",
        "answer": "vändpunkten",
        "key": "30"
    },
    {
        "searchTerm": "gescheitert",
        "answer": "misslyckats",
        "key": "31"
    },
    {
        "searchTerm": "der Staat",
        "answer": "staten",
        "key": "32"
    },
    {
        "searchTerm": "die Mauer",
        "answer": "muren",
        "key": "33"
    },
    {
        "searchTerm": "aufgeteilt",
        "answer": "uppdelad",
        "key": "34"
    },
    {
        "searchTerm": "jeweils",
        "answer": "var och en",
        "key": "35"
    },
    {
        "searchTerm": "sich verstand",
        "answer": "kom överens",
        "key": "36"
    },
    {
        "searchTerm": "stellt euch vor",
        "answer": "föreställ er",
        "key": "37"
    },
    {
        "searchTerm": "quer",
        "answer": "tvärs",
        "key": "38"
    },
    {
        "searchTerm": "Leute sind geflohen",
        "answer": "folk har flytt",
        "key": "39"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "medbestämmandet",
        "key": "40"
    },
    {
        "searchTerm": "verhindern",
        "answer": "förhindra",
        "key": "41"
    },
    {
        "searchTerm": "der Versuch",
        "answer": "försöket",
        "key": "42"
    },
    {
        "searchTerm": "ist gestorben",
        "answer": "har dött",
        "key": "43"
    },
    {
        "searchTerm": "geschafft",
        "answer": "klarat sig",
        "key": "44"
    },
    {
        "searchTerm": "die Grenze",
        "answer": "gränsen",
        "key": "45"
    },
    {
        "searchTerm": "wurde geöffnet",
        "answer": "öppnades",
        "key": "46"
    },
    {
        "searchTerm": "getanzt",
        "answer": "dansat",
        "key": "47"
    },
    {
        "searchTerm": "irre",
        "answer": "här",
        "key": "48"
    },
    {
        "searchTerm": "aufgelöst",
        "answer": "upplöst",
        "key": "49"
    },
    {
        "searchTerm": "grausam",
        "answer": "grym",
        "key": "50"
    },
    {
        "searchTerm": "der Bürgerkrieg",
        "answer": "inbördeskriget",
        "key": "51"
    },
    {
        "searchTerm": "bereits",
        "answer": "redan",
        "key": "52"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "flykten",
        "key": "53"
    },
    {
        "searchTerm": "verlassen",
        "answer": "lämna",
        "key": "54"
    },
    {
        "searchTerm": "der Flüchtling",
        "answer": "flyktingen",
        "key": "55"
    },
    {
        "searchTerm": "die Spende",
        "answer": "gåvan",
        "key": "56"
    },
    {
        "searchTerm": "die Hafenstadt",
        "answer": "hamnstaden",
        "key": "57"
    },
    {
        "searchTerm": "der Einwohner",
        "answer": "invånaren",
        "key": "58"
    },
    {
        "searchTerm": "die Entführung",
        "answer": "kidnappningen",
        "key": "59"
    },
    {
        "searchTerm": "zurückkehren",
        "answer": "återvända",
        "key": "60"
    },
    {
        "searchTerm": "tot",
        "answer": "död",
        "key": "61"
    },
    {
        "searchTerm": "die Angehörigen",
        "answer": "de anhöriga",
        "key": "62"
    },
    {
        "searchTerm": "das Lösegeld",
        "answer": "lösen",
        "key": "63"
    },
    {
        "searchTerm": "die Leiche",
        "answer": "liket",
        "key": "64"
    },
    {
        "searchTerm": "sich entschließen zu",
        "answer": "besluta sig att",
        "key": "65"
    },
    {
        "searchTerm": "der Schlepper",
        "answer": "människosmugglaren",
        "key": "66"
    },
    {
        "searchTerm": "drücken",
        "answer": "trycka",
        "key": "67"
    },
    {
        "searchTerm": "überqueren",
        "answer": "korsa",
        "key": "68"
    },
    {
        "searchTerm": "das Meer",
        "answer": "havet",
        "key": "69"
    },
    {
        "searchTerm": "rechtzeitig",
        "answer": "i tid",
        "key": "70"
    },
    {
        "searchTerm": "gebracht werden",
        "answer": "bli förd",
        "key": "71"
    },
    {
        "searchTerm": "„Was blieb uns anderes übrig?”",
        "answer": "“Vad hade vi för alternativ”?",
        "key": "72"
    },
    {
        "searchTerm": "empfangen",
        "answer": "mottagit",
        "key": "73"
    },
    {
        "searchTerm": "Asyl beantragen",
        "answer": "ansöka om asyl",
        "key": "74"
    },
    {
        "searchTerm": "genehmigt",
        "answer": "beviljat",
        "key": "75"
    },
    {
        "searchTerm": "umsonst",
        "answer": "gratis",
        "key": "76"
    },
    {
        "searchTerm": "irgendwann",
        "answer": "någon gång",
        "key": "77"
    },
    {
        "searchTerm": "nachholen",
        "answer": "hämta",
        "key": "78"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "hembygden",
        "key": "79"
    },
    {
        "searchTerm": "gehören zu",
        "answer": "hör till",
        "key": "80"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "världslitteraturen",
        "key": "81"
    },
    {
        "searchTerm": "bezeichnen",
        "answer": "beteckna",
        "key": "82"
    },
    {
        "searchTerm": "der Dichter",
        "answer": "diktaren",
        "key": "83"
    },
    {
        "searchTerm": "Jura",
        "answer": "juridik",
        "key": "84"
    },
    {
        "searchTerm": "gab",
        "answer": "gav",
        "key": "85"
    },
    {
        "searchTerm": "der Anlass",
        "answer": "anledningen",
        "key": "86"
    },
    {
        "searchTerm": "Die Leiden des jungen Werthers",
        "answer": "Den unge Werthers lidande",
        "key": "87"
    },
    {
        "searchTerm": "der Welterfolg",
        "answer": "världsframgången",
        "key": "88"
    },
    {
        "searchTerm": "der Weltveränderer",
        "answer": "världsförändraren",
        "key": "89"
    },
    {
        "searchTerm": "bedeutend",
        "answer": "betydande",
        "key": "90"
    },
    {
        "searchTerm": "die Persönlichkeit",
        "answer": "personligheten",
        "key": "91"
    },
    {
        "searchTerm": "irgendwie",
        "answer": "på något sätt",
        "key": "92"
    },
    {
        "searchTerm": "bewirken",
        "answer": "åstadkomma",
        "key": "93"
    },
    {
        "searchTerm": "erfahren",
        "answer": "få veta",
        "key": "94"
    },
    {
        "searchTerm": "ein bisschen",
        "answer": "lite grann",
        "key": "95"
    },
    {
        "searchTerm": "entdeckte",
        "answer": "upptäckte",
        "key": "96"
    },
    {
        "searchTerm": "nannten",
        "answer": "kallade",
        "key": "97"
    },
    {
        "searchTerm": "die Röntgenaufnahme",
        "answer": "röntgenbilden",
        "key": "98"
    },
    {
        "searchTerm": "der Fortschritt",
        "answer": "framsteget",
        "key": "99"
    },
    {
        "searchTerm": "der Meilenstein",
        "answer": "milstolpen",
        "key": "100"
    },
    {
        "searchTerm": "der Körper",
        "answer": "kroppen",
        "key": "101"
    },
    {
        "searchTerm": "die Knochenverletzung",
        "answer": "benbrottet",
        "key": "102"
    },
    {
        "searchTerm": "die Lungenkrankheit",
        "answer": "lungsjukdomen",
        "key": "103"
    },
    {
        "searchTerm": "entdecken",
        "answer": "upptäcka",
        "key": "104"
    },
    {
        "searchTerm": "der Spitzenschüler",
        "answer": "spetseleven",
        "key": "105"
    },
    {
        "searchTerm": "der Forscher",
        "answer": "forskaren",
        "key": "106"
    },
    {
        "searchTerm": "der Kämpfer",
        "answer": "kämpen",
        "key": "107"
    },
    {
        "searchTerm": "zog",
        "answer": "flyttade",
        "key": "108"
    },
    {
        "searchTerm": "älter",
        "answer": "äldre",
        "key": "109"
    },
    {
        "searchTerm": "die Widerstandsgruppe",
        "answer": "motståndsgruppen",
        "key": "110"
    },
    {
        "searchTerm": "gegründet",
        "answer": "grundat",
        "key": "111"
    },
    {
        "searchTerm": "schloss sich an",
        "answer": "anslöt sig till",
        "key": "112"
    },
    {
        "searchTerm": "verteilten",
        "answer": "utdelade",
        "key": "113"
    },
    {
        "searchTerm": "das Flugblatt",
        "answer": "flygbladet",
        "key": "114"
    },
    {
        "searchTerm": "richtete",
        "answer": "riktade",
        "key": "115"
    },
    {
        "searchTerm": "der Hausmeister",
        "answer": "vaktmästaren",
        "key": "116"
    },
    {
        "searchTerm": "verhaften",
        "answer": "häkta",
        "key": "117"
    },
    {
        "searchTerm": "zum Tode verurteilen",
        "answer": "döma till döden",
        "key": "118"
    },
    {
        "searchTerm": "hingerichtet",
        "answer": "avrättad",
        "key": "119"
    },
    {
        "searchTerm": "die Geschwister",
        "answer": "syskonen",
        "key": "120"
    },
    {
        "searchTerm": "die Bundeskanzlerin",
        "answer": "förbundskanslern (kvinnlig)",
        "key": "121"
    },
    {
        "searchTerm": "das Amt",
        "answer": "ämbetet",
        "key": "122"
    },
    {
        "searchTerm": "innehaben",
        "answer": "inneha",
        "key": "123"
    },
    {
        "searchTerm": "damalig",
        "answer": "dåvarande",
        "key": "124"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "studentexamen",
        "key": "125"
    },
    {
        "searchTerm": "zeigte",
        "answer": "visade",
        "key": "126"
    },
    {
        "searchTerm": "die Wiedervereinigung",
        "answer": "återföreningen",
        "key": "127"
    },
    {
        "searchTerm": "die Bundesumweltministerin",
        "answer": "miljöministern (kvinnlig)",
        "key": "128"
    },
    {
        "searchTerm": "der Vorsitzende",
        "answer": "ordföranden",
        "key": "129"
    },
    {
        "searchTerm": "historietimmen",
        "answer": "die Geschichtsstunde",
        "key": "130"
    },
    {
        "searchTerm": "prata",
        "answer": "reden",
        "key": "131"
    },
    {
        "searchTerm": "makten",
        "answer": "die Macht",
        "key": "132"
    },
    {
        "searchTerm": "maktövertagandet",
        "answer": "die Machtergreifung",
        "key": "133"
    },
    {
        "searchTerm": "förmodligen",
        "answer": "wahrscheinlich",
        "key": "134"
    },
    {
        "searchTerm": "infört",
        "answer": "eingeführt",
        "key": "135"
    },
    {
        "searchTerm": "lagen",
        "answer": "das Gesetz",
        "key": "136"
    },
    {
        "searchTerm": "mot",
        "answer": "gegen",
        "key": "137"
    },
    {
        "searchTerm": "gifta sig",
        "answer": "heiraten",
        "key": "138"
    },
    {
        "searchTerm": "tjänstemannen",
        "answer": "der Beamte",
        "key": "139"
    },
    {
        "searchTerm": "nådde",
        "answer": "erreichte",
        "key": "140"
    },
    {
        "searchTerm": "nazisten",
        "answer": "der Nazi",
        "key": "141"
    },
    {
        "searchTerm": "tänt på",
        "answer": "angezündet",
        "key": "142"
    },
    {
        "searchTerm": "förstöra",
        "answer": "zerstören",
        "key": "143"
    },
    {
        "searchTerm": "anordna",
        "answer": "veranstalten",
        "key": "144"
    },
    {
        "searchTerm": "förföljelsen",
        "answer": "die Verfolgung",
        "key": "145"
    },
    {
        "searchTerm": "dessutom",
        "answer": "außerdem",
        "key": "146"
    },
    {
        "searchTerm": "stjärnan",
        "answer": "der Stern",
        "key": "147"
    },
    {
        "searchTerm": "slutligen",
        "answer": "schließlich",
        "key": "148"
    },
    {
        "searchTerm": "sedan",
        "answer": "seit",
        "key": "149"
    },
    {
        "searchTerm": "inspärrat",
        "answer": "eingesperrt",
        "key": "150"
    },
    {
        "searchTerm": "förintelselägret",
        "answer": "das Vernichtungslager",
        "key": "151"
    },
    {
        "searchTerm": "byggt",
        "answer": "gebaut",
        "key": "152"
    },
    {
        "searchTerm": "ändamålet",
        "answer": "der Zweck",
        "key": "153"
    },
    {
        "searchTerm": "döda",
        "answer": "töten",
        "key": "154"
    },
    {
        "searchTerm": "ganska",
        "answer": "ziemlich",
        "key": "155"
    },
    {
        "searchTerm": "angripit",
        "answer": "angegriffen",
        "key": "156"
    },
    {
        "searchTerm": "efterhand",
        "answer": "nach und nach",
        "key": "157"
    },
    {
        "searchTerm": "ockupera",
        "answer": "besetzen",
        "key": "158"
    },
    {
        "searchTerm": "vändpunkten",
        "answer": "die Wende",
        "key": "159"
    },
    {
        "searchTerm": "misslyckats",
        "answer": "gescheitert",
        "key": "160"
    },
    {
        "searchTerm": "staten",
        "answer": "der Staat",
        "key": "161"
    },
    {
        "searchTerm": "muren",
        "answer": "die Mauer",
        "key": "162"
    },
    {
        "searchTerm": "uppdelad",
        "answer": "aufgeteilt",
        "key": "163"
    },
    {
        "searchTerm": "var och en",
        "answer": "jeweils",
        "key": "164"
    },
    {
        "searchTerm": "kom överens",
        "answer": "sich verstand",
        "key": "165"
    },
    {
        "searchTerm": "föreställ er",
        "answer": "stellt euch vor",
        "key": "166"
    },
    {
        "searchTerm": "tvärs",
        "answer": "quer",
        "key": "167"
    },
    {
        "searchTerm": "folk har flytt",
        "answer": "Leute sind geflohen",
        "key": "168"
    },
    {
        "searchTerm": "medbestämmandet",
        "answer": "Deutsch",
        "key": "169"
    },
    {
        "searchTerm": "förhindra",
        "answer": "verhindern",
        "key": "170"
    },
    {
        "searchTerm": "försöket",
        "answer": "der Versuch",
        "key": "171"
    },
    {
        "searchTerm": "har dött",
        "answer": "ist gestorben",
        "key": "172"
    },
    {
        "searchTerm": "klarat sig",
        "answer": "geschafft",
        "key": "173"
    },
    {
        "searchTerm": "gränsen",
        "answer": "die Grenze",
        "key": "174"
    },
    {
        "searchTerm": "öppnades",
        "answer": "wurde geöffnet",
        "key": "175"
    },
    {
        "searchTerm": "dansat",
        "answer": "getanzt",
        "key": "176"
    },
    {
        "searchTerm": "här",
        "answer": "irre",
        "key": "177"
    },
    {
        "searchTerm": "upplöst",
        "answer": "aufgelöst",
        "key": "178"
    },
    {
        "searchTerm": "grym",
        "answer": "grausam",
        "key": "179"
    },
    {
        "searchTerm": "inbördeskriget",
        "answer": "der Bürgerkrieg",
        "key": "180"
    },
    {
        "searchTerm": "redan",
        "answer": "bereits",
        "key": "181"
    },
    {
        "searchTerm": "flykten",
        "answer": "Deutsch",
        "key": "182"
    },
    {
        "searchTerm": "lämna",
        "answer": "verlassen",
        "key": "183"
    },
    {
        "searchTerm": "flyktingen",
        "answer": "der Flüchtling",
        "key": "184"
    },
    {
        "searchTerm": "gåvan",
        "answer": "die Spende",
        "key": "185"
    },
    {
        "searchTerm": "hamnstaden",
        "answer": "die Hafenstadt",
        "key": "186"
    },
    {
        "searchTerm": "invånaren",
        "answer": "der Einwohner",
        "key": "187"
    },
    {
        "searchTerm": "kidnappningen",
        "answer": "die Entführung",
        "key": "188"
    },
    {
        "searchTerm": "återvända",
        "answer": "zurückkehren",
        "key": "189"
    },
    {
        "searchTerm": "död",
        "answer": "tot",
        "key": "190"
    },
    {
        "searchTerm": "de anhöriga",
        "answer": "die Angehörigen",
        "key": "191"
    },
    {
        "searchTerm": "lösen",
        "answer": "das Lösegeld",
        "key": "192"
    },
    {
        "searchTerm": "liket",
        "answer": "die Leiche",
        "key": "193"
    },
    {
        "searchTerm": "besluta sig att",
        "answer": "sich entschließen zu",
        "key": "194"
    },
    {
        "searchTerm": "människosmugglaren",
        "answer": "der Schlepper",
        "key": "195"
    },
    {
        "searchTerm": "trycka",
        "answer": "drücken",
        "key": "196"
    },
    {
        "searchTerm": "korsa",
        "answer": "überqueren",
        "key": "197"
    },
    {
        "searchTerm": "havet",
        "answer": "das Meer",
        "key": "198"
    },
    {
        "searchTerm": "i tid",
        "answer": "rechtzeitig",
        "key": "199"
    },
    {
        "searchTerm": "bli förd",
        "answer": "gebracht werden",
        "key": "200"
    },
    {
        "searchTerm": "“Vad hade vi för alternativ”?",
        "answer": "„Was blieb uns anderes übrig?”",
        "key": "201"
    },
    {
        "searchTerm": "mottagit",
        "answer": "empfangen",
        "key": "202"
    },
    {
        "searchTerm": "ansöka om asyl",
        "answer": "Asyl beantragen",
        "key": "203"
    },
    {
        "searchTerm": "beviljat",
        "answer": "genehmigt",
        "key": "204"
    },
    {
        "searchTerm": "gratis",
        "answer": "umsonst",
        "key": "205"
    },
    {
        "searchTerm": "någon gång",
        "answer": "irgendwann",
        "key": "206"
    },
    {
        "searchTerm": "hämta",
        "answer": "nachholen",
        "key": "207"
    },
    {
        "searchTerm": "hembygden",
        "answer": "Deutsch",
        "key": "208"
    },
    {
        "searchTerm": "hör till",
        "answer": "gehören zu",
        "key": "209"
    },
    {
        "searchTerm": "världslitteraturen",
        "answer": "Deutsch",
        "key": "210"
    },
    {
        "searchTerm": "beteckna",
        "answer": "bezeichnen",
        "key": "211"
    },
    {
        "searchTerm": "diktaren",
        "answer": "der Dichter",
        "key": "212"
    },
    {
        "searchTerm": "juridik",
        "answer": "Jura",
        "key": "213"
    },
    {
        "searchTerm": "gav",
        "answer": "gab",
        "key": "214"
    },
    {
        "searchTerm": "anledningen",
        "answer": "der Anlass",
        "key": "215"
    },
    {
        "searchTerm": "Den unge Werthers lidande",
        "answer": "Die Leiden des jungen Werthers",
        "key": "216"
    },
    {
        "searchTerm": "världsframgången",
        "answer": "der Welterfolg",
        "key": "217"
    },
    {
        "searchTerm": "världsförändraren",
        "answer": "der Weltveränderer",
        "key": "218"
    },
    {
        "searchTerm": "betydande",
        "answer": "bedeutend",
        "key": "219"
    },
    {
        "searchTerm": "personligheten",
        "answer": "die Persönlichkeit",
        "key": "220"
    },
    {
        "searchTerm": "på något sätt",
        "answer": "irgendwie",
        "key": "221"
    },
    {
        "searchTerm": "åstadkomma",
        "answer": "bewirken",
        "key": "222"
    },
    {
        "searchTerm": "få veta",
        "answer": "erfahren",
        "key": "223"
    },
    {
        "searchTerm": "lite grann",
        "answer": "ein bisschen",
        "key": "224"
    },
    {
        "searchTerm": "upptäckte",
        "answer": "entdeckte",
        "key": "225"
    },
    {
        "searchTerm": "kallade",
        "answer": "nannten",
        "key": "226"
    },
    {
        "searchTerm": "röntgenbilden",
        "answer": "die Röntgenaufnahme",
        "key": "227"
    },
    {
        "searchTerm": "framsteget",
        "answer": "der Fortschritt",
        "key": "228"
    },
    {
        "searchTerm": "milstolpen",
        "answer": "der Meilenstein",
        "key": "229"
    },
    {
        "searchTerm": "kroppen",
        "answer": "der Körper",
        "key": "230"
    },
    {
        "searchTerm": "benbrottet",
        "answer": "die Knochenverletzung",
        "key": "231"
    },
    {
        "searchTerm": "lungsjukdomen",
        "answer": "die Lungenkrankheit",
        "key": "232"
    },
    {
        "searchTerm": "upptäcka",
        "answer": "entdecken",
        "key": "233"
    },
    {
        "searchTerm": "spetseleven",
        "answer": "der Spitzenschüler",
        "key": "234"
    },
    {
        "searchTerm": "forskaren",
        "answer": "der Forscher",
        "key": "235"
    },
    {
        "searchTerm": "kämpen",
        "answer": "der Kämpfer",
        "key": "236"
    },
    {
        "searchTerm": "flyttade",
        "answer": "zog",
        "key": "237"
    },
    {
        "searchTerm": "äldre",
        "answer": "älter",
        "key": "238"
    },
    {
        "searchTerm": "motståndsgruppen",
        "answer": "die Widerstandsgruppe",
        "key": "239"
    },
    {
        "searchTerm": "grundat",
        "answer": "gegründet",
        "key": "240"
    },
    {
        "searchTerm": "anslöt sig till",
        "answer": "schloss sich an",
        "key": "241"
    },
    {
        "searchTerm": "utdelade",
        "answer": "verteilten",
        "key": "242"
    },
    {
        "searchTerm": "flygbladet",
        "answer": "das Flugblatt",
        "key": "243"
    },
    {
        "searchTerm": "riktade",
        "answer": "richtete",
        "key": "244"
    },
    {
        "searchTerm": "vaktmästaren",
        "answer": "der Hausmeister",
        "key": "245"
    },
    {
        "searchTerm": "häkta",
        "answer": "verhaften",
        "key": "246"
    },
    {
        "searchTerm": "döma till döden",
        "answer": "zum Tode verurteilen",
        "key": "247"
    },
    {
        "searchTerm": "avrättad",
        "answer": "hingerichtet",
        "key": "248"
    },
    {
        "searchTerm": "syskonen",
        "answer": "die Geschwister",
        "key": "249"
    },
    {
        "searchTerm": "förbundskanslern (kvinnlig)",
        "answer": "die Bundeskanzlerin",
        "key": "250"
    },
    {
        "searchTerm": "ämbetet",
        "answer": "das Amt",
        "key": "251"
    },
    {
        "searchTerm": "inneha",
        "answer": "innehaben",
        "key": "252"
    },
    {
        "searchTerm": "dåvarande",
        "answer": "damalig",
        "key": "253"
    },
    {
        "searchTerm": "studentexamen",
        "answer": "Deutsch",
        "key": "254"
    },
    {
        "searchTerm": "visade",
        "answer": "zeigte",
        "key": "255"
    },
    {
        "searchTerm": "återföreningen",
        "answer": "die Wiedervereinigung",
        "key": "256"
    },
    {
        "searchTerm": "miljöministern (kvinnlig)",
        "answer": "die Bundesumweltministerin",
        "key": "257"
    },
    {
        "searchTerm": "ordföranden",
        "answer": "der Vorsitzende",
        "key": "258"
    }
]

export default kapitel95;