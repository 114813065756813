const kapitel86 = [
    {
        "searchTerm": "das Kino",
        "answer": "bion",
        "key": "1"
    },
    {
        "searchTerm": "die Eiskönigin",
        "answer": "Frost",
        "key": "2"
    },
    {
        "searchTerm": "unbeabsichtigt",
        "answer": "oavsiktligt",
        "key": "3"
    },
    {
        "searchTerm": "erfunden",
        "answer": "uppfunnit",
        "key": "4"
    },
    {
        "searchTerm": "hinunter",
        "answer": "utför",
        "key": "5"
    },
    {
        "searchTerm": "versetzen",
        "answer": "försätta",
        "key": "6"
    },
    {
        "searchTerm": "das Königreich",
        "answer": "kungariket",
        "key": "7"
    },
    {
        "searchTerm": "meistern",
        "answer": "bemästra",
        "key": "8"
    },
    {
        "searchTerm": "die Tribute von Panem",
        "answer": "Hungerspelen",
        "key": "9"
    },
    {
        "searchTerm": "spielen",
        "answer": "utspela sig",
        "key": "10"
    },
    {
        "searchTerm": "unterdrücken",
        "answer": "kuva",
        "key": "11"
    },
    {
        "searchTerm": "wählen",
        "answer": "välja",
        "key": "12"
    },
    {
        "searchTerm": "an den Kampfspielen teilnehmen",
        "answer": "delta i tävlingarna",
        "key": "13"
    },
    {
        "searchTerm": "der Tod",
        "answer": "döden",
        "key": "14"
    },
    {
        "searchTerm": "die Protagonistin",
        "answer": "huvudpersonen",
        "key": "15"
    },
    {
        "searchTerm": "die Befreiung",
        "answer": "befrielsen",
        "key": "16"
    },
    {
        "searchTerm": "besprechen",
        "answer": "samtala",
        "key": "17"
    },
    {
        "searchTerm": "die Szene",
        "answer": "scenen",
        "key": "18"
    },
    {
        "searchTerm": "musikälskaren",
        "answer": "angripen",
        "key": "19"
    },
    {
        "searchTerm": "auswendig",
        "answer": "utantill",
        "key": "20"
    },
    {
        "searchTerm": "es gefällt mir",
        "answer": "jag tycker om",
        "key": "21"
    },
    {
        "searchTerm": "das Lieblingslied",
        "answer": "älsklingslåten",
        "key": "22"
    },
    {
        "searchTerm": "verschieden",
        "answer": "olik",
        "key": "23"
    },
    {
        "searchTerm": "eingängig",
        "answer": "enkel",
        "key": "24"
    },
    {
        "searchTerm": "die Kunst",
        "answer": "konsten",
        "key": "25"
    },
    {
        "searchTerm": "der Flug",
        "answer": "flykten",
        "key": "26"
    },
    {
        "searchTerm": "der Klavierunterricht",
        "answer": "pianolektionen",
        "key": "27"
    },
    {
        "searchTerm": "genießen",
        "answer": "njuta av",
        "key": "28"
    },
    {
        "searchTerm": "der Komponist",
        "answer": "kompositören",
        "key": "29"
    },
    {
        "searchTerm": "egal",
        "answer": "likgiltigt",
        "key": "30"
    },
    {
        "searchTerm": "die Bandgründung",
        "answer": "bildandet av ett band",
        "key": "31"
    },
    {
        "searchTerm": "der Gesang",
        "answer": "sången",
        "key": "32"
    },
    {
        "searchTerm": "das Mitglied",
        "answer": "medlemmen",
        "key": "33"
    },
    {
        "searchTerm": "die Chart",
        "answer": "topplistan",
        "key": "34"
    },
    {
        "searchTerm": "das Bandcasting",
        "answer": "provspelningen",
        "key": "35"
    },
    {
        "searchTerm": "der Proberaum",
        "answer": "replokalen",
        "key": "36"
    },
    {
        "searchTerm": "ausgestattet",
        "answer": "utrustat",
        "key": "37"
    },
    {
        "searchTerm": "das Schlagzeug",
        "answer": "slagverket",
        "key": "38"
    },
    {
        "searchTerm": "das Cello",
        "answer": "cellon",
        "key": "39"
    },
    {
        "searchTerm": "vorspielen",
        "answer": "spela upp",
        "key": "40"
    },
    {
        "searchTerm": "geil",
        "answer": "häftigt",
        "key": "41"
    },
    {
        "searchTerm": "unbedingt",
        "answer": "tvunget",
        "key": "42"
    },
    {
        "searchTerm": "auf jeden Fall",
        "answer": "under alla förhållandevarje",
        "key": "43"
    },
    {
        "searchTerm": "meinen",
        "answer": "anse",
        "key": "44"
    },
    {
        "searchTerm": "fabelhaft",
        "answer": "otrolig",
        "key": "45"
    },
    {
        "searchTerm": "proben",
        "answer": "prova",
        "key": "46"
    },
    {
        "searchTerm": "das Musikfestival",
        "answer": "musikfestivalen",
        "key": "47"
    },
    {
        "searchTerm": "das Konzert",
        "answer": "konserten",
        "key": "48"
    },
    {
        "searchTerm": "der Musikliebhaber",
        "answer": "musikälskaren",
        "key": "49"
    },
    {
        "searchTerm": "der Geschmack",
        "answer": "smaken",
        "key": "50"
    },
    {
        "searchTerm": "kostenlos",
        "answer": "gratis",
        "key": "51"
    },
    {
        "searchTerm": "veranstaltet",
        "answer": "arrangerat",
        "key": "52"
    },
    {
        "searchTerm": "die Geschichte",
        "answer": "historian",
        "key": "53"
    },
    {
        "searchTerm": "der Auftritt",
        "answer": "entrén",
        "key": "54"
    },
    {
        "searchTerm": "das Gewitter",
        "answer": "åskan",
        "key": "55"
    },
    {
        "searchTerm": "die Bühne",
        "answer": "scenen",
        "key": "56"
    },
    {
        "searchTerm": "überschwemmt  ",
        "answer": "översvämmad",
        "key": "57"
    },
    {
        "searchTerm": "ausfielen",
        "answer": "upphörde att fungera",
        "key": "58"
    },
    {
        "searchTerm": "bedeutend",
        "answer": "mest betydelsefulla",
        "key": "59"
    },
    {
        "searchTerm": "die Kosten",
        "answer": "omkostnaderna",
        "key": "60"
    },
    {
        "searchTerm": "unter Beweis stellen",
        "answer": "bevisa",
        "key": "61"
    },
    {
        "searchTerm": "der Star",
        "answer": "stjärnan",
        "key": "62"
    },
    {
        "searchTerm": "das Feld",
        "answer": "fältet",
        "key": "63"
    },
    {
        "searchTerm": "das Festivalgelände",
        "answer": "området",
        "key": "64"
    },
    {
        "searchTerm": "enstehen",
        "answer": "uppstå",
        "key": "65"
    },
    {
        "searchTerm": "selbst",
        "answer": "inte ens",
        "key": "66"
    },
    {
        "searchTerm": "die Schlange",
        "answer": "kön",
        "key": "67"
    },
    {
        "searchTerm": "die Laune",
        "answer": "humöret",
        "key": "68"
    },
    {
        "searchTerm": "bekannt",
        "answer": "berömd",
        "key": "69"
    },
    {
        "searchTerm": "der Neuling",
        "answer": "nybörjaren",
        "key": "70"
    },
    {
        "searchTerm": "gleichzeitg",
        "answer": "samtidigt",
        "key": "71"
    },
    {
        "searchTerm": "auftreten",
        "answer": "uppträda",
        "key": "72"
    },
    {
        "searchTerm": "der Nachwuchsmusiker",
        "answer": "den nya generationens  musiker",
        "key": "73"
    },
    {
        "searchTerm": "sich bewerben",
        "answer": "ansöka",
        "key": "74"
    },
    {
        "searchTerm": "die Pferderennbahn",
        "answer": "hästkapplöpningsbanan",
        "key": "75"
    },
    {
        "searchTerm": "wird genutzt",
        "answer": "används",
        "key": "76"
    },
    {
        "searchTerm": "umgeben",
        "answer": "omgiven",
        "key": "77"
    },
    {
        "searchTerm": "die Lautstärke",
        "answer": "ljudstyrkan",
        "key": "78"
    },
    {
        "searchTerm": "ausgelassen",
        "answer": "uppsluppen",
        "key": "79"
    },
    {
        "searchTerm": "das Festival",
        "answer": "festivalen",
        "key": "80"
    },
    {
        "searchTerm": "die Größe",
        "answer": "storheten",
        "key": "81"
    },
    {
        "searchTerm": "bion",
        "answer": "das Kino",
        "key": "82"
    },
    {
        "searchTerm": "Frost",
        "answer": "die Eiskönigin",
        "key": "83"
    },
    {
        "searchTerm": "oavsiktligt",
        "answer": "unbeabsichtigt",
        "key": "84"
    },
    {
        "searchTerm": "uppfunnit",
        "answer": "erfunden",
        "key": "85"
    },
    {
        "searchTerm": "utför",
        "answer": "hinunter",
        "key": "86"
    },
    {
        "searchTerm": "försätta",
        "answer": "versetzen",
        "key": "87"
    },
    {
        "searchTerm": "kungariket",
        "answer": "das Königreich",
        "key": "88"
    },
    {
        "searchTerm": "bemästra",
        "answer": "meistern",
        "key": "89"
    },
    {
        "searchTerm": "Hungerspelen",
        "answer": "die Tribute von Panem",
        "key": "90"
    },
    {
        "searchTerm": "utspela sig",
        "answer": "spielen",
        "key": "91"
    },
    {
        "searchTerm": "kuva",
        "answer": "unterdrücken",
        "key": "92"
    },
    {
        "searchTerm": "välja",
        "answer": "wählen",
        "key": "93"
    },
    {
        "searchTerm": "delta i tävlingarna",
        "answer": "an den Kampfspielen teilnehmen",
        "key": "94"
    },
    {
        "searchTerm": "döden",
        "answer": "der Tod",
        "key": "95"
    },
    {
        "searchTerm": "huvudpersonen",
        "answer": "die Protagonistin",
        "key": "96"
    },
    {
        "searchTerm": "befrielsen",
        "answer": "die Befreiung",
        "key": "97"
    },
    {
        "searchTerm": "samtala",
        "answer": "besprechen",
        "key": "98"
    },
    {
        "searchTerm": "scenen",
        "answer": "die Szene",
        "key": "99"
    },
    {
        "searchTerm": "angripen",
        "answer": "musikälskaren",
        "key": "100"
    },
    {
        "searchTerm": "utantill",
        "answer": "auswendig",
        "key": "101"
    },
    {
        "searchTerm": "jag tycker om",
        "answer": "es gefällt mir",
        "key": "102"
    },
    {
        "searchTerm": "älsklingslåten",
        "answer": "das Lieblingslied",
        "key": "103"
    },
    {
        "searchTerm": "olik",
        "answer": "verschieden",
        "key": "104"
    },
    {
        "searchTerm": "enkel",
        "answer": "eingängig",
        "key": "105"
    },
    {
        "searchTerm": "konsten",
        "answer": "die Kunst",
        "key": "106"
    },
    {
        "searchTerm": "flykten",
        "answer": "der Flug",
        "key": "107"
    },
    {
        "searchTerm": "pianolektionen",
        "answer": "der Klavierunterricht",
        "key": "108"
    },
    {
        "searchTerm": "njuta av",
        "answer": "genießen",
        "key": "109"
    },
    {
        "searchTerm": "kompositören",
        "answer": "der Komponist",
        "key": "110"
    },
    {
        "searchTerm": "likgiltigt",
        "answer": "egal",
        "key": "111"
    },
    {
        "searchTerm": "bildandet av ett band",
        "answer": "die Bandgründung",
        "key": "112"
    },
    {
        "searchTerm": "sången",
        "answer": "der Gesang",
        "key": "113"
    },
    {
        "searchTerm": "medlemmen",
        "answer": "das Mitglied",
        "key": "114"
    },
    {
        "searchTerm": "topplistan",
        "answer": "die Chart",
        "key": "115"
    },
    {
        "searchTerm": "provspelningen",
        "answer": "das Bandcasting",
        "key": "116"
    },
    {
        "searchTerm": "replokalen",
        "answer": "der Proberaum",
        "key": "117"
    },
    {
        "searchTerm": "utrustat",
        "answer": "ausgestattet",
        "key": "118"
    },
    {
        "searchTerm": "slagverket",
        "answer": "das Schlagzeug",
        "key": "119"
    },
    {
        "searchTerm": "cellon",
        "answer": "das Cello",
        "key": "120"
    },
    {
        "searchTerm": "spela upp",
        "answer": "vorspielen",
        "key": "121"
    },
    {
        "searchTerm": "häftigt",
        "answer": "geil",
        "key": "122"
    },
    {
        "searchTerm": "tvunget",
        "answer": "unbedingt",
        "key": "123"
    },
    {
        "searchTerm": "under alla förhållandevarje",
        "answer": "auf jeden Fall",
        "key": "124"
    },
    {
        "searchTerm": "anse",
        "answer": "meinen",
        "key": "125"
    },
    {
        "searchTerm": "otrolig",
        "answer": "fabelhaft",
        "key": "126"
    },
    {
        "searchTerm": "prova",
        "answer": "proben",
        "key": "127"
    },
    {
        "searchTerm": "musikfestivalen",
        "answer": "das Musikfestival",
        "key": "128"
    },
    {
        "searchTerm": "konserten",
        "answer": "das Konzert",
        "key": "129"
    },
    {
        "searchTerm": "musikälskaren",
        "answer": "der Musikliebhaber",
        "key": "130"
    },
    {
        "searchTerm": "smaken",
        "answer": "der Geschmack",
        "key": "131"
    },
    {
        "searchTerm": "gratis",
        "answer": "kostenlos",
        "key": "132"
    },
    {
        "searchTerm": "arrangerat",
        "answer": "veranstaltet",
        "key": "133"
    },
    {
        "searchTerm": "historian",
        "answer": "die Geschichte",
        "key": "134"
    },
    {
        "searchTerm": "entrén",
        "answer": "der Auftritt",
        "key": "135"
    },
    {
        "searchTerm": "åskan",
        "answer": "das Gewitter",
        "key": "136"
    },
    {
        "searchTerm": "scenen",
        "answer": "die Bühne",
        "key": "137"
    },
    {
        "searchTerm": "översvämmad",
        "answer": "überschwemmt  ",
        "key": "138"
    },
    {
        "searchTerm": "upphörde att fungera",
        "answer": "ausfielen",
        "key": "139"
    },
    {
        "searchTerm": "mest betydelsefulla",
        "answer": "bedeutend",
        "key": "140"
    },
    {
        "searchTerm": "omkostnaderna",
        "answer": "die Kosten",
        "key": "141"
    },
    {
        "searchTerm": "bevisa",
        "answer": "unter Beweis stellen",
        "key": "142"
    },
    {
        "searchTerm": "stjärnan",
        "answer": "der Star",
        "key": "143"
    },
    {
        "searchTerm": "fältet",
        "answer": "das Feld",
        "key": "144"
    },
    {
        "searchTerm": "området",
        "answer": "das Festivalgelände",
        "key": "145"
    },
    {
        "searchTerm": "uppstå",
        "answer": "enstehen",
        "key": "146"
    },
    {
        "searchTerm": "inte ens",
        "answer": "selbst",
        "key": "147"
    },
    {
        "searchTerm": "kön",
        "answer": "die Schlange",
        "key": "148"
    },
    {
        "searchTerm": "humöret",
        "answer": "die Laune",
        "key": "149"
    },
    {
        "searchTerm": "berömd",
        "answer": "bekannt",
        "key": "150"
    },
    {
        "searchTerm": "nybörjaren",
        "answer": "der Neuling",
        "key": "151"
    },
    {
        "searchTerm": "samtidigt",
        "answer": "gleichzeitg",
        "key": "152"
    },
    {
        "searchTerm": "uppträda",
        "answer": "auftreten",
        "key": "153"
    },
    {
        "searchTerm": "den nya generationens  musiker",
        "answer": "der Nachwuchsmusiker",
        "key": "154"
    },
    {
        "searchTerm": "ansöka",
        "answer": "sich bewerben",
        "key": "155"
    },
    {
        "searchTerm": "hästkapplöpningsbanan",
        "answer": "die Pferderennbahn",
        "key": "156"
    },
    {
        "searchTerm": "används",
        "answer": "wird genutzt",
        "key": "157"
    },
    {
        "searchTerm": "omgiven",
        "answer": "umgeben",
        "key": "158"
    },
    {
        "searchTerm": "ljudstyrkan",
        "answer": "die Lautstärke",
        "key": "159"
    },
    {
        "searchTerm": "uppsluppen",
        "answer": "ausgelassen",
        "key": "160"
    },
    {
        "searchTerm": "festivalen",
        "answer": "das Festival",
        "key": "161"
    },
    {
        "searchTerm": "storheten",
        "answer": "die Größe",
        "key": "162"
    }
]

export default kapitel86;