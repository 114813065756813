const kapitel96 = [
    {
        "searchTerm": "der Profi",
        "answer": "proffset",
        "key": "1"
    },
    {
        "searchTerm": "der Traum",
        "answer": "drömmen",
        "key": "2"
    },
    {
        "searchTerm": "träumen",
        "answer": "drömma",
        "key": "3"
    },
    {
        "searchTerm": "verdienen",
        "answer": "tjäna",
        "key": "4"
    },
    {
        "searchTerm": "herumreisen",
        "answer": "resa omkring",
        "key": "5"
    },
    {
        "searchTerm": "später",
        "answer": "senare",
        "key": "6"
    },
    {
        "searchTerm": "jahrelang",
        "answer": "i åratal",
        "key": "7"
    },
    {
        "searchTerm": "verbinden",
        "answer": "koppla ihop",
        "key": "8"
    },
    {
        "searchTerm": "das Talent",
        "answer": "talangen",
        "key": "9"
    },
    {
        "searchTerm": "der Arzt",
        "answer": "läkaren",
        "key": "10"
    },
    {
        "searchTerm": "das Dorf",
        "answer": "byn",
        "key": "11"
    },
    {
        "searchTerm": "die Schauspielerin",
        "answer": "skådespelaren (kvinnlig)",
        "key": "12"
    },
    {
        "searchTerm": "in Erfüllung gehen",
        "answer": "gå i uppfyllelse",
        "key": "13"
    },
    {
        "searchTerm": "bestehen",
        "answer": "klara av",
        "key": "14"
    },
    {
        "searchTerm": "das Austauschjahr",
        "answer": "utbytesåret",
        "key": "15"
    },
    {
        "searchTerm": "der Beruf",
        "answer": "yrket",
        "key": "16"
    },
    {
        "searchTerm": "der Erfolg",
        "answer": "framgången",
        "key": "17"
    },
    {
        "searchTerm": "der Zukunftsgedanke",
        "answer": "framtidstanken",
        "key": "18"
    },
    {
        "searchTerm": "die Ansicht",
        "answer": "åsikten",
        "key": "19"
    },
    {
        "searchTerm": "die Zukunft",
        "answer": "framtiden",
        "key": "20"
    },
    {
        "searchTerm": "gerade jetzt",
        "answer": "just nu",
        "key": "21"
    },
    {
        "searchTerm": "Wenn es denen gut geht",
        "answer": "när de mår bra",
        "key": "22"
    },
    {
        "searchTerm": "ähnlich",
        "answer": "på samma sätt",
        "key": "23"
    },
    {
        "searchTerm": "vor allem",
        "answer": "framförallt",
        "key": "24"
    },
    {
        "searchTerm": "die Streitigkeit",
        "answer": "stridigheten",
        "key": "25"
    },
    {
        "searchTerm": "wir verstehen uns",
        "answer": "vi kommer överens",
        "key": "26"
    },
    {
        "searchTerm": "Das würde mir Spaß machen.",
        "answer": "Det skulle vara kul.",
        "key": "27"
    },
    {
        "searchTerm": "begegnen",
        "answer": "möta",
        "key": "28"
    },
    {
        "searchTerm": "die Surfbedingung",
        "answer": "surfförhållandet",
        "key": "29"
    },
    {
        "searchTerm": "die Schriftstellerin",
        "answer": "författaren (kvinnlig)",
        "key": "30"
    },
    {
        "searchTerm": "irgendwas",
        "answer": "någonting",
        "key": "31"
    },
    {
        "searchTerm": "der Arbeitsmarkt",
        "answer": "arbetsmarknaden",
        "key": "32"
    },
    {
        "searchTerm": "andererseits",
        "answer": "å andra sidan",
        "key": "33"
    },
    {
        "searchTerm": "sich entscheiden",
        "answer": "bestämma sig",
        "key": "34"
    },
    {
        "searchTerm": "aufwachsen",
        "answer": "växa upp",
        "key": "35"
    },
    {
        "searchTerm": "wegziehen",
        "answer": "flytta iväg",
        "key": "36"
    },
    {
        "searchTerm": "ruhig",
        "answer": "lugnt",
        "key": "37"
    },
    {
        "searchTerm": "allgemein",
        "answer": "allmänt",
        "key": "38"
    },
    {
        "searchTerm": "das Umweltprojekt",
        "answer": "miljöprojektet",
        "key": "39"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "återvinningen",
        "key": "40"
    },
    {
        "searchTerm": "der Mülleimer",
        "answer": "sophinken",
        "key": "41"
    },
    {
        "searchTerm": "das Informationsschild",
        "answer": "informationsskylten",
        "key": "42"
    },
    {
        "searchTerm": "umweltbewusst",
        "answer": "miljömedveten",
        "key": "43"
    },
    {
        "searchTerm": "deswegen",
        "answer": "därför",
        "key": "44"
    },
    {
        "searchTerm": "beispielsweise",
        "answer": "till exempel",
        "key": "45"
    },
    {
        "searchTerm": "die Weise",
        "answer": "viset",
        "key": "46"
    },
    {
        "searchTerm": "überwiegend",
        "answer": "övervägande",
        "key": "47"
    },
    {
        "searchTerm": "zufrieden",
        "answer": "nöjd",
        "key": "48"
    },
    {
        "searchTerm": "ich freue mich auf",
        "answer": "jag ser fram emot",
        "key": "49"
    },
    {
        "searchTerm": "widersprechen",
        "answer": "säga emot",
        "key": "50"
    },
    {
        "searchTerm": "scheinbar",
        "answer": "till synes",
        "key": "51"
    },
    {
        "searchTerm": "immer schlimmer",
        "answer": "allt sämre",
        "key": "52"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "klimatförändringarna",
        "key": "53"
    },
    {
        "searchTerm": "die Massentierhaltung",
        "answer": "massdjurhållningen",
        "key": "54"
    },
    {
        "searchTerm": "die Rodung des Regenwaldes",
        "answer": "skövlingen av regnskogen",
        "key": "55"
    },
    {
        "searchTerm": "die Wahrheit",
        "answer": "sanningen",
        "key": "56"
    },
    {
        "searchTerm": "ich mache mir Sorgen",
        "answer": "jag oroar mig",
        "key": "57"
    },
    {
        "searchTerm": "passieren",
        "answer": "hända",
        "key": "58"
    },
    {
        "searchTerm": "weitermachen",
        "answer": "fortsätta",
        "key": "59"
    },
    {
        "searchTerm": "in einem größeren Rahmen",
        "answer": "här",
        "key": "60"
    },
    {
        "searchTerm": "nimmt teil",
        "answer": "deltar",
        "key": "61"
    },
    {
        "searchTerm": "sich beschäftigen",
        "answer": "sysselsätta sig",
        "key": "62"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "strömmen",
        "key": "63"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "soporna",
        "key": "64"
    },
    {
        "searchTerm": "mitmachen",
        "answer": "delta",
        "key": "65"
    },
    {
        "searchTerm": "mittendrin",
        "answer": "mitt i",
        "key": "66"
    },
    {
        "searchTerm": "umweltfreundlich",
        "answer": "miljövänlig",
        "key": "67"
    },
    {
        "searchTerm": "die Wind- und Wasserkraft",
        "answer": "vind-och vattenkraften",
        "key": "68"
    },
    {
        "searchTerm": "alltäglich",
        "answer": "vardaglig",
        "key": "69"
    },
    {
        "searchTerm": "ausschalten",
        "answer": "stänga av",
        "key": "70"
    },
    {
        "searchTerm": "der Unterschied",
        "answer": "skillnaden",
        "key": "71"
    },
    {
        "searchTerm": "die Recyclingtonne",
        "answer": "återvinningstunnan",
        "key": "72"
    },
    {
        "searchTerm": "der Tauschtag",
        "answer": "bytesdagen",
        "key": "73"
    },
    {
        "searchTerm": "das Gerät",
        "answer": "apparaten",
        "key": "74"
    },
    {
        "searchTerm": "tauschen",
        "answer": "byta",
        "key": "75"
    },
    {
        "searchTerm": "öfter",
        "answer": "oftare",
        "key": "76"
    },
    {
        "searchTerm": "wegschmeißen",
        "answer": "kasta bort",
        "key": "77"
    },
    {
        "searchTerm": "die Ernährung",
        "answer": "födan",
        "key": "78"
    },
    {
        "searchTerm": "der Einfluss",
        "answer": "inflytandet",
        "key": "79"
    },
    {
        "searchTerm": "bewusster",
        "answer": "mer medvetet",
        "key": "80"
    },
    {
        "searchTerm": "weniger",
        "answer": "mindre",
        "key": "81"
    },
    {
        "searchTerm": "die Umgebung-en",
        "answer": "omgivningen",
        "key": "82"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "miljöskyddet",
        "key": "83"
    },
    {
        "searchTerm": "das Gewächshaus",
        "answer": "växthuset",
        "key": "84"
    },
    {
        "searchTerm": "die Ernte",
        "answer": "skörden",
        "key": "85"
    },
    {
        "searchTerm": "proffset",
        "answer": "der Profi",
        "key": "86"
    },
    {
        "searchTerm": "drömmen",
        "answer": "der Traum",
        "key": "87"
    },
    {
        "searchTerm": "drömma",
        "answer": "träumen",
        "key": "88"
    },
    {
        "searchTerm": "tjäna",
        "answer": "verdienen",
        "key": "89"
    },
    {
        "searchTerm": "resa omkring",
        "answer": "herumreisen",
        "key": "90"
    },
    {
        "searchTerm": "senare",
        "answer": "später",
        "key": "91"
    },
    {
        "searchTerm": "i åratal",
        "answer": "jahrelang",
        "key": "92"
    },
    {
        "searchTerm": "koppla ihop",
        "answer": "verbinden",
        "key": "93"
    },
    {
        "searchTerm": "talangen",
        "answer": "das Talent",
        "key": "94"
    },
    {
        "searchTerm": "läkaren",
        "answer": "der Arzt",
        "key": "95"
    },
    {
        "searchTerm": "byn",
        "answer": "das Dorf",
        "key": "96"
    },
    {
        "searchTerm": "skådespelaren (kvinnlig)",
        "answer": "die Schauspielerin",
        "key": "97"
    },
    {
        "searchTerm": "gå i uppfyllelse",
        "answer": "in Erfüllung gehen",
        "key": "98"
    },
    {
        "searchTerm": "klara av",
        "answer": "bestehen",
        "key": "99"
    },
    {
        "searchTerm": "utbytesåret",
        "answer": "das Austauschjahr",
        "key": "100"
    },
    {
        "searchTerm": "yrket",
        "answer": "der Beruf",
        "key": "101"
    },
    {
        "searchTerm": "framgången",
        "answer": "der Erfolg",
        "key": "102"
    },
    {
        "searchTerm": "framtidstanken",
        "answer": "der Zukunftsgedanke",
        "key": "103"
    },
    {
        "searchTerm": "åsikten",
        "answer": "die Ansicht",
        "key": "104"
    },
    {
        "searchTerm": "framtiden",
        "answer": "die Zukunft",
        "key": "105"
    },
    {
        "searchTerm": "just nu",
        "answer": "gerade jetzt",
        "key": "106"
    },
    {
        "searchTerm": "när de mår bra",
        "answer": "Wenn es denen gut geht",
        "key": "107"
    },
    {
        "searchTerm": "på samma sätt",
        "answer": "ähnlich",
        "key": "108"
    },
    {
        "searchTerm": "framförallt",
        "answer": "vor allem",
        "key": "109"
    },
    {
        "searchTerm": "stridigheten",
        "answer": "die Streitigkeit",
        "key": "110"
    },
    {
        "searchTerm": "vi kommer överens",
        "answer": "wir verstehen uns",
        "key": "111"
    },
    {
        "searchTerm": "Det skulle vara kul.",
        "answer": "Das würde mir Spaß machen.",
        "key": "112"
    },
    {
        "searchTerm": "möta",
        "answer": "begegnen",
        "key": "113"
    },
    {
        "searchTerm": "surfförhållandet",
        "answer": "die Surfbedingung",
        "key": "114"
    },
    {
        "searchTerm": "författaren (kvinnlig)",
        "answer": "die Schriftstellerin",
        "key": "115"
    },
    {
        "searchTerm": "någonting",
        "answer": "irgendwas",
        "key": "116"
    },
    {
        "searchTerm": "arbetsmarknaden",
        "answer": "der Arbeitsmarkt",
        "key": "117"
    },
    {
        "searchTerm": "å andra sidan",
        "answer": "andererseits",
        "key": "118"
    },
    {
        "searchTerm": "bestämma sig",
        "answer": "sich entscheiden",
        "key": "119"
    },
    {
        "searchTerm": "växa upp",
        "answer": "aufwachsen",
        "key": "120"
    },
    {
        "searchTerm": "flytta iväg",
        "answer": "wegziehen",
        "key": "121"
    },
    {
        "searchTerm": "lugnt",
        "answer": "ruhig",
        "key": "122"
    },
    {
        "searchTerm": "allmänt",
        "answer": "allgemein",
        "key": "123"
    },
    {
        "searchTerm": "miljöprojektet",
        "answer": "das Umweltprojekt",
        "key": "124"
    },
    {
        "searchTerm": "återvinningen",
        "answer": "Deutsch",
        "key": "125"
    },
    {
        "searchTerm": "sophinken",
        "answer": "der Mülleimer",
        "key": "126"
    },
    {
        "searchTerm": "informationsskylten",
        "answer": "das Informationsschild",
        "key": "127"
    },
    {
        "searchTerm": "miljömedveten",
        "answer": "umweltbewusst",
        "key": "128"
    },
    {
        "searchTerm": "därför",
        "answer": "deswegen",
        "key": "129"
    },
    {
        "searchTerm": "till exempel",
        "answer": "beispielsweise",
        "key": "130"
    },
    {
        "searchTerm": "viset",
        "answer": "die Weise",
        "key": "131"
    },
    {
        "searchTerm": "övervägande",
        "answer": "überwiegend",
        "key": "132"
    },
    {
        "searchTerm": "nöjd",
        "answer": "zufrieden",
        "key": "133"
    },
    {
        "searchTerm": "jag ser fram emot",
        "answer": "ich freue mich auf",
        "key": "134"
    },
    {
        "searchTerm": "säga emot",
        "answer": "widersprechen",
        "key": "135"
    },
    {
        "searchTerm": "till synes",
        "answer": "scheinbar",
        "key": "136"
    },
    {
        "searchTerm": "allt sämre",
        "answer": "immer schlimmer",
        "key": "137"
    },
    {
        "searchTerm": "klimatförändringarna",
        "answer": "Deutsch",
        "key": "138"
    },
    {
        "searchTerm": "massdjurhållningen",
        "answer": "die Massentierhaltung",
        "key": "139"
    },
    {
        "searchTerm": "skövlingen av regnskogen",
        "answer": "die Rodung des Regenwaldes",
        "key": "140"
    },
    {
        "searchTerm": "sanningen",
        "answer": "die Wahrheit",
        "key": "141"
    },
    {
        "searchTerm": "jag oroar mig",
        "answer": "ich mache mir Sorgen",
        "key": "142"
    },
    {
        "searchTerm": "hända",
        "answer": "passieren",
        "key": "143"
    },
    {
        "searchTerm": "fortsätta",
        "answer": "weitermachen",
        "key": "144"
    },
    {
        "searchTerm": "här",
        "answer": "in einem größeren Rahmen",
        "key": "145"
    },
    {
        "searchTerm": "deltar",
        "answer": "nimmt teil",
        "key": "146"
    },
    {
        "searchTerm": "sysselsätta sig",
        "answer": "sich beschäftigen",
        "key": "147"
    },
    {
        "searchTerm": "strömmen",
        "answer": "Deutsch",
        "key": "148"
    },
    {
        "searchTerm": "soporna",
        "answer": "Deutsch",
        "key": "149"
    },
    {
        "searchTerm": "delta",
        "answer": "mitmachen",
        "key": "150"
    },
    {
        "searchTerm": "mitt i",
        "answer": "mittendrin",
        "key": "151"
    },
    {
        "searchTerm": "miljövänlig",
        "answer": "umweltfreundlich",
        "key": "152"
    },
    {
        "searchTerm": "vind-och vattenkraften",
        "answer": "die Wind- und Wasserkraft",
        "key": "153"
    },
    {
        "searchTerm": "vardaglig",
        "answer": "alltäglich",
        "key": "154"
    },
    {
        "searchTerm": "stänga av",
        "answer": "ausschalten",
        "key": "155"
    },
    {
        "searchTerm": "skillnaden",
        "answer": "der Unterschied",
        "key": "156"
    },
    {
        "searchTerm": "återvinningstunnan",
        "answer": "die Recyclingtonne",
        "key": "157"
    },
    {
        "searchTerm": "bytesdagen",
        "answer": "der Tauschtag",
        "key": "158"
    },
    {
        "searchTerm": "apparaten",
        "answer": "das Gerät",
        "key": "159"
    },
    {
        "searchTerm": "byta",
        "answer": "tauschen",
        "key": "160"
    },
    {
        "searchTerm": "oftare",
        "answer": "öfter",
        "key": "161"
    },
    {
        "searchTerm": "kasta bort",
        "answer": "wegschmeißen",
        "key": "162"
    },
    {
        "searchTerm": "födan",
        "answer": "die Ernährung",
        "key": "163"
    },
    {
        "searchTerm": "inflytandet",
        "answer": "der Einfluss",
        "key": "164"
    },
    {
        "searchTerm": "mer medvetet",
        "answer": "bewusster",
        "key": "165"
    },
    {
        "searchTerm": "mindre",
        "answer": "weniger",
        "key": "166"
    },
    {
        "searchTerm": "omgivningen",
        "answer": "die Umgebung-en",
        "key": "167"
    },
    {
        "searchTerm": "miljöskyddet",
        "answer": "Deutsch",
        "key": "168"
    },
    {
        "searchTerm": "växthuset",
        "answer": "das Gewächshaus",
        "key": "169"
    },
    {
        "searchTerm": "skörden",
        "answer": "die Ernte",
        "key": "170"
    }
]

export default kapitel96;