const kapitel94 = [
    {
        "searchTerm": "die Lieblingsband",
        "answer": "favoritgruppen",
        "key": "1"
    },
    {
        "searchTerm": "abgeben",
        "answer": "lämna in",
        "key": "2"
    },
    {
        "searchTerm": "fast",
        "answer": "nästan",
        "key": "3"
    },
    {
        "searchTerm": "die Zeile",
        "answer": "raden",
        "key": "4"
    },
    {
        "searchTerm": "wenig",
        "answer": "lite",
        "key": "5"
    },
    {
        "searchTerm": "anfangen",
        "answer": "börja",
        "key": "6"
    },
    {
        "searchTerm": "sogar",
        "answer": "till och med",
        "key": "7"
    },
    {
        "searchTerm": "Alles klar!",
        "answer": "OK!",
        "key": "8"
    },
    {
        "searchTerm": "runter",
        "answer": "ner",
        "key": "9"
    },
    {
        "searchTerm": "holen",
        "answer": "hämta",
        "key": "10"
    },
    {
        "searchTerm": "eben",
        "answer": "just",
        "key": "11"
    },
    {
        "searchTerm": "später",
        "answer": "senare",
        "key": "12"
    },
    {
        "searchTerm": "Zeig mal!",
        "answer": "Visa då!",
        "key": "13"
    },
    {
        "searchTerm": "hochgeladen",
        "answer": "laddat upp",
        "key": "14"
    },
    {
        "searchTerm": "angucken",
        "answer": "se på",
        "key": "15"
    },
    {
        "searchTerm": "weniger",
        "answer": "mindre",
        "key": "16"
    },
    {
        "searchTerm": "die Beziehung",
        "answer": "förhållandet",
        "key": "17"
    },
    {
        "searchTerm": "verpassen",
        "answer": "missa",
        "key": "18"
    },
    {
        "searchTerm": "genervt",
        "answer": "irriterad",
        "key": "19"
    },
    {
        "searchTerm": "deswegen",
        "answer": "därför",
        "key": "20"
    },
    {
        "searchTerm": "sich unterhalten",
        "answer": "umgås",
        "key": "21"
    },
    {
        "searchTerm": "dauernd",
        "answer": "ständigt",
        "key": "22"
    },
    {
        "searchTerm": "kaum",
        "answer": "knappt",
        "key": "23"
    },
    {
        "searchTerm": "verschicken",
        "answer": "skicka iväg",
        "key": "24"
    },
    {
        "searchTerm": "der Messenger",
        "answer": "meddelandetjänsten",
        "key": "25"
    },
    {
        "searchTerm": "ausgeschlossen",
        "answer": "utanför",
        "key": "26"
    },
    {
        "searchTerm": "sich fühlen",
        "answer": "känna sig",
        "key": "27"
    },
    {
        "searchTerm": "ab und zu",
        "answer": "då och då",
        "key": "28"
    },
    {
        "searchTerm": "gelikt",
        "answer": "gillat",
        "key": "29"
    },
    {
        "searchTerm": "erfahren",
        "answer": "få veta",
        "key": "30"
    },
    {
        "searchTerm": "verboten",
        "answer": "förbjuden",
        "key": "31"
    },
    {
        "searchTerm": "benutzen",
        "answer": "använda",
        "key": "32"
    },
    {
        "searchTerm": "zugeben",
        "answer": "erkänna",
        "key": "33"
    },
    {
        "searchTerm": "ablenken",
        "answer": "avleda",
        "key": "34"
    },
    {
        "searchTerm": "schauen",
        "answer": "titta",
        "key": "35"
    },
    {
        "searchTerm": "ziemlich",
        "answer": "ganska",
        "key": "36"
    },
    {
        "searchTerm": "erreichbar",
        "answer": "nåbar",
        "key": "37"
    },
    {
        "searchTerm": "lautlos",
        "answer": "ljudlös",
        "key": "38"
    },
    {
        "searchTerm": "statt",
        "answer": "istället",
        "key": "39"
    },
    {
        "searchTerm": "das Fach",
        "answer": "ämnet",
        "key": "40"
    },
    {
        "searchTerm": "ansonsten",
        "answer": "annars",
        "key": "41"
    },
    {
        "searchTerm": "verbringen",
        "answer": "tillbringa",
        "key": "42"
    },
    {
        "searchTerm": "der Like",
        "answer": "gilla-markeringen",
        "key": "43"
    },
    {
        "searchTerm": "die Schattenseite",
        "answer": "skuggsidan",
        "key": "44"
    },
    {
        "searchTerm": "besonders",
        "answer": "särskilt",
        "key": "45"
    },
    {
        "searchTerm": "peinlich",
        "answer": "pinsam",
        "key": "46"
    },
    {
        "searchTerm": "beschimpfen",
        "answer": "förolämpa",
        "key": "47"
    },
    {
        "searchTerm": "bedrohen",
        "answer": "hota",
        "key": "48"
    },
    {
        "searchTerm": "der Täter",
        "answer": "gärningsmannen",
        "key": "49"
    },
    {
        "searchTerm": "angreifen",
        "answer": "angripa",
        "key": "50"
    },
    {
        "searchTerm": "verletzen",
        "answer": "skada",
        "key": "51"
    },
    {
        "searchTerm": "das Opfer",
        "answer": "offret",
        "key": "52"
    },
    {
        "searchTerm": "spicken",
        "answer": "fuska",
        "key": "53"
    },
    {
        "searchTerm": "der Begleiter",
        "answer": "följeslagaren",
        "key": "54"
    },
    {
        "searchTerm": "würde",
        "answer": "skulle",
        "key": "55"
    },
    {
        "searchTerm": "der Gedanke",
        "answer": "tanken",
        "key": "56"
    },
    {
        "searchTerm": "drehen",
        "answer": "vrida",
        "key": "57"
    },
    {
        "searchTerm": "hin und her",
        "answer": "hit och dit",
        "key": "58"
    },
    {
        "searchTerm": "einschlafen",
        "answer": "somna",
        "key": "59"
    },
    {
        "searchTerm": "offenbar",
        "answer": "tydligen",
        "key": "60"
    },
    {
        "searchTerm": "ich lasse es",
        "answer": "jag låter det vara",
        "key": "61"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "dumheten",
        "key": "62"
    },
    {
        "searchTerm": "morgen früh",
        "answer": "imorgon bitti",
        "key": "63"
    },
    {
        "searchTerm": "wiederholen",
        "answer": "upprepa",
        "key": "64"
    },
    {
        "searchTerm": "das Referat",
        "answer": "föredraget",
        "key": "65"
    },
    {
        "searchTerm": "der Weltkrieg",
        "answer": "världskriget",
        "key": "66"
    },
    {
        "searchTerm": "einreichen",
        "answer": "lämna in",
        "key": "67"
    },
    {
        "searchTerm": "das Spiel",
        "answer": "matchen",
        "key": "68"
    },
    {
        "searchTerm": "gewinnen",
        "answer": "vinna",
        "key": "69"
    },
    {
        "searchTerm": "planen",
        "answer": "planera",
        "key": "70"
    },
    {
        "searchTerm": "lecker",
        "answer": "läcker",
        "key": "71"
    },
    {
        "searchTerm": "versprochen",
        "answer": "lovat",
        "key": "72"
    },
    {
        "searchTerm": "abholen",
        "answer": "hämta",
        "key": "73"
    },
    {
        "searchTerm": "hinkriegen",
        "answer": "fixa",
        "key": "74"
    },
    {
        "searchTerm": "kein Wunder",
        "answer": "inte konstigt",
        "key": "75"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "ont i magen",
        "key": "76"
    },
    {
        "searchTerm": "überhaupt",
        "answer": "överhuvudtaget",
        "key": "77"
    },
    {
        "searchTerm": "favoritgruppen",
        "answer": "die Lieblingsband",
        "key": "78"
    },
    {
        "searchTerm": "lämna in",
        "answer": "abgeben",
        "key": "79"
    },
    {
        "searchTerm": "nästan",
        "answer": "fast",
        "key": "80"
    },
    {
        "searchTerm": "raden",
        "answer": "die Zeile",
        "key": "81"
    },
    {
        "searchTerm": "lite",
        "answer": "wenig",
        "key": "82"
    },
    {
        "searchTerm": "börja",
        "answer": "anfangen",
        "key": "83"
    },
    {
        "searchTerm": "till och med",
        "answer": "sogar",
        "key": "84"
    },
    {
        "searchTerm": "OK!",
        "answer": "Alles klar!",
        "key": "85"
    },
    {
        "searchTerm": "ner",
        "answer": "runter",
        "key": "86"
    },
    {
        "searchTerm": "hämta",
        "answer": "holen",
        "key": "87"
    },
    {
        "searchTerm": "just",
        "answer": "eben",
        "key": "88"
    },
    {
        "searchTerm": "senare",
        "answer": "später",
        "key": "89"
    },
    {
        "searchTerm": "Visa då!",
        "answer": "Zeig mal!",
        "key": "90"
    },
    {
        "searchTerm": "laddat upp",
        "answer": "hochgeladen",
        "key": "91"
    },
    {
        "searchTerm": "se på",
        "answer": "angucken",
        "key": "92"
    },
    {
        "searchTerm": "mindre",
        "answer": "weniger",
        "key": "93"
    },
    {
        "searchTerm": "förhållandet",
        "answer": "die Beziehung",
        "key": "94"
    },
    {
        "searchTerm": "missa",
        "answer": "verpassen",
        "key": "95"
    },
    {
        "searchTerm": "irriterad",
        "answer": "genervt",
        "key": "96"
    },
    {
        "searchTerm": "därför",
        "answer": "deswegen",
        "key": "97"
    },
    {
        "searchTerm": "umgås",
        "answer": "sich unterhalten",
        "key": "98"
    },
    {
        "searchTerm": "ständigt",
        "answer": "dauernd",
        "key": "99"
    },
    {
        "searchTerm": "knappt",
        "answer": "kaum",
        "key": "100"
    },
    {
        "searchTerm": "skicka iväg",
        "answer": "verschicken",
        "key": "101"
    },
    {
        "searchTerm": "meddelandetjänsten",
        "answer": "der Messenger",
        "key": "102"
    },
    {
        "searchTerm": "utanför",
        "answer": "ausgeschlossen",
        "key": "103"
    },
    {
        "searchTerm": "känna sig",
        "answer": "sich fühlen",
        "key": "104"
    },
    {
        "searchTerm": "då och då",
        "answer": "ab und zu",
        "key": "105"
    },
    {
        "searchTerm": "gillat",
        "answer": "gelikt",
        "key": "106"
    },
    {
        "searchTerm": "få veta",
        "answer": "erfahren",
        "key": "107"
    },
    {
        "searchTerm": "förbjuden",
        "answer": "verboten",
        "key": "108"
    },
    {
        "searchTerm": "använda",
        "answer": "benutzen",
        "key": "109"
    },
    {
        "searchTerm": "erkänna",
        "answer": "zugeben",
        "key": "110"
    },
    {
        "searchTerm": "avleda",
        "answer": "ablenken",
        "key": "111"
    },
    {
        "searchTerm": "titta",
        "answer": "schauen",
        "key": "112"
    },
    {
        "searchTerm": "ganska",
        "answer": "ziemlich",
        "key": "113"
    },
    {
        "searchTerm": "nåbar",
        "answer": "erreichbar",
        "key": "114"
    },
    {
        "searchTerm": "ljudlös",
        "answer": "lautlos",
        "key": "115"
    },
    {
        "searchTerm": "istället",
        "answer": "statt",
        "key": "116"
    },
    {
        "searchTerm": "ämnet",
        "answer": "das Fach",
        "key": "117"
    },
    {
        "searchTerm": "annars",
        "answer": "ansonsten",
        "key": "118"
    },
    {
        "searchTerm": "tillbringa",
        "answer": "verbringen",
        "key": "119"
    },
    {
        "searchTerm": "gilla-markeringen",
        "answer": "der Like",
        "key": "120"
    },
    {
        "searchTerm": "skuggsidan",
        "answer": "die Schattenseite",
        "key": "121"
    },
    {
        "searchTerm": "särskilt",
        "answer": "besonders",
        "key": "122"
    },
    {
        "searchTerm": "pinsam",
        "answer": "peinlich",
        "key": "123"
    },
    {
        "searchTerm": "förolämpa",
        "answer": "beschimpfen",
        "key": "124"
    },
    {
        "searchTerm": "hota",
        "answer": "bedrohen",
        "key": "125"
    },
    {
        "searchTerm": "gärningsmannen",
        "answer": "der Täter",
        "key": "126"
    },
    {
        "searchTerm": "angripa",
        "answer": "angreifen",
        "key": "127"
    },
    {
        "searchTerm": "skada",
        "answer": "verletzen",
        "key": "128"
    },
    {
        "searchTerm": "offret",
        "answer": "das Opfer",
        "key": "129"
    },
    {
        "searchTerm": "fuska",
        "answer": "spicken",
        "key": "130"
    },
    {
        "searchTerm": "följeslagaren",
        "answer": "der Begleiter",
        "key": "131"
    },
    {
        "searchTerm": "skulle",
        "answer": "würde",
        "key": "132"
    },
    {
        "searchTerm": "tanken",
        "answer": "der Gedanke",
        "key": "133"
    },
    {
        "searchTerm": "vrida",
        "answer": "drehen",
        "key": "134"
    },
    {
        "searchTerm": "hit och dit",
        "answer": "hin und her",
        "key": "135"
    },
    {
        "searchTerm": "somna",
        "answer": "einschlafen",
        "key": "136"
    },
    {
        "searchTerm": "tydligen",
        "answer": "offenbar",
        "key": "137"
    },
    {
        "searchTerm": "jag låter det vara",
        "answer": "ich lasse es",
        "key": "138"
    },
    {
        "searchTerm": "dumheten",
        "answer": "Deutsch",
        "key": "139"
    },
    {
        "searchTerm": "imorgon bitti",
        "answer": "morgen früh",
        "key": "140"
    },
    {
        "searchTerm": "upprepa",
        "answer": "wiederholen",
        "key": "141"
    },
    {
        "searchTerm": "föredraget",
        "answer": "das Referat",
        "key": "142"
    },
    {
        "searchTerm": "världskriget",
        "answer": "der Weltkrieg",
        "key": "143"
    },
    {
        "searchTerm": "lämna in",
        "answer": "einreichen",
        "key": "144"
    },
    {
        "searchTerm": "matchen",
        "answer": "das Spiel",
        "key": "145"
    },
    {
        "searchTerm": "vinna",
        "answer": "gewinnen",
        "key": "146"
    },
    {
        "searchTerm": "planera",
        "answer": "planen",
        "key": "147"
    },
    {
        "searchTerm": "läcker",
        "answer": "lecker",
        "key": "148"
    },
    {
        "searchTerm": "lovat",
        "answer": "versprochen",
        "key": "149"
    },
    {
        "searchTerm": "hämta",
        "answer": "abholen",
        "key": "150"
    },
    {
        "searchTerm": "fixa",
        "answer": "hinkriegen",
        "key": "151"
    },
    {
        "searchTerm": "inte konstigt",
        "answer": "kein Wunder",
        "key": "152"
    },
    {
        "searchTerm": "ont i magen",
        "answer": "Deutsch",
        "key": "153"
    },
    {
        "searchTerm": "överhuvudtaget",
        "answer": "überhaupt",
        "key": "154"
    }
]

export default kapitel94;