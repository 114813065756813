import React, { useEffect, useState } from 'react';
import './App.css';
import Quizbox from '../Quizbox/Quizbox'; 
import Progress from '../Progress/Progress'; 
import kapitel81 from '../kapitel81.js';
import kapitel82 from '../kapitel82.js';
import kapitel83 from '../kapitel83.js';
import kapitel84 from '../kapitel84.js';
import kapitel85 from '../kapitel85.js';
import kapitel86 from '../kapitel86.js';
import kapitel91 from '../kapitel91.js';
import kapitel92 from '../kapitel92.js';
import kapitel93 from '../kapitel93.js';
import kapitel94 from '../kapitel94.js';
import kapitel95 from '../kapitel95.js';
import kapitel96 from '../kapitel96.js';



function App() {

  const [searchObjects, setSearchObjects] = useState([]);

  const [actInd, setActInd] = useState(0);

  const [currentTerm, setCurrentTerm] = useState();

  const [toggle, setToggle] = useState('open');

  const [resultTracker, setResultTracker] = useState([]);



  const start = (chIdent) => {
    const startBtns = document.querySelectorAll(".startBtn");
    for (let j = 0; j < startBtns.length; j++) {
      startBtns[j].style.display = 'none';
    }

    const newOrder = [];
    for (let i = 0; i < 10; i++) {
      newOrder.splice(Math.floor(Math.random() * newOrder.length),0,chIdent[i])
    }
    setSearchObjects(newOrder);    

    document.getElementById("nextBtn").style.display = 'block';
    document.querySelector(".resultBox").style.display = 'none';
    document.querySelector(".progItemBox").style.display = 'flex';

    setResultTracker([]);
    setActInd(0);
  }

  function goNext() {
    if (actInd < searchObjects.length) {
      setActInd(actInd+1);
    }
    setCurrentTerm(searchObjects[actInd]);
    setToggle('open');
    if (actInd >= searchObjects.length) {
      document.getElementById("nextBtn").style.display = 'none';
      document.getElementById("reviewResults").style.display = 'block';
    }
  }

  function reviewResults() {
    document.querySelector(".resultBox").style.display = 'block';
    document.getElementById("reviewResults").style.display = 'none';
    document.querySelector(".progItemBox").style.display = 'none';
    const startBtns = document.querySelectorAll(".startBtn");
    for (let j = 0; j < startBtns.length; j++) {
      startBtns[j].style.display = 'block';
    }
  }

  return (
    <div className="App">
      <h1>Vokabeltrainer Jahrgangsstufe 9 Vittangi</h1>
      <div className='quizContainer'>
        {currentTerm ? <Quizbox key={currentTerm.key} currentTerm={currentTerm} toggle={toggle} setToggle={setToggle} resultTracker={resultTracker} setResultTracker={setResultTracker} /> : ''}
      </div>
      <button className='startBtn' onClick={() => {start(kapitel81)}}>Buch 8, Kapitel 1</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel82)}}>Buch 8, Kapitel 2</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel83)}}>Buch 8, Kapitel 3</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel84)}}>Buch 8, Kapitel 4</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel85)}}>Buch 8, Kapitel 5</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel86)}}>Buch 8, Kapitel 6</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel91)}}>Buch 9, Kapitel 1</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel92)}}>Buch 9, Kapitel 2</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel93)}}>Buch 9, Kapitel 3</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel94)}}>Buch 9, Kapitel 4</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel95)}}>Buch 9, Kapitel 5</button><br></br>
      <button className='startBtn' onClick={() => {start(kapitel96)}}>Buch 9, Kapitel 6</button>
      <button id='nextBtn' onClick={() => {goNext()}}>nächste Frage</button>
      <button id='reviewResults' onClick={() => {reviewResults()}}>Ergebnisse anzeigen</button>
      <Progress resultTracker={resultTracker} />
    </div>
  );
}

export default App;
