const kapitel81 = [
    {
        "searchTerm": "der Wanderurlaub",
        "answer": "vandringssemestern",
        "key": 1
    },
    {
        "searchTerm": "gehabt",
        "answer": "haft",
        "key": "2"
    },
    {
        "searchTerm": "geregnet",
        "answer": "regnat",
        "key": "3"
    },
    {
        "searchTerm": "sonst",
        "answer": "annars",
        "key": "4"
    },
    {
        "searchTerm": "nichts Besonderes",
        "answer": "inget särskilt",
        "key": "5"
    },
    {
        "searchTerm": "gemacht",
        "answer": "gjort",
        "key": "6"
    },
    {
        "searchTerm": "am Meer",
        "answer": "vid havet",
        "key": "7"
    },
    {
        "searchTerm": "gebadet",
        "answer": "badat",
        "key": "8"
    },
    {
        "searchTerm": "getroffen",
        "answer": "träffat",
        "key": "9"
    },
    {
        "searchTerm": "nur",
        "answer": "bara",
        "key": "10"
    },
    {
        "searchTerm": "gefaulenzt",
        "answer": "latat sig",
        "key": "11"
    },
    {
        "searchTerm": "die letzte Woche",
        "answer": "i förra veckan",
        "key": "12"
    },
    {
        "searchTerm": "gearbeitet",
        "answer": "arbetat",
        "key": "13"
    },
    {
        "searchTerm": "zu Hause",
        "answer": "hemma",
        "key": "14"
    },
    {
        "searchTerm": "Seid ihr nicht gefahren?  ",
        "answer": "Har ni inte åkt?",
        "key": "15"
    },
    {
        "searchTerm": "der Fahrradunfall",
        "answer": "cykelolyckan",
        "key": "16"
    },
    {
        "searchTerm": "vor",
        "answer": "före",
        "key": "17"
    },
    {
        "searchTerm": "Was ist passiert?  ",
        "answer": "Vad har hänt?",
        "key": "18"
    },
    {
        "searchTerm": "gebrochen",
        "answer": "hon har brutit",
        "key": "19"
    },
    {
        "searchTerm": "die Rippe",
        "answer": "revbenet",
        "key": "20"
    },
    {
        "searchTerm": "das Krankenhaus",
        "answer": "sjukhuset",
        "key": "21"
    },
    {
        "searchTerm": "statt",
        "answer": "istället för",
        "key": "22"
    },
    {
        "searchTerm": "es hat sich gelohnt  ",
        "answer": "det har lönat sig",
        "key": "23"
    },
    {
        "searchTerm": "euer",
        "answer": "er",
        "key": "24"
    },
    {
        "searchTerm": "noch",
        "answer": "ännu",
        "key": "25"
    },
    {
        "searchTerm": "vor zwei Wochen",
        "answer": "för två veckor sedan",
        "key": "26"
    },
    {
        "searchTerm": "wir sind eingezogen  ",
        "answer": "vi flyttade in",
        "key": "27"
    },
    {
        "searchTerm": "unbedingt",
        "answer": "absolut",
        "key": "28"
    },
    {
        "searchTerm": "hat bekommen",
        "answer": "har fått",
        "key": "29"
    },
    {
        "searchTerm": "die Schule fängt an  ",
        "answer": "skolan börjar",
        "key": "30"
    },
    {
        "searchTerm": "doch",
        "answer": "ju",
        "key": "31"
    },
    {
        "searchTerm": "gar nicht",
        "answer": "inte alls",
        "key": "32"
    },
    {
        "searchTerm": "gemacht",
        "answer": "gjort",
        "key": "33"
    },
    {
        "searchTerm": "geschrieben",
        "answer": "skrivit",
        "key": "34"
    },
    {
        "searchTerm": "ich bin gewesen",
        "answer": "jag har varit",
        "key": "35"
    },
    {
        "searchTerm": "mindestens",
        "answer": "minst",
        "key": "36"
    },
    {
        "searchTerm": "gelesen",
        "answer": "läst",
        "key": "37"
    },
    {
        "searchTerm": "die Leseratte",
        "answer": "läslusen",
        "key": "38"
    },
    {
        "searchTerm": "gezeltet",
        "answer": "tältat",
        "key": "39"
    },
    {
        "searchTerm": "in der Nähe von",
        "answer": "i närheten av",
        "key": "40"
    },
    {
        "searchTerm": "die Zeitschrift",
        "answer": "tidningen",
        "key": "41"
    },
    {
        "searchTerm": "der Campingkocher",
        "answer": "campingköket",
        "key": "42"
    },
    {
        "searchTerm": "reden",
        "answer": "prata",
        "key": "43"
    },
    {
        "searchTerm": "kichern",
        "answer": "fnittra",
        "key": "44"
    },
    {
        "searchTerm": "sie haben Sport gemacht",
        "answer": "de har utövat sport",
        "key": "45"
    },
    {
        "searchTerm": "unser",
        "answer": "vår/vårt",
        "key": "46"
    },
    {
        "searchTerm": "die Eissorte",
        "answer": "glassorten",
        "key": "47"
    },
    {
        "searchTerm": "die Eisdiele",
        "answer": "glassbaren",
        "key": "48"
    },
    {
        "searchTerm": "probiert",
        "answer": "provat",
        "key": "49"
    },
    {
        "searchTerm": "es hat geschmeckt  ",
        "answer": "det har smakat",
        "key": "50"
    },
    {
        "searchTerm": "die Kirsche",
        "answer": "körsbäret",
        "key": "51"
    },
    {
        "searchTerm": "das Schlumpfeneis",
        "answer": "smurfglassen",
        "key": "52"
    },
    {
        "searchTerm": "genäht",
        "answer": "sytt",
        "key": "53"
    },
    {
        "searchTerm": "versucht",
        "answer": "försökt",
        "key": "54"
    },
    {
        "searchTerm": "der Stoff",
        "answer": "tyget",
        "key": "55"
    },
    {
        "searchTerm": "gekauft",
        "answer": "köpt",
        "key": "56"
    },
    {
        "searchTerm": "irgendwas",
        "answer": "någonting",
        "key": "57"
    },
    {
        "searchTerm": "es hat funktioniert  ",
        "answer": "fungerat",
        "key": "58"
    },
    {
        "searchTerm": "noch lange nicht",
        "answer": "inte på länge än",
        "key": "59"
    },
    {
        "searchTerm": "gestalten",
        "answer": "forma",
        "key": "60"
    },
    {
        "searchTerm": "die Wand",
        "answer": "väggen",
        "key": "61"
    },
    {
        "searchTerm": "nach Hause",
        "answer": "hem",
        "key": "62"
    },
    {
        "searchTerm": "die Kunst",
        "answer": "konsten",
        "key": "63"
    },
    {
        "searchTerm": "die Dose",
        "answer": "burken",
        "key": "64"
    },
    {
        "searchTerm": "bei",
        "answer": "hos",
        "key": "65"
    },
    {
        "searchTerm": "der Buchstabe",
        "answer": "bokstaven",
        "key": "66"
    },
    {
        "searchTerm": "der Profi",
        "answer": "proffset",
        "key": "67"
    },
    {
        "searchTerm": "man darf",
        "answer": "man får lov",
        "key": "68"
    },
    {
        "searchTerm": "erklären",
        "answer": "förklara",
        "key": "69"
    },
    {
        "searchTerm": "kreativ werden",
        "answer": "bli kreativ",
        "key": "70"
    },
    {
        "searchTerm": "die Unterschrift",
        "answer": "underskriften",
        "key": "71"
    },
    {
        "searchTerm": "wählen",
        "answer": "välja",
        "key": "72"
    },
    {
        "searchTerm": "das Abschlussfest",
        "answer": "avslutningsfesten",
        "key": "73"
    },
    {
        "searchTerm": "der Ball",
        "answer": "bollen",
        "key": "74"
    },
    {
        "searchTerm": "hochschleudern",
        "answer": "kasta upp",
        "key": "75"
    },
    {
        "searchTerm": "auffangen",
        "answer": "fånga upp",
        "key": "76"
    },
    {
        "searchTerm": "er vergisst",
        "answer": "han glömmer",
        "key": "77"
    },
    {
        "searchTerm": "mutig",
        "answer": "modig",
        "key": "78"
    },
    {
        "searchTerm": "besonders",
        "answer": "speciellt",
        "key": "79"
    },
    {
        "searchTerm": "brauchen",
        "answer": "behöva",
        "key": "80"
    },
    {
        "searchTerm": "der Mut",
        "answer": "modet",
        "key": "81"
    },
    {
        "searchTerm": "die Körperspannung",
        "answer": "kroppsspänningen",
        "key": "82"
    },
    {
        "searchTerm": "geeignet",
        "answer": "lämpligt",
        "key": "83"
    },
    {
        "searchTerm": "der Höhepunkt",
        "answer": "höjdpunkten",
        "key": "84"
    },
    {
        "searchTerm": "der Tagesablauf",
        "answer": "dagsprogrammet",
        "key": "85"
    },
    {
        "searchTerm": "die Verpflegung",
        "answer": "mat och dryck",
        "key": "86"
    },
    {
        "searchTerm": "vandringssemestern",
        "answer": "der Wanderurlaub",
        "key": "87"
    },
    {
        "searchTerm": "haft",
        "answer": "gehabt",
        "key": "88"
    },
    {
        "searchTerm": "regnat",
        "answer": "geregnet",
        "key": "89"
    },
    {
        "searchTerm": "annars",
        "answer": "sonst",
        "key": "90"
    },
    {
        "searchTerm": "inget särskilt",
        "answer": "nichts Besonderes",
        "key": "91"
    },
    {
        "searchTerm": "gjort",
        "answer": "gemacht",
        "key": "92"
    },
    {
        "searchTerm": "vid havet",
        "answer": "am Meer",
        "key": "93"
    },
    {
        "searchTerm": "badat",
        "answer": "gebadet",
        "key": "94"
    },
    {
        "searchTerm": "träffat",
        "answer": "getroffen",
        "key": "95"
    },
    {
        "searchTerm": "bara",
        "answer": "nur",
        "key": "96"
    },
    {
        "searchTerm": "latat sig",
        "answer": "gefaulenzt",
        "key": "97"
    },
    {
        "searchTerm": "i förra veckan",
        "answer": "die letzte Woche",
        "key": "98"
    },
    {
        "searchTerm": "arbetat",
        "answer": "gearbeitet",
        "key": "99"
    },
    {
        "searchTerm": "hemma",
        "answer": "zu Hause",
        "key": "100"
    },
    {
        "searchTerm": "Har ni inte åkt?",
        "answer": "Seid ihr nicht gefahren?  ",
        "key": "101"
    },
    {
        "searchTerm": "cykelolyckan",
        "answer": "der Fahrradunfall",
        "key": "102"
    },
    {
        "searchTerm": "före",
        "answer": "vor",
        "key": "103"
    },
    {
        "searchTerm": "Vad har hänt?",
        "answer": "Was ist passiert?  ",
        "key": "104"
    },
    {
        "searchTerm": "hon har brutit",
        "answer": "gebrochen",
        "key": "105"
    },
    {
        "searchTerm": "revbenet",
        "answer": "die Rippe",
        "key": "106"
    },
    {
        "searchTerm": "sjukhuset",
        "answer": "das Krankenhaus",
        "key": "107"
    },
    {
        "searchTerm": "istället för",
        "answer": "statt",
        "key": "108"
    },
    {
        "searchTerm": "det har lönat sig",
        "answer": "es hat sich gelohnt  ",
        "key": "109"
    },
    {
        "searchTerm": "er",
        "answer": "euer",
        "key": "110"
    },
    {
        "searchTerm": "ännu",
        "answer": "noch",
        "key": "111"
    },
    {
        "searchTerm": "för två veckor sedan",
        "answer": "vor zwei Wochen",
        "key": "112"
    },
    {
        "searchTerm": "vi flyttade in",
        "answer": "wir sind eingezogen  ",
        "key": "113"
    },
    {
        "searchTerm": "absolut",
        "answer": "unbedingt",
        "key": "114"
    },
    {
        "searchTerm": "har fått",
        "answer": "hat bekommen",
        "key": "115"
    },
    {
        "searchTerm": "skolan börjar",
        "answer": "die Schule fängt an  ",
        "key": "116"
    },
    {
        "searchTerm": "ju",
        "answer": "doch",
        "key": "117"
    },
    {
        "searchTerm": "inte alls",
        "answer": "gar nicht",
        "key": "118"
    },
    {
        "searchTerm": "gjort",
        "answer": "gemacht",
        "key": "119"
    },
    {
        "searchTerm": "skrivit",
        "answer": "geschrieben",
        "key": "120"
    },
    {
        "searchTerm": "jag har varit",
        "answer": "ich bin gewesen",
        "key": "121"
    },
    {
        "searchTerm": "minst",
        "answer": "mindestens",
        "key": "122"
    },
    {
        "searchTerm": "läst",
        "answer": "gelesen",
        "key": "123"
    },
    {
        "searchTerm": "läslusen",
        "answer": "die Leseratte",
        "key": "124"
    },
    {
        "searchTerm": "tältat",
        "answer": "gezeltet",
        "key": "125"
    },
    {
        "searchTerm": "i närheten av",
        "answer": "in der Nähe von",
        "key": "126"
    },
    {
        "searchTerm": "tidningen",
        "answer": "die Zeitschrift",
        "key": "127"
    },
    {
        "searchTerm": "campingköket",
        "answer": "der Campingkocher",
        "key": "128"
    },
    {
        "searchTerm": "prata",
        "answer": "reden",
        "key": "129"
    },
    {
        "searchTerm": "fnittra",
        "answer": "kichern",
        "key": "130"
    },
    {
        "searchTerm": "de har utövat sport",
        "answer": "sie haben Sport gemacht",
        "key": "131"
    },
    {
        "searchTerm": "vår/vårt",
        "answer": "unser",
        "key": "132"
    },
    {
        "searchTerm": "glassorten",
        "answer": "die Eissorte",
        "key": "133"
    },
    {
        "searchTerm": "glassbaren",
        "answer": "die Eisdiele",
        "key": "134"
    },
    {
        "searchTerm": "provat",
        "answer": "probiert",
        "key": "135"
    },
    {
        "searchTerm": "det har smakat",
        "answer": "es hat geschmeckt  ",
        "key": "136"
    },
    {
        "searchTerm": "körsbäret",
        "answer": "die Kirsche",
        "key": "137"
    },
    {
        "searchTerm": "smurfglassen",
        "answer": "das Schlumpfeneis",
        "key": "138"
    },
    {
        "searchTerm": "sytt",
        "answer": "genäht",
        "key": "139"
    },
    {
        "searchTerm": "försökt",
        "answer": "versucht",
        "key": "140"
    },
    {
        "searchTerm": "tyget",
        "answer": "der Stoff",
        "key": "141"
    },
    {
        "searchTerm": "köpt",
        "answer": "gekauft",
        "key": "142"
    },
    {
        "searchTerm": "någonting",
        "answer": "irgendwas",
        "key": "143"
    },
    {
        "searchTerm": "fungerat",
        "answer": "es hat funktioniert  ",
        "key": "144"
    },
    {
        "searchTerm": "inte på länge än",
        "answer": "noch lange nicht",
        "key": "145"
    },
    {
        "searchTerm": "forma",
        "answer": "gestalten",
        "key": "146"
    },
    {
        "searchTerm": "väggen",
        "answer": "die Wand",
        "key": "147"
    },
    {
        "searchTerm": "hem",
        "answer": "nach Hause",
        "key": "148"
    },
    {
        "searchTerm": "konsten",
        "answer": "die Kunst",
        "key": "149"
    },
    {
        "searchTerm": "burken",
        "answer": "die Dose",
        "key": "150"
    },
    {
        "searchTerm": "hos",
        "answer": "bei",
        "key": "151"
    },
    {
        "searchTerm": "bokstaven",
        "answer": "der Buchstabe",
        "key": "152"
    },
    {
        "searchTerm": "proffset",
        "answer": "der Profi",
        "key": "153"
    },
    {
        "searchTerm": "man får lov",
        "answer": "man darf",
        "key": "154"
    },
    {
        "searchTerm": "förklara",
        "answer": "erklären",
        "key": "155"
    },
    {
        "searchTerm": "bli kreativ",
        "answer": "kreativ werden",
        "key": "156"
    },
    {
        "searchTerm": "underskriften",
        "answer": "die Unterschrift",
        "key": "157"
    },
    {
        "searchTerm": "välja",
        "answer": "wählen",
        "key": "158"
    },
    {
        "searchTerm": "avslutningsfesten",
        "answer": "das Abschlussfest",
        "key": "159"
    },
    {
        "searchTerm": "bollen",
        "answer": "der Ball",
        "key": "160"
    },
    {
        "searchTerm": "kasta upp",
        "answer": "hochschleudern",
        "key": "161"
    },
    {
        "searchTerm": "fånga upp",
        "answer": "auffangen",
        "key": "162"
    },
    {
        "searchTerm": "han glömmer",
        "answer": "er vergisst",
        "key": "163"
    },
    {
        "searchTerm": "modig",
        "answer": "mutig",
        "key": "164"
    },
    {
        "searchTerm": "speciellt",
        "answer": "besonders",
        "key": "165"
    },
    {
        "searchTerm": "behöva",
        "answer": "brauchen",
        "key": "166"
    },
    {
        "searchTerm": "modet",
        "answer": "der Mut",
        "key": "167"
    },
    {
        "searchTerm": "kroppsspänningen",
        "answer": "die Körperspannung",
        "key": "168"
    },
    {
        "searchTerm": "lämpligt",
        "answer": "geeignet",
        "key": "169"
    },
    {
        "searchTerm": "höjdpunkten",
        "answer": "der Höhepunkt",
        "key": "170"
    },
    {
        "searchTerm": "dagsprogrammet",
        "answer": "der Tagesablauf",
        "key": "171"
    },
    {
        "searchTerm": "mat och dryck",
        "answer": "die Verpflegung",
        "key": "172"
    }
]

export default kapitel81;