const kapitel91 = [
    {
        "searchTerm": "gerade",
        "answer": "just",
        "key": "1"
    },
    {
        "searchTerm": "draußen",
        "answer": "ute",
        "key": "2"
    },
    {
        "searchTerm": "lebhaft",
        "answer": "livlig",
        "key": "3"
    },
    {
        "searchTerm": "geplaudert",
        "answer": "snackat",
        "key": "4"
    },
    {
        "searchTerm": "zweiwöchig",
        "answer": "tvåveckors",
        "key": "5"
    },
    {
        "searchTerm": "der Zufall",
        "answer": "slumpen",
        "key": "6"
    },
    {
        "searchTerm": "noch",
        "answer": "fortfarande",
        "key": "7"
    },
    {
        "searchTerm": "außerdem",
        "answer": "dessutom",
        "key": "8"
    },
    {
        "searchTerm": "ein bisschen",
        "answer": "lite grann",
        "key": "9"
    },
    {
        "searchTerm": "blamiert",
        "answer": "gjort bort",
        "key": "10"
    },
    {
        "searchTerm": "zurechtgewiesen",
        "answer": "tillrättavisat",
        "key": "11"
    },
    {
        "searchTerm": "die Begrüßungsfeier",
        "answer": "välkomstfirandet",
        "key": "12"
    },
    {
        "searchTerm": "der Tagesablauf",
        "answer": "dagens förlopp",
        "key": "13"
    },
    {
        "searchTerm": "gefrühstückt",
        "answer": "ätit frukost",
        "key": "14"
    },
    {
        "searchTerm": "der Unterricht",
        "answer": "undervisningen",
        "key": "15"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "utflykten",
        "key": "16"
    },
    {
        "searchTerm": "zum Beispiel",
        "answer": "till exempel",
        "key": "17"
    },
    {
        "searchTerm": "verschieden",
        "answer": "olika",
        "key": "18"
    },
    {
        "searchTerm": "die Deutschkenntnisse",
        "answer": "tyskakunskaperna",
        "key": "19"
    },
    {
        "searchTerm": "wurden gezwungen",
        "answer": "tvingades",
        "key": "20"
    },
    {
        "searchTerm": "die Umgebung",
        "answer": "omgivningen",
        "key": "21"
    },
    {
        "searchTerm": "das Glockenspiel",
        "answer": "klockspelet",
        "key": "22"
    },
    {
        "searchTerm": "unheimlich",
        "answer": "otäck",
        "key": "23"
    },
    {
        "searchTerm": "nach Schärfe",
        "answer": "efter styrka",
        "key": "24"
    },
    {
        "searchTerm": "die Erinnerung",
        "answer": "minnet",
        "key": "25"
    },
    {
        "searchTerm": "stattgefunden",
        "answer": "ägt rum",
        "key": "26"
    },
    {
        "searchTerm": "der Stand",
        "answer": "ståndet",
        "key": "27"
    },
    {
        "searchTerm": "geknüpft",
        "answer": "knutit",
        "key": "28"
    },
    {
        "searchTerm": "der Wetterbericht",
        "answer": "väderleksrapporten",
        "key": "29"
    },
    {
        "searchTerm": "bewölkt",
        "answer": "mulet",
        "key": "30"
    },
    {
        "searchTerm": "trocken",
        "answer": "torrt",
        "key": "31"
    },
    {
        "searchTerm": "der Besucher",
        "answer": "besökaren",
        "key": "32"
    },
    {
        "searchTerm": "der Frosch",
        "answer": "grodan",
        "key": "33"
    },
    {
        "searchTerm": "hüpfen",
        "answer": "hoppa",
        "key": "34"
    },
    {
        "searchTerm": "hell",
        "answer": "ljus",
        "key": "35"
    },
    {
        "searchTerm": "der Hering",
        "answer": "sillen",
        "key": "36"
    },
    {
        "searchTerm": "wehen",
        "answer": "blåsa",
        "key": "37"
    },
    {
        "searchTerm": "allmählich",
        "answer": "efter hand",
        "key": "38"
    },
    {
        "searchTerm": "die Wolke",
        "answer": "molnet",
        "key": "39"
    },
    {
        "searchTerm": "sind aufgezogen",
        "answer": "dragit upp",
        "key": "40"
    },
    {
        "searchTerm": "es donnert",
        "answer": "det åskar",
        "key": "41"
    },
    {
        "searchTerm": "das Gewitter",
        "answer": "ovädret",
        "key": "42"
    },
    {
        "searchTerm": "es bricht los",
        "answer": "det bryter lös",
        "key": "43"
    },
    {
        "searchTerm": "befestigt",
        "answer": "fastsatt",
        "key": "44"
    },
    {
        "searchTerm": "der Wolkenbruch",
        "answer": "skyfallet",
        "key": "45"
    },
    {
        "searchTerm": "durchnässt",
        "answer": "genomblött",
        "key": "46"
    },
    {
        "searchTerm": "wasserdicht",
        "answer": "vattenfast",
        "key": "47"
    },
    {
        "searchTerm": "schöpfen",
        "answer": "skyffla",
        "key": "48"
    },
    {
        "searchTerm": "die Wiese",
        "answer": "ängen",
        "key": "49"
    },
    {
        "searchTerm": "der Nebel",
        "answer": "dimman",
        "key": "50"
    },
    {
        "searchTerm": "verrückt",
        "answer": "tokig",
        "key": "51"
    },
    {
        "searchTerm": "die Austauschschülerin",
        "answer": "utbytesstudenten (kvinnlig)",
        "key": "52"
    },
    {
        "searchTerm": "verbracht",
        "answer": "tillbringat",
        "key": "53"
    },
    {
        "searchTerm": "von Anfang an",
        "answer": "från början",
        "key": "54"
    },
    {
        "searchTerm": "am Meer",
        "answer": "vid havet",
        "key": "55"
    },
    {
        "searchTerm": "Verwandte",
        "answer": "släktingar",
        "key": "56"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "vardagen",
        "key": "57"
    },
    {
        "searchTerm": "ähnlich",
        "answer": "liknande",
        "key": "58"
    },
    {
        "searchTerm": "allerdings",
        "answer": "visserligen",
        "key": "59"
    },
    {
        "searchTerm": "der Unterschied",
        "answer": "olikheten",
        "key": "60"
    },
    {
        "searchTerm": "entdecken",
        "answer": "upptäcka",
        "key": "61"
    },
    {
        "searchTerm": "Brot mit Aufschnitt",
        "answer": "bröd med pålägg",
        "key": "62"
    },
    {
        "searchTerm": "sondern",
        "answer": "utan",
        "key": "63"
    },
    {
        "searchTerm": "stattdessen",
        "answer": "istället",
        "key": "64"
    },
    {
        "searchTerm": "unüblich",
        "answer": "ovanligt",
        "key": "65"
    },
    {
        "searchTerm": "die Tüte",
        "answer": "påsen",
        "key": "66"
    },
    {
        "searchTerm": "vermissen",
        "answer": "sakna",
        "key": "67"
    },
    {
        "searchTerm": "einander vertrauen",
        "answer": "ha förtroende för varandra",
        "key": "68"
    },
    {
        "searchTerm": "das Verhältnis",
        "answer": "förhållandet",
        "key": "69"
    },
    {
        "searchTerm": "reden",
        "answer": "tala",
        "key": "70"
    },
    {
        "searchTerm": "sogar",
        "answer": "till och med",
        "key": "71"
    },
    {
        "searchTerm": "duzen",
        "answer": "dua",
        "key": "72"
    },
    {
        "searchTerm": "der Flur",
        "answer": "hallen",
        "key": "73"
    },
    {
        "searchTerm": "unbedingt",
        "answer": "absolut",
        "key": "74"
    },
    {
        "searchTerm": "just",
        "answer": "gerade",
        "key": "75"
    },
    {
        "searchTerm": "ute",
        "answer": "draußen",
        "key": "76"
    },
    {
        "searchTerm": "livlig",
        "answer": "lebhaft",
        "key": "77"
    },
    {
        "searchTerm": "snackat",
        "answer": "geplaudert",
        "key": "78"
    },
    {
        "searchTerm": "tvåveckors",
        "answer": "zweiwöchig",
        "key": "79"
    },
    {
        "searchTerm": "slumpen",
        "answer": "der Zufall",
        "key": "80"
    },
    {
        "searchTerm": "fortfarande",
        "answer": "noch",
        "key": "81"
    },
    {
        "searchTerm": "dessutom",
        "answer": "außerdem",
        "key": "82"
    },
    {
        "searchTerm": "lite grann",
        "answer": "ein bisschen",
        "key": "83"
    },
    {
        "searchTerm": "gjort bort",
        "answer": "blamiert",
        "key": "84"
    },
    {
        "searchTerm": "tillrättavisat",
        "answer": "zurechtgewiesen",
        "key": "85"
    },
    {
        "searchTerm": "välkomstfirandet",
        "answer": "die Begrüßungsfeier",
        "key": "86"
    },
    {
        "searchTerm": "dagens förlopp",
        "answer": "der Tagesablauf",
        "key": "87"
    },
    {
        "searchTerm": "ätit frukost",
        "answer": "gefrühstückt",
        "key": "88"
    },
    {
        "searchTerm": "undervisningen",
        "answer": "der Unterricht",
        "key": "89"
    },
    {
        "searchTerm": "utflykten",
        "answer": "Deutsch",
        "key": "90"
    },
    {
        "searchTerm": "till exempel",
        "answer": "zum Beispiel",
        "key": "91"
    },
    {
        "searchTerm": "olika",
        "answer": "verschieden",
        "key": "92"
    },
    {
        "searchTerm": "tyskakunskaperna",
        "answer": "die Deutschkenntnisse",
        "key": "93"
    },
    {
        "searchTerm": "tvingades",
        "answer": "wurden gezwungen",
        "key": "94"
    },
    {
        "searchTerm": "omgivningen",
        "answer": "die Umgebung",
        "key": "95"
    },
    {
        "searchTerm": "klockspelet",
        "answer": "das Glockenspiel",
        "key": "96"
    },
    {
        "searchTerm": "otäck",
        "answer": "unheimlich",
        "key": "97"
    },
    {
        "searchTerm": "efter styrka",
        "answer": "nach Schärfe",
        "key": "98"
    },
    {
        "searchTerm": "minnet",
        "answer": "die Erinnerung",
        "key": "99"
    },
    {
        "searchTerm": "ägt rum",
        "answer": "stattgefunden",
        "key": "100"
    },
    {
        "searchTerm": "ståndet",
        "answer": "der Stand",
        "key": "101"
    },
    {
        "searchTerm": "knutit",
        "answer": "geknüpft",
        "key": "102"
    },
    {
        "searchTerm": "väderleksrapporten",
        "answer": "der Wetterbericht",
        "key": "103"
    },
    {
        "searchTerm": "mulet",
        "answer": "bewölkt",
        "key": "104"
    },
    {
        "searchTerm": "torrt",
        "answer": "trocken",
        "key": "105"
    },
    {
        "searchTerm": "besökaren",
        "answer": "der Besucher",
        "key": "106"
    },
    {
        "searchTerm": "grodan",
        "answer": "der Frosch",
        "key": "107"
    },
    {
        "searchTerm": "hoppa",
        "answer": "hüpfen",
        "key": "108"
    },
    {
        "searchTerm": "ljus",
        "answer": "hell",
        "key": "109"
    },
    {
        "searchTerm": "sillen",
        "answer": "der Hering",
        "key": "110"
    },
    {
        "searchTerm": "blåsa",
        "answer": "wehen",
        "key": "111"
    },
    {
        "searchTerm": "efter hand",
        "answer": "allmählich",
        "key": "112"
    },
    {
        "searchTerm": "molnet",
        "answer": "die Wolke",
        "key": "113"
    },
    {
        "searchTerm": "dragit upp",
        "answer": "sind aufgezogen",
        "key": "114"
    },
    {
        "searchTerm": "det åskar",
        "answer": "es donnert",
        "key": "115"
    },
    {
        "searchTerm": "ovädret",
        "answer": "das Gewitter",
        "key": "116"
    },
    {
        "searchTerm": "det bryter lös",
        "answer": "es bricht los",
        "key": "117"
    },
    {
        "searchTerm": "fastsatt",
        "answer": "befestigt",
        "key": "118"
    },
    {
        "searchTerm": "skyfallet",
        "answer": "der Wolkenbruch",
        "key": "119"
    },
    {
        "searchTerm": "genomblött",
        "answer": "durchnässt",
        "key": "120"
    },
    {
        "searchTerm": "vattenfast",
        "answer": "wasserdicht",
        "key": "121"
    },
    {
        "searchTerm": "skyffla",
        "answer": "schöpfen",
        "key": "122"
    },
    {
        "searchTerm": "ängen",
        "answer": "die Wiese",
        "key": "123"
    },
    {
        "searchTerm": "dimman",
        "answer": "der Nebel",
        "key": "124"
    },
    {
        "searchTerm": "tokig",
        "answer": "verrückt",
        "key": "125"
    },
    {
        "searchTerm": "utbytesstudenten (kvinnlig)",
        "answer": "die Austauschschülerin",
        "key": "126"
    },
    {
        "searchTerm": "tillbringat",
        "answer": "verbracht",
        "key": "127"
    },
    {
        "searchTerm": "från början",
        "answer": "von Anfang an",
        "key": "128"
    },
    {
        "searchTerm": "vid havet",
        "answer": "am Meer",
        "key": "129"
    },
    {
        "searchTerm": "släktingar",
        "answer": "Verwandte",
        "key": "130"
    },
    {
        "searchTerm": "vardagen",
        "answer": "Deutsch",
        "key": "131"
    },
    {
        "searchTerm": "liknande",
        "answer": "ähnlich",
        "key": "132"
    },
    {
        "searchTerm": "visserligen",
        "answer": "allerdings",
        "key": "133"
    },
    {
        "searchTerm": "olikheten",
        "answer": "der Unterschied",
        "key": "134"
    },
    {
        "searchTerm": "upptäcka",
        "answer": "entdecken",
        "key": "135"
    },
    {
        "searchTerm": "bröd med pålägg",
        "answer": "Brot mit Aufschnitt",
        "key": "136"
    },
    {
        "searchTerm": "utan",
        "answer": "sondern",
        "key": "137"
    },
    {
        "searchTerm": "istället",
        "answer": "stattdessen",
        "key": "138"
    },
    {
        "searchTerm": "ovanligt",
        "answer": "unüblich",
        "key": "139"
    },
    {
        "searchTerm": "påsen",
        "answer": "die Tüte",
        "key": "140"
    },
    {
        "searchTerm": "sakna",
        "answer": "vermissen",
        "key": "141"
    },
    {
        "searchTerm": "ha förtroende för varandra",
        "answer": "einander vertrauen",
        "key": "142"
    },
    {
        "searchTerm": "förhållandet",
        "answer": "das Verhältnis",
        "key": "143"
    },
    {
        "searchTerm": "tala",
        "answer": "reden",
        "key": "144"
    },
    {
        "searchTerm": "till och med",
        "answer": "sogar",
        "key": "145"
    },
    {
        "searchTerm": "dua",
        "answer": "duzen",
        "key": "146"
    },
    {
        "searchTerm": "hallen",
        "answer": "der Flur",
        "key": "147"
    },
    {
        "searchTerm": "absolut",
        "answer": "unbedingt",
        "key": "148"
    }
]

export default kapitel91;