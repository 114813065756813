const kapitel92 = [
    {
        "searchTerm": "der Wurstaufschnitt",
        "answer": "korvpålägget",
        "key": "1"
    },
    {
        "searchTerm": "das Nudelgericht",
        "answer": "pastarätten",
        "key": "2"
    },
    {
        "searchTerm": "entweder … oder",
        "answer": "antingen … eller",
        "key": "3"
    },
    {
        "searchTerm": "obwohl",
        "answer": "fastän",
        "key": "4"
    },
    {
        "searchTerm": "der Eintopf",
        "answer": "grytan",
        "key": "5"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "nötköttet",
        "key": "6"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "grönsakerna",
        "key": "7"
    },
    {
        "searchTerm": "der Pfifferling",
        "answer": "kantarellen",
        "key": "8"
    },
    {
        "searchTerm": "der Krümel",
        "answer": "smulan",
        "key": "9"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "miljön",
        "key": "10"
    },
    {
        "searchTerm": "die Sahnesoße",
        "answer": "gräddsåsen",
        "key": "11"
    },
    {
        "searchTerm": "die Kantine",
        "answer": "matsalen",
        "key": "12"
    },
    {
        "searchTerm": "täglich",
        "answer": "dagligen",
        "key": "13"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "dukningen",
        "key": "14"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "diskningen",
        "key": "15"
    },
    {
        "searchTerm": "wählen",
        "answer": "välja",
        "key": "16"
    },
    {
        "searchTerm": "das Hühnchen",
        "answer": "kycklingen",
        "key": "17"
    },
    {
        "searchTerm": "meistens",
        "answer": "för det mesta",
        "key": "18"
    },
    {
        "searchTerm": "zubereiten",
        "answer": "tillreda",
        "key": "19"
    },
    {
        "searchTerm": "kaum",
        "answer": "knappast",
        "key": "20"
    },
    {
        "searchTerm": "die Süßigkeiten",
        "answer": "godiset",
        "key": "21"
    },
    {
        "searchTerm": "das Mittelmeer",
        "answer": "Medelhavet",
        "key": "22"
    },
    {
        "searchTerm": "Köfte",
        "answer": "turkiska frikadeller",
        "key": "23"
    },
    {
        "searchTerm": "planen",
        "answer": "planera",
        "key": "24"
    },
    {
        "searchTerm": "vorschlagen",
        "answer": "föreslå",
        "key": "25"
    },
    {
        "searchTerm": "mitmachen",
        "answer": "delta",
        "key": "26"
    },
    {
        "searchTerm": "besprechen",
        "answer": "prata om",
        "key": "27"
    },
    {
        "searchTerm": "sich melden",
        "answer": "höra av sig",
        "key": "28"
    },
    {
        "searchTerm": "Bescheid sagen",
        "answer": "ge besked",
        "key": "29"
    },
    {
        "searchTerm": "zum Naschen",
        "answer": "att knapra på",
        "key": "30"
    },
    {
        "searchTerm": "die Bank",
        "answer": "bänken",
        "key": "31"
    },
    {
        "searchTerm": "mitbringen",
        "answer": "ta med",
        "key": "32"
    },
    {
        "searchTerm": "gedacht",
        "answer": "tänkt",
        "key": "33"
    },
    {
        "searchTerm": "das Getränk",
        "answer": "drycken",
        "key": "34"
    },
    {
        "searchTerm": "beim Tragen helfen",
        "answer": "hjälpa till att bära",
        "key": "35"
    },
    {
        "searchTerm": "geklärt",
        "answer": "klarat upp",
        "key": "36"
    },
    {
        "searchTerm": "die Tischtennisplatte",
        "answer": "bordtennisbordet",
        "key": "37"
    },
    {
        "searchTerm": "der Keller",
        "answer": "källaren",
        "key": "38"
    },
    {
        "searchTerm": "der Schläger",
        "answer": "racket",
        "key": "39"
    },
    {
        "searchTerm": "genug",
        "answer": "tillräckligt",
        "key": "40"
    },
    {
        "searchTerm": "lahm",
        "answer": "här",
        "key": "41"
    },
    {
        "searchTerm": "fällt uns ein",
        "answer": "kommer vi på",
        "key": "42"
    },
    {
        "searchTerm": "Zutaten",
        "answer": "ingredienser",
        "key": "43"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "sockret",
        "key": "44"
    },
    {
        "searchTerm": "der Zuckerrübensirup",
        "answer": "sirapen av sockerbetor",
        "key": "45"
    },
    {
        "searchTerm": "weich",
        "answer": "mjuk",
        "key": "46"
    },
    {
        "searchTerm": "das Tütchen",
        "answer": "den lilla påsen",
        "key": "47"
    },
    {
        "searchTerm": "die Schüssel",
        "answer": "skålen",
        "key": "48"
    },
    {
        "searchTerm": "vermischen",
        "answer": "blanda",
        "key": "49"
    },
    {
        "searchTerm": "verrühren",
        "answer": "röra ihop",
        "key": "50"
    },
    {
        "searchTerm": "die Teigmischung",
        "answer": "degblandningen",
        "key": "51"
    },
    {
        "searchTerm": "der Teig",
        "answer": "degen",
        "key": "52"
    },
    {
        "searchTerm": "der Kühlschrank",
        "answer": "kylskåpet",
        "key": "53"
    },
    {
        "searchTerm": "der Backofen",
        "answer": "ugnen",
        "key": "54"
    },
    {
        "searchTerm": "vorheizen",
        "answer": "förvärma",
        "key": "55"
    },
    {
        "searchTerm": "das Backblech",
        "answer": "bakplåten",
        "key": "56"
    },
    {
        "searchTerm": "die Schiene",
        "answer": "skenan",
        "key": "57"
    },
    {
        "searchTerm": "korvpålägget",
        "answer": "der Wurstaufschnitt",
        "key": "58"
    },
    {
        "searchTerm": "pastarätten",
        "answer": "das Nudelgericht",
        "key": "59"
    },
    {
        "searchTerm": "antingen … eller",
        "answer": "entweder … oder",
        "key": "60"
    },
    {
        "searchTerm": "fastän",
        "answer": "obwohl",
        "key": "61"
    },
    {
        "searchTerm": "grytan",
        "answer": "der Eintopf",
        "key": "62"
    },
    {
        "searchTerm": "nötköttet",
        "answer": "Deutsch",
        "key": "63"
    },
    {
        "searchTerm": "grönsakerna",
        "answer": "Deutsch",
        "key": "64"
    },
    {
        "searchTerm": "kantarellen",
        "answer": "der Pfifferling",
        "key": "65"
    },
    {
        "searchTerm": "smulan",
        "answer": "der Krümel",
        "key": "66"
    },
    {
        "searchTerm": "miljön",
        "answer": "Deutsch",
        "key": "67"
    },
    {
        "searchTerm": "gräddsåsen",
        "answer": "die Sahnesoße",
        "key": "68"
    },
    {
        "searchTerm": "matsalen",
        "answer": "die Kantine",
        "key": "69"
    },
    {
        "searchTerm": "dagligen",
        "answer": "täglich",
        "key": "70"
    },
    {
        "searchTerm": "dukningen",
        "answer": "Deutsch",
        "key": "71"
    },
    {
        "searchTerm": "diskningen",
        "answer": "Deutsch",
        "key": "72"
    },
    {
        "searchTerm": "välja",
        "answer": "wählen",
        "key": "73"
    },
    {
        "searchTerm": "kycklingen",
        "answer": "das Hühnchen",
        "key": "74"
    },
    {
        "searchTerm": "för det mesta",
        "answer": "meistens",
        "key": "75"
    },
    {
        "searchTerm": "tillreda",
        "answer": "zubereiten",
        "key": "76"
    },
    {
        "searchTerm": "knappast",
        "answer": "kaum",
        "key": "77"
    },
    {
        "searchTerm": "godiset",
        "answer": "die Süßigkeiten",
        "key": "78"
    },
    {
        "searchTerm": "Medelhavet",
        "answer": "das Mittelmeer",
        "key": "79"
    },
    {
        "searchTerm": "turkiska frikadeller",
        "answer": "Köfte",
        "key": "80"
    },
    {
        "searchTerm": "planera",
        "answer": "planen",
        "key": "81"
    },
    {
        "searchTerm": "föreslå",
        "answer": "vorschlagen",
        "key": "82"
    },
    {
        "searchTerm": "delta",
        "answer": "mitmachen",
        "key": "83"
    },
    {
        "searchTerm": "prata om",
        "answer": "besprechen",
        "key": "84"
    },
    {
        "searchTerm": "höra av sig",
        "answer": "sich melden",
        "key": "85"
    },
    {
        "searchTerm": "ge besked",
        "answer": "Bescheid sagen",
        "key": "86"
    },
    {
        "searchTerm": "att knapra på",
        "answer": "zum Naschen",
        "key": "87"
    },
    {
        "searchTerm": "bänken",
        "answer": "die Bank",
        "key": "88"
    },
    {
        "searchTerm": "ta med",
        "answer": "mitbringen",
        "key": "89"
    },
    {
        "searchTerm": "tänkt",
        "answer": "gedacht",
        "key": "90"
    },
    {
        "searchTerm": "drycken",
        "answer": "das Getränk",
        "key": "91"
    },
    {
        "searchTerm": "hjälpa till att bära",
        "answer": "beim Tragen helfen",
        "key": "92"
    },
    {
        "searchTerm": "klarat upp",
        "answer": "geklärt",
        "key": "93"
    },
    {
        "searchTerm": "bordtennisbordet",
        "answer": "die Tischtennisplatte",
        "key": "94"
    },
    {
        "searchTerm": "källaren",
        "answer": "der Keller",
        "key": "95"
    },
    {
        "searchTerm": "racket",
        "answer": "der Schläger",
        "key": "96"
    },
    {
        "searchTerm": "tillräckligt",
        "answer": "genug",
        "key": "97"
    },
    {
        "searchTerm": "här",
        "answer": "lahm",
        "key": "98"
    },
    {
        "searchTerm": "kommer vi på",
        "answer": "fällt uns ein",
        "key": "99"
    },
    {
        "searchTerm": "ingredienser",
        "answer": "Zutaten",
        "key": "100"
    },
    {
        "searchTerm": "sockret",
        "answer": "Deutsch",
        "key": "101"
    },
    {
        "searchTerm": "sirapen av sockerbetor",
        "answer": "der Zuckerrübensirup",
        "key": "102"
    },
    {
        "searchTerm": "mjuk",
        "answer": "weich",
        "key": "103"
    },
    {
        "searchTerm": "den lilla påsen",
        "answer": "das Tütchen",
        "key": "104"
    },
    {
        "searchTerm": "skålen",
        "answer": "die Schüssel",
        "key": "105"
    },
    {
        "searchTerm": "blanda",
        "answer": "vermischen",
        "key": "106"
    },
    {
        "searchTerm": "röra ihop",
        "answer": "verrühren",
        "key": "107"
    },
    {
        "searchTerm": "degblandningen",
        "answer": "die Teigmischung",
        "key": "108"
    },
    {
        "searchTerm": "degen",
        "answer": "der Teig",
        "key": "109"
    },
    {
        "searchTerm": "kylskåpet",
        "answer": "der Kühlschrank",
        "key": "110"
    },
    {
        "searchTerm": "ugnen",
        "answer": "der Backofen",
        "key": "111"
    },
    {
        "searchTerm": "förvärma",
        "answer": "vorheizen",
        "key": "112"
    },
    {
        "searchTerm": "bakplåten",
        "answer": "das Backblech",
        "key": "113"
    },
    {
        "searchTerm": "skenan",
        "answer": "die Schiene",
        "key": "114"
    }
]

export default kapitel92;