const kapitel83 = [
    {
        "searchTerm": "mein Zuhause",
        "answer": "mitt hem",
        "key": "1"
    },
    {
        "searchTerm": "die Einweihungsparty",
        "answer": "invigningspartyt",
        "key": "2"
    },
    {
        "searchTerm": "gestrichen",
        "answer": "strukit",
        "key": "3"
    },
    {
        "searchTerm": "der Flohmarkt",
        "answer": "loppmarknaden",
        "key": "4"
    },
    {
        "searchTerm": "das Ding",
        "answer": "saken",
        "key": "5"
    },
    {
        "searchTerm": "außerdem",
        "answer": "dessutom",
        "key": "6"
    },
    {
        "searchTerm": "gar nicht",
        "answer": "alls inte",
        "key": "7"
    },
    {
        "searchTerm": "gekriegt",
        "answer": "fått",
        "key": "8"
    },
    {
        "searchTerm": "sich zurücklehnen",
        "answer": "luta sig tillbaka",
        "key": "9"
    },
    {
        "searchTerm": "fast",
        "answer": "nästan",
        "key": "10"
    },
    {
        "searchTerm": "geklingelt",
        "answer": "ringt",
        "key": "11"
    },
    {
        "searchTerm": "aufmachen",
        "answer": "öppna",
        "key": "12"
    },
    {
        "searchTerm": "anstellen",
        "answer": "sätta på",
        "key": "13"
    },
    {
        "searchTerm": "sie veranstaltet",
        "answer": "hon anordnar",
        "key": "14"
    },
    {
        "searchTerm": "Da ist immer was los!",
        "answer": "Där är alltid något på gång!",
        "key": "15"
    },
    {
        "searchTerm": "alleinstehend",
        "answer": "ensamstående",
        "key": "16"
    },
    {
        "searchTerm": "berufstätig",
        "answer": "yrkesarbetande",
        "key": "17"
    },
    {
        "searchTerm": "deshalb",
        "answer": "därför",
        "key": "18"
    },
    {
        "searchTerm": "einkaufen gehen",
        "answer": "gå ut och handla",
        "key": "19"
    },
    {
        "searchTerm": "rausbringen",
        "answer": "ta ut",
        "key": "20"
    },
    {
        "searchTerm": "der Müll",
        "answer": "soporna",
        "key": "21"
    },
    {
        "searchTerm": "das Geschirr spülen",
        "answer": "diska",
        "key": "22"
    },
    {
        "searchTerm": "die Geschirrspülmaschine",
        "answer": "diskmaskinen",
        "key": "23"
    },
    {
        "searchTerm": "saugen",
        "answer": "dammsuga",
        "key": "24"
    },
    {
        "searchTerm": "sorgen für",
        "answer": "ta hand om",
        "key": "25"
    },
    {
        "searchTerm": "aufräumen",
        "answer": "röja upp",
        "key": "26"
    },
    {
        "searchTerm": "tatsächlich",
        "answer": "faktiskt",
        "key": "27"
    },
    {
        "searchTerm": "die Wäsche",
        "answer": "tvätten",
        "key": "28"
    },
    {
        "searchTerm": "sie wäscht",
        "answer": "hon tvättar",
        "key": "29"
    },
    {
        "searchTerm": "die Wohnung",
        "answer": "lägenheten",
        "key": "30"
    },
    {
        "searchTerm": "der Stock",
        "answer": "våningen",
        "key": "31"
    },
    {
        "searchTerm": "im dritten Stock",
        "answer": "på tredje våningen",
        "key": "32"
    },
    {
        "searchTerm": "das Nest",
        "answer": "boet",
        "key": "33"
    },
    {
        "searchTerm": "der Laptop",
        "answer": "laptopen",
        "key": "34"
    },
    {
        "searchTerm": "entspannt",
        "answer": "avslappnat",
        "key": "35"
    },
    {
        "searchTerm": "der Roman",
        "answer": "romanen",
        "key": "36"
    },
    {
        "searchTerm": "abhängen",
        "answer": "hänga",
        "key": "37"
    },
    {
        "searchTerm": "weil",
        "answer": "eftersom",
        "key": "38"
    },
    {
        "searchTerm": "die Miete",
        "answer": "hyran",
        "key": "39"
    },
    {
        "searchTerm": "allerdings",
        "answer": "visserligen",
        "key": "40"
    },
    {
        "searchTerm": "einladen",
        "answer": "bjuda in",
        "key": "41"
    },
    {
        "searchTerm": "sich super fühlen",
        "answer": "må toppen",
        "key": "42"
    },
    {
        "searchTerm": "trotzdem",
        "answer": "trots det",
        "key": "43"
    },
    {
        "searchTerm": "das Neubaugebiet",
        "answer": "det nybyggda området",
        "key": "44"
    },
    {
        "searchTerm": "musikfestivalen",
        "answer": "byggt",
        "key": "45"
    },
    {
        "searchTerm": "die Dachterrasse",
        "answer": "takterrassen",
        "key": "46"
    },
    {
        "searchTerm": "echt nerven können",
        "answer": "att kunna gå någon på nerverna",
        "key": "47"
    },
    {
        "searchTerm": "dekorieren",
        "answer": "dekorera",
        "key": "48"
    },
    {
        "searchTerm": "riesig",
        "answer": "jättestor",
        "key": "49"
    },
    {
        "searchTerm": "gucken",
        "answer": "titta",
        "key": "50"
    },
    {
        "searchTerm": "kicken",
        "answer": "kicka",
        "key": "51"
    },
    {
        "searchTerm": "der Nachbar",
        "answer": "grannen",
        "key": "52"
    },
    {
        "searchTerm": "allerdings",
        "answer": "visserligen",
        "key": "53"
    },
    {
        "searchTerm": "ansonsten",
        "answer": "annars",
        "key": "54"
    },
    {
        "searchTerm": "losziehen",
        "answer": "dra iväg",
        "key": "55"
    },
    {
        "searchTerm": "das Erlebnisbad",
        "answer": "äventyrsbadet",
        "key": "56"
    },
    {
        "searchTerm": "der Milchhof",
        "answer": "mjölkgården",
        "key": "57"
    },
    {
        "searchTerm": "Das nervt!",
        "answer": "Det irriterar mig!",
        "key": "58"
    },
    {
        "searchTerm": "ländlich",
        "answer": "lantlig",
        "key": "59"
    },
    {
        "searchTerm": "ähnlich",
        "answer": "snarlikt",
        "key": "60"
    },
    {
        "searchTerm": "sogar",
        "answer": "till och med",
        "key": "61"
    },
    {
        "searchTerm": "die Bevölkerung",
        "answer": "befolkningen",
        "key": "62"
    },
    {
        "searchTerm": "zum Vergleich",
        "answer": "i jämförelse med",
        "key": "63"
    },
    {
        "searchTerm": "gleichzeitig",
        "answer": "samtidigt",
        "key": "64"
    },
    {
        "searchTerm": "nirgendwo",
        "answer": "ingenstans",
        "key": "65"
    },
    {
        "searchTerm": "der Raum",
        "answer": "området",
        "key": "66"
    },
    {
        "searchTerm": "die Vielfalt",
        "answer": "mångfalden",
        "key": "67"
    },
    {
        "searchTerm": "der Bewohner",
        "answer": "invånaren",
        "key": "68"
    },
    {
        "searchTerm": "bieten",
        "answer": "erbjuda",
        "key": "69"
    },
    {
        "searchTerm": "die Entscheidung",
        "answer": "avgörandet",
        "key": "70"
    },
    {
        "searchTerm": "der Vorteil",
        "answer": "fördelen",
        "key": "71"
    },
    {
        "searchTerm": "der Nachteil",
        "answer": "nackdelen",
        "key": "72"
    },
    {
        "searchTerm": "gesund",
        "answer": "hälsosam",
        "key": "73"
    },
    {
        "searchTerm": "im Vergleich zu",
        "answer": "i jämförelse med",
        "key": "74"
    },
    {
        "searchTerm": "wenig",
        "answer": "lite",
        "key": "75"
    },
    {
        "searchTerm": "der Verkehr",
        "answer": "trafiken",
        "key": "76"
    },
    {
        "searchTerm": "jeder",
        "answer": "var och en",
        "key": "77"
    },
    {
        "searchTerm": "dadurch",
        "answer": "därigenom",
        "key": "78"
    },
    {
        "searchTerm": "außerdem",
        "answer": "dessutom",
        "key": "79"
    },
    {
        "searchTerm": "gegenseitig",
        "answer": "ömsesidigt",
        "key": "80"
    },
    {
        "searchTerm": "genauso",
        "answer": "precis så",
        "key": "81"
    },
    {
        "searchTerm": "vor allem",
        "answer": "framförallt",
        "key": "82"
    },
    {
        "searchTerm": "es ist nicht viel los",
        "answer": "det är inte mycket på gång",
        "key": "83"
    },
    {
        "searchTerm": "das Jugendhaus",
        "answer": "ungdomsgården",
        "key": "84"
    },
    {
        "searchTerm": "die Langeweile",
        "answer": "ledan",
        "key": "85"
    },
    {
        "searchTerm": "beobachten",
        "answer": "betrakta",
        "key": "86"
    },
    {
        "searchTerm": "erreichen",
        "answer": "nå fram",
        "key": "87"
    },
    {
        "searchTerm": "sauber",
        "answer": "ren",
        "key": "88"
    },
    {
        "searchTerm": "nicht einmal",
        "answer": "inte ens",
        "key": "89"
    },
    {
        "searchTerm": "mitt hem",
        "answer": "mein Zuhause",
        "key": "90"
    },
    {
        "searchTerm": "invigningspartyt",
        "answer": "die Einweihungsparty",
        "key": "91"
    },
    {
        "searchTerm": "strukit",
        "answer": "gestrichen",
        "key": "92"
    },
    {
        "searchTerm": "loppmarknaden",
        "answer": "der Flohmarkt",
        "key": "93"
    },
    {
        "searchTerm": "saken",
        "answer": "das Ding",
        "key": "94"
    },
    {
        "searchTerm": "dessutom",
        "answer": "außerdem",
        "key": "95"
    },
    {
        "searchTerm": "alls inte",
        "answer": "gar nicht",
        "key": "96"
    },
    {
        "searchTerm": "fått",
        "answer": "gekriegt",
        "key": "97"
    },
    {
        "searchTerm": "luta sig tillbaka",
        "answer": "sich zurücklehnen",
        "key": "98"
    },
    {
        "searchTerm": "nästan",
        "answer": "fast",
        "key": "99"
    },
    {
        "searchTerm": "ringt",
        "answer": "geklingelt",
        "key": "100"
    },
    {
        "searchTerm": "öppna",
        "answer": "aufmachen",
        "key": "101"
    },
    {
        "searchTerm": "sätta på",
        "answer": "anstellen",
        "key": "102"
    },
    {
        "searchTerm": "hon anordnar",
        "answer": "sie veranstaltet",
        "key": "103"
    },
    {
        "searchTerm": "Där är alltid något på gång!",
        "answer": "Da ist immer was los!",
        "key": "104"
    },
    {
        "searchTerm": "ensamstående",
        "answer": "alleinstehend",
        "key": "105"
    },
    {
        "searchTerm": "yrkesarbetande",
        "answer": "berufstätig",
        "key": "106"
    },
    {
        "searchTerm": "därför",
        "answer": "deshalb",
        "key": "107"
    },
    {
        "searchTerm": "gå ut och handla",
        "answer": "einkaufen gehen",
        "key": "108"
    },
    {
        "searchTerm": "ta ut",
        "answer": "rausbringen",
        "key": "109"
    },
    {
        "searchTerm": "soporna",
        "answer": "der Müll",
        "key": "110"
    },
    {
        "searchTerm": "diska",
        "answer": "das Geschirr spülen",
        "key": "111"
    },
    {
        "searchTerm": "diskmaskinen",
        "answer": "die Geschirrspülmaschine",
        "key": "112"
    },
    {
        "searchTerm": "dammsuga",
        "answer": "saugen",
        "key": "113"
    },
    {
        "searchTerm": "ta hand om",
        "answer": "sorgen für",
        "key": "114"
    },
    {
        "searchTerm": "röja upp",
        "answer": "aufräumen",
        "key": "115"
    },
    {
        "searchTerm": "faktiskt",
        "answer": "tatsächlich",
        "key": "116"
    },
    {
        "searchTerm": "tvätten",
        "answer": "die Wäsche",
        "key": "117"
    },
    {
        "searchTerm": "hon tvättar",
        "answer": "sie wäscht",
        "key": "118"
    },
    {
        "searchTerm": "lägenheten",
        "answer": "die Wohnung",
        "key": "119"
    },
    {
        "searchTerm": "våningen",
        "answer": "der Stock",
        "key": "120"
    },
    {
        "searchTerm": "på tredje våningen",
        "answer": "im dritten Stock",
        "key": "121"
    },
    {
        "searchTerm": "boet",
        "answer": "das Nest",
        "key": "122"
    },
    {
        "searchTerm": "laptopen",
        "answer": "der Laptop",
        "key": "123"
    },
    {
        "searchTerm": "avslappnat",
        "answer": "entspannt",
        "key": "124"
    },
    {
        "searchTerm": "romanen",
        "answer": "der Roman",
        "key": "125"
    },
    {
        "searchTerm": "hänga",
        "answer": "abhängen",
        "key": "126"
    },
    {
        "searchTerm": "eftersom",
        "answer": "weil",
        "key": "127"
    },
    {
        "searchTerm": "hyran",
        "answer": "die Miete",
        "key": "128"
    },
    {
        "searchTerm": "visserligen",
        "answer": "allerdings",
        "key": "129"
    },
    {
        "searchTerm": "bjuda in",
        "answer": "einladen",
        "key": "130"
    },
    {
        "searchTerm": "må toppen",
        "answer": "sich super fühlen",
        "key": "131"
    },
    {
        "searchTerm": "trots det",
        "answer": "trotzdem",
        "key": "132"
    },
    {
        "searchTerm": "det nybyggda området",
        "answer": "das Neubaugebiet",
        "key": "133"
    },
    {
        "searchTerm": "byggt",
        "answer": "musikfestivalen",
        "key": "134"
    },
    {
        "searchTerm": "takterrassen",
        "answer": "die Dachterrasse",
        "key": "135"
    },
    {
        "searchTerm": "att kunna gå någon på nerverna",
        "answer": "echt nerven können",
        "key": "136"
    },
    {
        "searchTerm": "dekorera",
        "answer": "dekorieren",
        "key": "137"
    },
    {
        "searchTerm": "jättestor",
        "answer": "riesig",
        "key": "138"
    },
    {
        "searchTerm": "titta",
        "answer": "gucken",
        "key": "139"
    },
    {
        "searchTerm": "kicka",
        "answer": "kicken",
        "key": "140"
    },
    {
        "searchTerm": "grannen",
        "answer": "der Nachbar",
        "key": "141"
    },
    {
        "searchTerm": "visserligen",
        "answer": "allerdings",
        "key": "142"
    },
    {
        "searchTerm": "annars",
        "answer": "ansonsten",
        "key": "143"
    },
    {
        "searchTerm": "dra iväg",
        "answer": "losziehen",
        "key": "144"
    },
    {
        "searchTerm": "äventyrsbadet",
        "answer": "das Erlebnisbad",
        "key": "145"
    },
    {
        "searchTerm": "mjölkgården",
        "answer": "der Milchhof",
        "key": "146"
    },
    {
        "searchTerm": "Det irriterar mig!",
        "answer": "Das nervt!",
        "key": "147"
    },
    {
        "searchTerm": "lantlig",
        "answer": "ländlich",
        "key": "148"
    },
    {
        "searchTerm": "snarlikt",
        "answer": "ähnlich",
        "key": "149"
    },
    {
        "searchTerm": "till och med",
        "answer": "sogar",
        "key": "150"
    },
    {
        "searchTerm": "befolkningen",
        "answer": "die Bevölkerung",
        "key": "151"
    },
    {
        "searchTerm": "i jämförelse med",
        "answer": "zum Vergleich",
        "key": "152"
    },
    {
        "searchTerm": "samtidigt",
        "answer": "gleichzeitig",
        "key": "153"
    },
    {
        "searchTerm": "ingenstans",
        "answer": "nirgendwo",
        "key": "154"
    },
    {
        "searchTerm": "området",
        "answer": "der Raum",
        "key": "155"
    },
    {
        "searchTerm": "mångfalden",
        "answer": "die Vielfalt",
        "key": "156"
    },
    {
        "searchTerm": "invånaren",
        "answer": "der Bewohner",
        "key": "157"
    },
    {
        "searchTerm": "erbjuda",
        "answer": "bieten",
        "key": "158"
    },
    {
        "searchTerm": "avgörandet",
        "answer": "die Entscheidung",
        "key": "159"
    },
    {
        "searchTerm": "fördelen",
        "answer": "der Vorteil",
        "key": "160"
    },
    {
        "searchTerm": "nackdelen",
        "answer": "der Nachteil",
        "key": "161"
    },
    {
        "searchTerm": "hälsosam",
        "answer": "gesund",
        "key": "162"
    },
    {
        "searchTerm": "i jämförelse med",
        "answer": "im Vergleich zu",
        "key": "163"
    },
    {
        "searchTerm": "lite",
        "answer": "wenig",
        "key": "164"
    },
    {
        "searchTerm": "trafiken",
        "answer": "der Verkehr",
        "key": "165"
    },
    {
        "searchTerm": "var och en",
        "answer": "jeder",
        "key": "166"
    },
    {
        "searchTerm": "därigenom",
        "answer": "dadurch",
        "key": "167"
    },
    {
        "searchTerm": "dessutom",
        "answer": "außerdem",
        "key": "168"
    },
    {
        "searchTerm": "ömsesidigt",
        "answer": "gegenseitig",
        "key": "169"
    },
    {
        "searchTerm": "precis så",
        "answer": "genauso",
        "key": "170"
    },
    {
        "searchTerm": "framförallt",
        "answer": "vor allem",
        "key": "171"
    },
    {
        "searchTerm": "det är inte mycket på gång",
        "answer": "es ist nicht viel los",
        "key": "172"
    },
    {
        "searchTerm": "ungdomsgården",
        "answer": "das Jugendhaus",
        "key": "173"
    },
    {
        "searchTerm": "ledan",
        "answer": "die Langeweile",
        "key": "174"
    },
    {
        "searchTerm": "betrakta",
        "answer": "beobachten",
        "key": "175"
    },
    {
        "searchTerm": "nå fram",
        "answer": "erreichen",
        "key": "176"
    },
    {
        "searchTerm": "ren",
        "answer": "sauber",
        "key": "177"
    },
    {
        "searchTerm": "inte ens",
        "answer": "nicht einmal",
        "key": "178"
    }
]

export default kapitel83;