const kapitel84 = [
    {
        "searchTerm": "die Verabredung",
        "answer": "överenskommelsen",
        "key": "1"
    },
    {
        "searchTerm": "der Keller",
        "answer": "källaren",
        "key": "2"
    },
    {
        "searchTerm": "dauern",
        "answer": "vara (om tid)",
        "key": "3"
    },
    {
        "searchTerm": "der Zuhörer",
        "answer": "lyssnaren",
        "key": "4"
    },
    {
        "searchTerm": "das Konzert",
        "answer": "konserten",
        "key": "5"
    },
    {
        "searchTerm": "geübt",
        "answer": "övat",
        "key": "6"
    },
    {
        "searchTerm": "das Publikum",
        "answer": "publiken",
        "key": "7"
    },
    {
        "searchTerm": "laut",
        "answer": "högt",
        "key": "8"
    },
    {
        "searchTerm": "das Naschen",
        "answer": "småätandet",
        "key": "9"
    },
    {
        "searchTerm": "mitbringen",
        "answer": "ta med",
        "key": "10"
    },
    {
        "searchTerm": "auf euch kann man sich verlassen",
        "answer": "man kan lita på er",
        "key": "11"
    },
    {
        "searchTerm": "vorschlagen",
        "answer": "föreslå",
        "key": "12"
    },
    {
        "searchTerm": "verschwitzt",
        "answer": "genomsvettig",
        "key": "13"
    },
    {
        "searchTerm": "das Handtuch",
        "answer": "handduken",
        "key": "14"
    },
    {
        "searchTerm": "moin",
        "answer": "hej",
        "key": "15"
    },
    {
        "searchTerm": "die Nordsee",
        "answer": "Nordsjön",
        "key": "16"
    },
    {
        "searchTerm": "die Mission",
        "answer": "uppdraget",
        "key": "17"
    },
    {
        "searchTerm": "die Jugendlichen",
        "answer": "ungdomarna",
        "key": "18"
    },
    {
        "searchTerm": "die Freundesgruppe",
        "answer": "kompisgruppen",
        "key": "19"
    },
    {
        "searchTerm": "sich vorstellen",
        "answer": "presentera sig",
        "key": "20"
    },
    {
        "searchTerm": "das Leben",
        "answer": "livet",
        "key": "21"
    },
    {
        "searchTerm": "das Geheimnis",
        "answer": "hemligheten",
        "key": "22"
    },
    {
        "searchTerm": "ich vertraue",
        "answer": "jag litar på",
        "key": "23"
    },
    {
        "searchTerm": "niemand",
        "answer": "ingen",
        "key": "24"
    },
    {
        "searchTerm": "aufgeregt",
        "answer": "upprörd",
        "key": "25"
    },
    {
        "searchTerm": "die Lieblingsband",
        "answer": "älsklingsbandet",
        "key": "26"
    },
    {
        "searchTerm": "kennengelernt  ",
        "answer": "lärt känna",
        "key": "27"
    },
    {
        "searchTerm": "seitdem",
        "answer": "sedan dess",
        "key": "28"
    },
    {
        "searchTerm": "unzertrennlich",
        "answer": "oskiljbar",
        "key": "29"
    },
    {
        "searchTerm": "zusammengeklebt  ",
        "answer": "ihopklistrade",
        "key": "30"
    },
    {
        "searchTerm": "der Teil",
        "answer": "delen",
        "key": "31"
    },
    {
        "searchTerm": "traurig",
        "answer": "ledsen",
        "key": "32"
    },
    {
        "searchTerm": "anvertrauen",
        "answer": "anförtro",
        "key": "33"
    },
    {
        "searchTerm": "der Ohrenschützer",
        "answer": "öronskyddet",
        "key": "34"
    },
    {
        "searchTerm": "genießen",
        "answer": "njuta",
        "key": "35"
    },
    {
        "searchTerm": "auf jeden Fall",
        "answer": "i varje fall",
        "key": "36"
    },
    {
        "searchTerm": "die Ehrlichkeit",
        "answer": "ärligheten",
        "key": "37"
    },
    {
        "searchTerm": "die Zuverlässigkeit",
        "answer": "tillförlitligheten",
        "key": "38"
    },
    {
        "searchTerm": "mich zum Lachen bringen",
        "answer": "få mig att skratta",
        "key": "39"
    },
    {
        "searchTerm": "unglaublich",
        "answer": "otrolig",
        "key": "40"
    },
    {
        "searchTerm": "weiter",
        "answer": "ytterligare",
        "key": "41"
    },
    {
        "searchTerm": "dachte",
        "answer": "tänkte",
        "key": "42"
    },
    {
        "searchTerm": "auf die Probe gestellt  ",
        "answer": "ställd på prov",
        "key": "43"
    },
    {
        "searchTerm": "verzweifelt",
        "answer": "förtvivlad",
        "key": "44"
    },
    {
        "searchTerm": "noch nie",
        "answer": "aldrig någonsin",
        "key": "45"
    },
    {
        "searchTerm": "antun",
        "answer": "göra",
        "key": "46"
    },
    {
        "searchTerm": "einem etwas ausspannen",
        "answer": "ta ifrån någon något",
        "key": "47"
    },
    {
        "searchTerm": "die Beziehung",
        "answer": "relationen",
        "key": "48"
    },
    {
        "searchTerm": "ging los",
        "answer": "startade",
        "key": "49"
    },
    {
        "searchTerm": "gemerkt",
        "answer": "märkt",
        "key": "50"
    },
    {
        "searchTerm": "mit mir Schluss gemacht",
        "answer": "gjort slut med mig",
        "key": "51"
    },
    {
        "searchTerm": "das heißt",
        "answer": "det betyder",
        "key": "52"
    },
    {
        "searchTerm": "ehemalig",
        "answer": "före detta",
        "key": "53"
    },
    {
        "searchTerm": "überstehen",
        "answer": "uthärda",
        "key": "54"
    },
    {
        "searchTerm": "jemals",
        "answer": "någonsin",
        "key": "55"
    },
    {
        "searchTerm": "verzeihen",
        "answer": "förlåta",
        "key": "56"
    },
    {
        "searchTerm": "enttäuscht",
        "answer": "besviken",
        "key": "57"
    },
    {
        "searchTerm": "aufgeben",
        "answer": "ge upp",
        "key": "58"
    },
    {
        "searchTerm": "das Gespräch",
        "answer": "samtalet",
        "key": "59"
    },
    {
        "searchTerm": "klären",
        "answer": "reda upp",
        "key": "60"
    },
    {
        "searchTerm": "um etwas kämpfen",
        "answer": "kämpa om något",
        "key": "61"
    },
    {
        "searchTerm": "unverzeihlich",
        "answer": "oförlåtligt",
        "key": "62"
    },
    {
        "searchTerm": "auf andere Gedanken kommen",
        "answer": "komma på andra tankar",
        "key": "63"
    },
    {
        "searchTerm": "die Zeit heilt alle Wunden",
        "answer": "tiden läker alla sår",
        "key": "64"
    },
    {
        "searchTerm": "aus dem Weg gehen",
        "answer": "gå undan",
        "key": "65"
    },
    {
        "searchTerm": "vermissen",
        "answer": "sakna",
        "key": "66"
    },
    {
        "searchTerm": "es tut Ben leid",
        "answer": "Ben är ledsen",
        "key": "67"
    },
    {
        "searchTerm": "wegen",
        "answer": "på grund av",
        "key": "68"
    },
    {
        "searchTerm": "das Verhalten",
        "answer": "uppförandet",
        "key": "69"
    },
    {
        "searchTerm": "erleben",
        "answer": "uppleva",
        "key": "70"
    },
    {
        "searchTerm": "överenskommelsen",
        "answer": "die Verabredung",
        "key": "71"
    },
    {
        "searchTerm": "källaren",
        "answer": "der Keller",
        "key": "72"
    },
    {
        "searchTerm": "vara (om tid)",
        "answer": "dauern",
        "key": "73"
    },
    {
        "searchTerm": "lyssnaren",
        "answer": "der Zuhörer",
        "key": "74"
    },
    {
        "searchTerm": "konserten",
        "answer": "das Konzert",
        "key": "75"
    },
    {
        "searchTerm": "övat",
        "answer": "geübt",
        "key": "76"
    },
    {
        "searchTerm": "publiken",
        "answer": "das Publikum",
        "key": "77"
    },
    {
        "searchTerm": "högt",
        "answer": "laut",
        "key": "78"
    },
    {
        "searchTerm": "småätandet",
        "answer": "das Naschen",
        "key": "79"
    },
    {
        "searchTerm": "ta med",
        "answer": "mitbringen",
        "key": "80"
    },
    {
        "searchTerm": "man kan lita på er",
        "answer": "auf euch kann man sich verlassen",
        "key": "81"
    },
    {
        "searchTerm": "föreslå",
        "answer": "vorschlagen",
        "key": "82"
    },
    {
        "searchTerm": "genomsvettig",
        "answer": "verschwitzt",
        "key": "83"
    },
    {
        "searchTerm": "handduken",
        "answer": "das Handtuch",
        "key": "84"
    },
    {
        "searchTerm": "hej",
        "answer": "moin",
        "key": "85"
    },
    {
        "searchTerm": "Nordsjön",
        "answer": "die Nordsee",
        "key": "86"
    },
    {
        "searchTerm": "uppdraget",
        "answer": "die Mission",
        "key": "87"
    },
    {
        "searchTerm": "ungdomarna",
        "answer": "die Jugendlichen",
        "key": "88"
    },
    {
        "searchTerm": "kompisgruppen",
        "answer": "die Freundesgruppe",
        "key": "89"
    },
    {
        "searchTerm": "presentera sig",
        "answer": "sich vorstellen",
        "key": "90"
    },
    {
        "searchTerm": "livet",
        "answer": "das Leben",
        "key": "91"
    },
    {
        "searchTerm": "hemligheten",
        "answer": "das Geheimnis",
        "key": "92"
    },
    {
        "searchTerm": "jag litar på",
        "answer": "ich vertraue",
        "key": "93"
    },
    {
        "searchTerm": "ingen",
        "answer": "niemand",
        "key": "94"
    },
    {
        "searchTerm": "upprörd",
        "answer": "aufgeregt",
        "key": "95"
    },
    {
        "searchTerm": "älsklingsbandet",
        "answer": "die Lieblingsband",
        "key": "96"
    },
    {
        "searchTerm": "lärt känna",
        "answer": "kennengelernt  ",
        "key": "97"
    },
    {
        "searchTerm": "sedan dess",
        "answer": "seitdem",
        "key": "98"
    },
    {
        "searchTerm": "oskiljbar",
        "answer": "unzertrennlich",
        "key": "99"
    },
    {
        "searchTerm": "ihopklistrade",
        "answer": "zusammengeklebt  ",
        "key": "100"
    },
    {
        "searchTerm": "delen",
        "answer": "der Teil",
        "key": "101"
    },
    {
        "searchTerm": "ledsen",
        "answer": "traurig",
        "key": "102"
    },
    {
        "searchTerm": "anförtro",
        "answer": "anvertrauen",
        "key": "103"
    },
    {
        "searchTerm": "öronskyddet",
        "answer": "der Ohrenschützer",
        "key": "104"
    },
    {
        "searchTerm": "njuta",
        "answer": "genießen",
        "key": "105"
    },
    {
        "searchTerm": "i varje fall",
        "answer": "auf jeden Fall",
        "key": "106"
    },
    {
        "searchTerm": "ärligheten",
        "answer": "die Ehrlichkeit",
        "key": "107"
    },
    {
        "searchTerm": "tillförlitligheten",
        "answer": "die Zuverlässigkeit",
        "key": "108"
    },
    {
        "searchTerm": "få mig att skratta",
        "answer": "mich zum Lachen bringen",
        "key": "109"
    },
    {
        "searchTerm": "otrolig",
        "answer": "unglaublich",
        "key": "110"
    },
    {
        "searchTerm": "ytterligare",
        "answer": "weiter",
        "key": "111"
    },
    {
        "searchTerm": "tänkte",
        "answer": "dachte",
        "key": "112"
    },
    {
        "searchTerm": "ställd på prov",
        "answer": "auf die Probe gestellt  ",
        "key": "113"
    },
    {
        "searchTerm": "förtvivlad",
        "answer": "verzweifelt",
        "key": "114"
    },
    {
        "searchTerm": "aldrig någonsin",
        "answer": "noch nie",
        "key": "115"
    },
    {
        "searchTerm": "göra",
        "answer": "antun",
        "key": "116"
    },
    {
        "searchTerm": "ta ifrån någon något",
        "answer": "einem etwas ausspannen",
        "key": "117"
    },
    {
        "searchTerm": "relationen",
        "answer": "die Beziehung",
        "key": "118"
    },
    {
        "searchTerm": "startade",
        "answer": "ging los",
        "key": "119"
    },
    {
        "searchTerm": "märkt",
        "answer": "gemerkt",
        "key": "120"
    },
    {
        "searchTerm": "gjort slut med mig",
        "answer": "mit mir Schluss gemacht",
        "key": "121"
    },
    {
        "searchTerm": "det betyder",
        "answer": "das heißt",
        "key": "122"
    },
    {
        "searchTerm": "före detta",
        "answer": "ehemalig",
        "key": "123"
    },
    {
        "searchTerm": "uthärda",
        "answer": "überstehen",
        "key": "124"
    },
    {
        "searchTerm": "någonsin",
        "answer": "jemals",
        "key": "125"
    },
    {
        "searchTerm": "förlåta",
        "answer": "verzeihen",
        "key": "126"
    },
    {
        "searchTerm": "besviken",
        "answer": "enttäuscht",
        "key": "127"
    },
    {
        "searchTerm": "ge upp",
        "answer": "aufgeben",
        "key": "128"
    },
    {
        "searchTerm": "samtalet",
        "answer": "das Gespräch",
        "key": "129"
    },
    {
        "searchTerm": "reda upp",
        "answer": "klären",
        "key": "130"
    },
    {
        "searchTerm": "kämpa om något",
        "answer": "um etwas kämpfen",
        "key": "131"
    },
    {
        "searchTerm": "oförlåtligt",
        "answer": "unverzeihlich",
        "key": "132"
    },
    {
        "searchTerm": "komma på andra tankar",
        "answer": "auf andere Gedanken kommen",
        "key": "133"
    },
    {
        "searchTerm": "tiden läker alla sår",
        "answer": "die Zeit heilt alle Wunden",
        "key": "134"
    },
    {
        "searchTerm": "gå undan",
        "answer": "aus dem Weg gehen",
        "key": "135"
    },
    {
        "searchTerm": "sakna",
        "answer": "vermissen",
        "key": "136"
    },
    {
        "searchTerm": "Ben är ledsen",
        "answer": "es tut Ben leid",
        "key": "137"
    },
    {
        "searchTerm": "på grund av",
        "answer": "wegen",
        "key": "138"
    },
    {
        "searchTerm": "uppförandet",
        "answer": "das Verhalten",
        "key": "139"
    },
    {
        "searchTerm": "uppleva",
        "answer": "erleben",
        "key": "140"
    }
]

export default kapitel84;