const kapitel93 = [
    {
        "searchTerm": "mit der Mode gehen",
        "answer": "följa modet",
        "key": "1"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "märkeskläderna",
        "key": "2"
    },
    {
        "searchTerm": "die Klamotten",
        "answer": "kläderna",
        "key": "3"
    },
    {
        "searchTerm": "zwar",
        "answer": "visserligen",
        "key": "4"
    },
    {
        "searchTerm": "es ist mir egal",
        "answer": "det är mig likgiltigt",
        "key": "5"
    },
    {
        "searchTerm": "sich anziehen",
        "answer": "klä på sig",
        "key": "6"
    },
    {
        "searchTerm": "ich achte nicht darauf",
        "answer": "jag bryr mig inte om det",
        "key": "7"
    },
    {
        "searchTerm": "zeitlos",
        "answer": "tidlös",
        "key": "8"
    },
    {
        "searchTerm": "eher",
        "answer": "snarare",
        "key": "9"
    },
    {
        "searchTerm": "sich wohlfühlen",
        "answer": "må bra",
        "key": "10"
    },
    {
        "searchTerm": "widerspiegeln",
        "answer": "återspegla",
        "key": "11"
    },
    {
        "searchTerm": "tragen",
        "answer": "ha på sig",
        "key": "12"
    },
    {
        "searchTerm": "dunkel",
        "answer": "mörk",
        "key": "13"
    },
    {
        "searchTerm": "gelegentlich",
        "answer": "ibland",
        "key": "14"
    },
    {
        "searchTerm": "der Rock",
        "answer": "kjolen",
        "key": "15"
    },
    {
        "searchTerm": "das Kleid",
        "answer": "klänningen",
        "key": "16"
    },
    {
        "searchTerm": "der Stiefel",
        "answer": "stöveln",
        "key": "17"
    },
    {
        "searchTerm": "kariert",
        "answer": "rutig",
        "key": "18"
    },
    {
        "searchTerm": "das Hemd",
        "answer": "skjortan",
        "key": "19"
    },
    {
        "searchTerm": "rumhängen",
        "answer": "hänga",
        "key": "20"
    },
    {
        "searchTerm": "lässig",
        "answer": "slappt",
        "key": "21"
    },
    {
        "searchTerm": "irgendwie",
        "answer": "på något sätt",
        "key": "22"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "det yttre",
        "key": "23"
    },
    {
        "searchTerm": "der Eindruck",
        "answer": "intrycket",
        "key": "24"
    },
    {
        "searchTerm": "hervorstechen",
        "answer": "sticka ut",
        "key": "25"
    },
    {
        "searchTerm": "schau mal",
        "answer": "titta",
        "key": "26"
    },
    {
        "searchTerm": "die Modepuppe",
        "answer": "modedockan",
        "key": "27"
    },
    {
        "searchTerm": "Geld verdienen",
        "answer": "tjäna pengar",
        "key": "28"
    },
    {
        "searchTerm": "verkaufen",
        "answer": "sälja",
        "key": "29"
    },
    {
        "searchTerm": "der Flohmarkt",
        "answer": "loppmarknaden",
        "key": "30"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "stödundervisningen",
        "key": "31"
    },
    {
        "searchTerm": "das Werbeblatt",
        "answer": "reklambladet",
        "key": "32"
    },
    {
        "searchTerm": "austragen",
        "answer": "bära ut",
        "key": "33"
    },
    {
        "searchTerm": "kriegen",
        "answer": "få",
        "key": "34"
    },
    {
        "searchTerm": "Gassi gehen",
        "answer": "gå ut med hunden",
        "key": "35"
    },
    {
        "searchTerm": "passieren",
        "answer": "hända",
        "key": "36"
    },
    {
        "searchTerm": "der Nachbar",
        "answer": "grannen",
        "key": "37"
    },
    {
        "searchTerm": "Geschirr waschen",
        "answer": "diska",
        "key": "38"
    },
    {
        "searchTerm": "aufräumen",
        "answer": "plocka undan",
        "key": "39"
    },
    {
        "searchTerm": "den Rasen mähen",
        "answer": "klippa gräsmattan",
        "key": "40"
    },
    {
        "searchTerm": "Schnee schaufeln",
        "answer": "skotta snö",
        "key": "41"
    },
    {
        "searchTerm": "ausreichen",
        "answer": "räcka till",
        "key": "42"
    },
    {
        "searchTerm": "der Wunsch",
        "answer": "önskan",
        "key": "43"
    },
    {
        "searchTerm": "der Nebenjob",
        "answer": "extrajobbet",
        "key": "44"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "fickpengarna",
        "key": "45"
    },
    {
        "searchTerm": "beliebt",
        "answer": "omtyckt",
        "key": "46"
    },
    {
        "searchTerm": "der Nebenverdienst",
        "answer": "extrainkomsten",
        "key": "47"
    },
    {
        "searchTerm": "die Quelle",
        "answer": "källan",
        "key": "48"
    },
    {
        "searchTerm": "das Gesetz",
        "answer": "lagen",
        "key": "49"
    },
    {
        "searchTerm": "zustimmen",
        "answer": "ge tillstånd",
        "key": "50"
    },
    {
        "searchTerm": "genug",
        "answer": "tillräckligt",
        "key": "51"
    },
    {
        "searchTerm": "während der Schulzeit",
        "answer": "under skoltid",
        "key": "52"
    },
    {
        "searchTerm": "zwischen",
        "answer": "mellan",
        "key": "53"
    },
    {
        "searchTerm": "höchstens",
        "answer": "högst",
        "key": "54"
    },
    {
        "searchTerm": "die Gaststätte",
        "answer": "restaurangen",
        "key": "55"
    },
    {
        "searchTerm": "die Eisdiele",
        "answer": "glasståndet",
        "key": "56"
    },
    {
        "searchTerm": "der Betreuer",
        "answer": "vårdaren",
        "key": "57"
    },
    {
        "searchTerm": "der Freizeitpark",
        "answer": "fritidsparken",
        "key": "58"
    },
    {
        "searchTerm": "sich lohnen",
        "answer": "löna sig",
        "key": "59"
    },
    {
        "searchTerm": "der Flyer",
        "answer": "reklambladet",
        "key": "60"
    },
    {
        "searchTerm": "Deutsch",
        "answer": "omgivningen",
        "key": "61"
    },
    {
        "searchTerm": "verteilen",
        "answer": "dela ut",
        "key": "62"
    },
    {
        "searchTerm": "darüber hinaus",
        "answer": "utöver det",
        "key": "63"
    },
    {
        "searchTerm": "der Kindergarten",
        "answer": "förskolan",
        "key": "64"
    },
    {
        "searchTerm": "das Schwarze Brett",
        "answer": "anslagstavlan",
        "key": "65"
    },
    {
        "searchTerm": "zufrieden",
        "answer": "nöjd",
        "key": "66"
    },
    {
        "searchTerm": "sich verkleiden",
        "answer": "klä ut sig",
        "key": "67"
    },
    {
        "searchTerm": "meistens",
        "answer": "för det mesta",
        "key": "68"
    },
    {
        "searchTerm": "das Kostüm",
        "answer": "dräkten",
        "key": "69"
    },
    {
        "searchTerm": "der Wettbewerb",
        "answer": "tävlingen",
        "key": "70"
    },
    {
        "searchTerm": "gewonnen",
        "answer": "vunnit",
        "key": "71"
    },
    {
        "searchTerm": "basteln",
        "answer": "knåpa ihop",
        "key": "72"
    },
    {
        "searchTerm": "mochte",
        "answer": "tyckte om",
        "key": "73"
    },
    {
        "searchTerm": "das Tattoo",
        "answer": "tatueringen",
        "key": "74"
    },
    {
        "searchTerm": "rasiert",
        "answer": "rakad",
        "key": "75"
    },
    {
        "searchTerm": "der Ingwerwurzeltee",
        "answer": "teet av ingefärsrot",
        "key": "76"
    },
    {
        "searchTerm": "vortragen",
        "answer": "framföra",
        "key": "77"
    },
    {
        "searchTerm": "entscheiden",
        "answer": "besluta",
        "key": "78"
    },
    {
        "searchTerm": "das Vorbild",
        "answer": "förebilden",
        "key": "79"
    },
    {
        "searchTerm": "albern",
        "answer": "fånig",
        "key": "80"
    },
    {
        "searchTerm": "namens",
        "answer": "med namnet",
        "key": "81"
    },
    {
        "searchTerm": "umgebaut",
        "answer": "ombyggd",
        "key": "82"
    },
    {
        "searchTerm": "dagegen",
        "answer": "däremot",
        "key": "83"
    },
    {
        "searchTerm": "die Meisterschaft",
        "answer": "mästerskapet",
        "key": "84"
    },
    {
        "searchTerm": "der Zuschauer",
        "answer": "åskådaren",
        "key": "85"
    },
    {
        "searchTerm": "authentisch",
        "answer": "äkta",
        "key": "86"
    },
    {
        "searchTerm": "ausgeglichen",
        "answer": "balanserad",
        "key": "87"
    },
    {
        "searchTerm": "seitdem",
        "answer": "sedan dess",
        "key": "88"
    },
    {
        "searchTerm": "följa modet",
        "answer": "mit der Mode gehen",
        "key": "89"
    },
    {
        "searchTerm": "märkeskläderna",
        "answer": "Deutsch",
        "key": "90"
    },
    {
        "searchTerm": "kläderna",
        "answer": "die Klamotten",
        "key": "91"
    },
    {
        "searchTerm": "visserligen",
        "answer": "zwar",
        "key": "92"
    },
    {
        "searchTerm": "det är mig likgiltigt",
        "answer": "es ist mir egal",
        "key": "93"
    },
    {
        "searchTerm": "klä på sig",
        "answer": "sich anziehen",
        "key": "94"
    },
    {
        "searchTerm": "jag bryr mig inte om det",
        "answer": "ich achte nicht darauf",
        "key": "95"
    },
    {
        "searchTerm": "tidlös",
        "answer": "zeitlos",
        "key": "96"
    },
    {
        "searchTerm": "snarare",
        "answer": "eher",
        "key": "97"
    },
    {
        "searchTerm": "må bra",
        "answer": "sich wohlfühlen",
        "key": "98"
    },
    {
        "searchTerm": "återspegla",
        "answer": "widerspiegeln",
        "key": "99"
    },
    {
        "searchTerm": "ha på sig",
        "answer": "tragen",
        "key": "100"
    },
    {
        "searchTerm": "mörk",
        "answer": "dunkel",
        "key": "101"
    },
    {
        "searchTerm": "ibland",
        "answer": "gelegentlich",
        "key": "102"
    },
    {
        "searchTerm": "kjolen",
        "answer": "der Rock",
        "key": "103"
    },
    {
        "searchTerm": "klänningen",
        "answer": "das Kleid",
        "key": "104"
    },
    {
        "searchTerm": "stöveln",
        "answer": "der Stiefel",
        "key": "105"
    },
    {
        "searchTerm": "rutig",
        "answer": "kariert",
        "key": "106"
    },
    {
        "searchTerm": "skjortan",
        "answer": "das Hemd",
        "key": "107"
    },
    {
        "searchTerm": "hänga",
        "answer": "rumhängen",
        "key": "108"
    },
    {
        "searchTerm": "slappt",
        "answer": "lässig",
        "key": "109"
    },
    {
        "searchTerm": "på något sätt",
        "answer": "irgendwie",
        "key": "110"
    },
    {
        "searchTerm": "det yttre",
        "answer": "Deutsch",
        "key": "111"
    },
    {
        "searchTerm": "intrycket",
        "answer": "der Eindruck",
        "key": "112"
    },
    {
        "searchTerm": "sticka ut",
        "answer": "hervorstechen",
        "key": "113"
    },
    {
        "searchTerm": "titta",
        "answer": "schau mal",
        "key": "114"
    },
    {
        "searchTerm": "modedockan",
        "answer": "die Modepuppe",
        "key": "115"
    },
    {
        "searchTerm": "tjäna pengar",
        "answer": "Geld verdienen",
        "key": "116"
    },
    {
        "searchTerm": "sälja",
        "answer": "verkaufen",
        "key": "117"
    },
    {
        "searchTerm": "loppmarknaden",
        "answer": "der Flohmarkt",
        "key": "118"
    },
    {
        "searchTerm": "stödundervisningen",
        "answer": "Deutsch",
        "key": "119"
    },
    {
        "searchTerm": "reklambladet",
        "answer": "das Werbeblatt",
        "key": "120"
    },
    {
        "searchTerm": "bära ut",
        "answer": "austragen",
        "key": "121"
    },
    {
        "searchTerm": "få",
        "answer": "kriegen",
        "key": "122"
    },
    {
        "searchTerm": "gå ut med hunden",
        "answer": "Gassi gehen",
        "key": "123"
    },
    {
        "searchTerm": "hända",
        "answer": "passieren",
        "key": "124"
    },
    {
        "searchTerm": "grannen",
        "answer": "der Nachbar",
        "key": "125"
    },
    {
        "searchTerm": "diska",
        "answer": "Geschirr waschen",
        "key": "126"
    },
    {
        "searchTerm": "plocka undan",
        "answer": "aufräumen",
        "key": "127"
    },
    {
        "searchTerm": "klippa gräsmattan",
        "answer": "den Rasen mähen",
        "key": "128"
    },
    {
        "searchTerm": "skotta snö",
        "answer": "Schnee schaufeln",
        "key": "129"
    },
    {
        "searchTerm": "räcka till",
        "answer": "ausreichen",
        "key": "130"
    },
    {
        "searchTerm": "önskan",
        "answer": "der Wunsch",
        "key": "131"
    },
    {
        "searchTerm": "extrajobbet",
        "answer": "der Nebenjob",
        "key": "132"
    },
    {
        "searchTerm": "fickpengarna",
        "answer": "Deutsch",
        "key": "133"
    },
    {
        "searchTerm": "omtyckt",
        "answer": "beliebt",
        "key": "134"
    },
    {
        "searchTerm": "extrainkomsten",
        "answer": "der Nebenverdienst",
        "key": "135"
    },
    {
        "searchTerm": "källan",
        "answer": "die Quelle",
        "key": "136"
    },
    {
        "searchTerm": "lagen",
        "answer": "das Gesetz",
        "key": "137"
    },
    {
        "searchTerm": "ge tillstånd",
        "answer": "zustimmen",
        "key": "138"
    },
    {
        "searchTerm": "tillräckligt",
        "answer": "genug",
        "key": "139"
    },
    {
        "searchTerm": "under skoltid",
        "answer": "während der Schulzeit",
        "key": "140"
    },
    {
        "searchTerm": "mellan",
        "answer": "zwischen",
        "key": "141"
    },
    {
        "searchTerm": "högst",
        "answer": "höchstens",
        "key": "142"
    },
    {
        "searchTerm": "restaurangen",
        "answer": "die Gaststätte",
        "key": "143"
    },
    {
        "searchTerm": "glasståndet",
        "answer": "die Eisdiele",
        "key": "144"
    },
    {
        "searchTerm": "vårdaren",
        "answer": "der Betreuer",
        "key": "145"
    },
    {
        "searchTerm": "fritidsparken",
        "answer": "der Freizeitpark",
        "key": "146"
    },
    {
        "searchTerm": "löna sig",
        "answer": "sich lohnen",
        "key": "147"
    },
    {
        "searchTerm": "reklambladet",
        "answer": "der Flyer",
        "key": "148"
    },
    {
        "searchTerm": "omgivningen",
        "answer": "Deutsch",
        "key": "149"
    },
    {
        "searchTerm": "dela ut",
        "answer": "verteilen",
        "key": "150"
    },
    {
        "searchTerm": "utöver det",
        "answer": "darüber hinaus",
        "key": "151"
    },
    {
        "searchTerm": "förskolan",
        "answer": "der Kindergarten",
        "key": "152"
    },
    {
        "searchTerm": "anslagstavlan",
        "answer": "das Schwarze Brett",
        "key": "153"
    },
    {
        "searchTerm": "nöjd",
        "answer": "zufrieden",
        "key": "154"
    },
    {
        "searchTerm": "klä ut sig",
        "answer": "sich verkleiden",
        "key": "155"
    },
    {
        "searchTerm": "för det mesta",
        "answer": "meistens",
        "key": "156"
    },
    {
        "searchTerm": "dräkten",
        "answer": "das Kostüm",
        "key": "157"
    },
    {
        "searchTerm": "tävlingen",
        "answer": "der Wettbewerb",
        "key": "158"
    },
    {
        "searchTerm": "vunnit",
        "answer": "gewonnen",
        "key": "159"
    },
    {
        "searchTerm": "knåpa ihop",
        "answer": "basteln",
        "key": "160"
    },
    {
        "searchTerm": "tyckte om",
        "answer": "mochte",
        "key": "161"
    },
    {
        "searchTerm": "tatueringen",
        "answer": "das Tattoo",
        "key": "162"
    },
    {
        "searchTerm": "rakad",
        "answer": "rasiert",
        "key": "163"
    },
    {
        "searchTerm": "teet av ingefärsrot",
        "answer": "der Ingwerwurzeltee",
        "key": "164"
    },
    {
        "searchTerm": "framföra",
        "answer": "vortragen",
        "key": "165"
    },
    {
        "searchTerm": "besluta",
        "answer": "entscheiden",
        "key": "166"
    },
    {
        "searchTerm": "förebilden",
        "answer": "das Vorbild",
        "key": "167"
    },
    {
        "searchTerm": "fånig",
        "answer": "albern",
        "key": "168"
    },
    {
        "searchTerm": "med namnet",
        "answer": "namens",
        "key": "169"
    },
    {
        "searchTerm": "ombyggd",
        "answer": "umgebaut",
        "key": "170"
    },
    {
        "searchTerm": "däremot",
        "answer": "dagegen",
        "key": "171"
    },
    {
        "searchTerm": "mästerskapet",
        "answer": "die Meisterschaft",
        "key": "172"
    },
    {
        "searchTerm": "åskådaren",
        "answer": "der Zuschauer",
        "key": "173"
    },
    {
        "searchTerm": "äkta",
        "answer": "authentisch",
        "key": "174"
    },
    {
        "searchTerm": "balanserad",
        "answer": "ausgeglichen",
        "key": "175"
    },
    {
        "searchTerm": "sedan dess",
        "answer": "seitdem",
        "key": "176"
    }
]

export default kapitel93;